import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import Loader from "../components/Loader";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ModalCustomer from "../components/ModalCustomer";

function Qualifying_customers() {
  const navigate = useNavigate();
  const location = useLocation();
  const [customers, setcustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [errormessage, seterrormessage] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, sethasMore] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const handleCurrentPage = () => {
    setcustomers([]);
    setCurrentPage(0);
  };
  const observer = useRef();
  const tempRef = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        console.log(entries, "entries");
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );

  console.log(location, "location");

  const postGetList = async () => {
    seterrormessage(null);
    setIsLoadingMore(true);
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcrm/customers/${location.state.api}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          page: currentPage,
          searchInput: searchInput,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        },
      });
      console.log(response, "response");
      if (!response?.data?.error) {
        let set = new Set();
        for (const element of customers) {
          if (element?.customer_id) {
            set.add(element?.customer_id);
          }
        }

        const newQuotes = response.data.customers.filter(
          (newQuote) => !set.has(newQuote?.customer_id)
        );
        console.log(set, "set");
        console.log(newQuotes, "new quotes");
        sethasMore(response.data.hasMore);
        setcustomers([...customers, ...newQuotes]);
      } else {
        sethasMore(response.data.hasMore);
        seterrormessage(response.data.message);
      }
      setIsLoadingMore(false);
    } catch (error) {
      setIsLoadingMore(false);
      console.log(error);
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  console.log(customers, "customers");
  function dateFnCreatedAT(createdAt) {
    const createdAtDate = new Date(createdAt);

    const dd = String(createdAtDate.getDate()).padStart(2, "0");
    const mm = String(createdAtDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = createdAtDate.getFullYear();

    const formattedDate = `${dd}-${mm}-${yyyy}`;

    return formattedDate;
  }
  useEffect(() => {
    postGetList();
  }, [currentPage, searchInput]);

  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="row d-flex align-items-center">
                <div className="col-4">
                  <div
                    className="w-100 py-3 d-flex align-items-center "
                   
                  >
                    <button  style={{ padding: "18px", cursor: "pointer" }}
                    onClick={() => navigate("/crm")} type="button" className="back-btn">
                      <svg className="icon me-2 fs-22">
                        <use href="#icon_leftarrow"></use>
                      </svg>
                    <strong className="fs-14">Back</strong>
                    </button>
                  </div>
                </div>
                <div className="col-4">
                  <h2 className="fw-700 fs-30 text-center py-3">
                    {location.state.title}
                  </h2>
                </div>
                <div className="col-4"></div>
              </div>

              <div className="d-flex align-items-center">
                <div className="w-25">
                  <input
                    type="search"
                    className="form-control rounded-pill"
                    value={searchInput}
                    onChange={(e) => {
                      setcustomers([]);
                      setCurrentPage(0);
                      setsearchInput(e.target.value);
                    }}
                    placeholder="Search Name, email, Organization Name"
                    id="datatable-search-input"
                  />
                </div>
                <div className="ms-auto">
                  {location.state.title == "Qualifying Customer" && (
                    <ModalCustomer
                      postGetList={postGetList}
                      handleCurrentPage={handleCurrentPage}
                      from="customers"
                    />
                  )}
                </div>
              </div>
            </div>
            {customers.length == 0 && errormessage && (
              <h2 className="fw-600 text-center my-5">No customer found</h2>
            )}
            {customers?.length > 0 && (
              <table className="table text-center my-4 border">
                <thead>
                  <tr className="border" style={{ background: "#EBEEFF" }}>
                    <th>Organization Name</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Lead Created On</th>
                  </tr>
                </thead>
                <tbody>
                  {customers?.map((item, index) => (
                    <tr
                      ref={
                        customers?.length == index + 1
                          ? lastBookElementRef
                          : tempRef
                      }
                      key={item?.organization_name}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.setItem(
                          "from_navigation",
                          JSON.stringify({
                            title: location.state.title,
                            backend: location.state.backend,
                            api: location.state.api,
                            place: "customers",
                          })
                        );
                        navigate(`/${item?.customer_id}`);
                      }}
                    >
                      <td>{item?.organization_name}</td>
                      <td>{item?.customer_name}</td>
                      <td>{item?.customer_email}</td>
                      <td>{item?.customer_phone}</td>
                      <td>{dateFnCreatedAT(item?.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <div>
              {isLoadingMore && (
                <div className="text-center">
                  <Loader />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Qualifying_customers;
