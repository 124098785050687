import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import NavBar from '../components/NavBar'

function Customers_list() {
  return (
    <>
    <NavBar/>
    <Container>
      <Row>
        <Col md={12}>
          <div className='wrapper-box'>
            <h2 className='fw-700 fs-30 text-center'>List of Customers</h2>
          </div>
            <table class="table text-centerl my-4">
              <thead>
                <tr className='border' style={{background: "#EBEEFF"}}>
                  <th>Spot</th>
                  <th>Recurring</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div style={{ maxWidth: "450px" }}>Coca Cola</div>
                  </td>
                  <td>On Boarding Date: 25-05-2023</td>
                  <td width='200px'>
                    <button type='button' className='quotes-btn d-flex justify-content-between px-3'>
                      <span>View</span>
                      <svg className="icon fs-22">
                        <use href="#icon_rightarrow"></use>
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Parley</td>
                  <td>On Boarding Date: 25-05-2023</td>
                  <td>
                    <button type='button' className='quotes-btn d-flex justify-content-between px-3'>
                      <span>View</span>
                      <svg className="icon fs-22">
                        <use href="#icon_rightarrow"></use>
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Dabur</td>
                  <td>On Boarding Date: 25-05-2023</td>
                  <td>
                    <button type='button' className='quotes-btn d-flex justify-content-between px-3'>
                      <span>View</span>
                      <svg className="icon fs-22">
                        <use href="#icon_rightarrow"></use>
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Haldiram</td>
                  <td>On Boarding Date: 25-05-2023</td>
                  <td>
                    <button type='button' className='quotes-btn d-flex justify-content-between px-3'>
                      <span>View</span>
                      <svg className="icon fs-22">
                        <use href="#icon_rightarrow"></use>
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Nestle</td>
                  <td>On Boarding Date: 25-05-2023</td>
                  <td>
                    <button type='button' className='quotes-btn d-flex justify-content-between px-3'>
                      <span>View</span>
                      <svg className="icon fs-22">
                        <use href="#icon_rightarrow"></use>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Customers_list