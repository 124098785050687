import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "../pages/Login";
import Pageone from "../pages/Pageone";
import Pagetwo from "../pages/Pagetwo";
import Pagethree from "../pages/Pagethree";
import LayoutOutlet from "./LayoutOutlet";
import Home from "../pages/Home";
import Details from "../pages/Details";
import ChooseCustomer from "../pages/ChooseCustomer";
import Crm from "../pages/Crm";
import Customers_list from "../pages/Customers_list";
import Tracking from "../pages/Tracking";
import Booking_number from "../pages/Booking_number";
import Qualifying_customers from "../pages/Qualifying_customers";
import Apo from "../pages/Apo";
import Selectcard from "../pages/Selectcard";
import LayoutOutlet2 from "./LayoutOutlet2";
import Home2 from "../pages/Home2";
import Quote_details from "../pages/Quote_details";
import PageDynamic from "../pages/PageDynamic";
import Unauthorized from "../pages/Unauthorized";

function Routing() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/unauthorized" element={<Unauthorized />}></Route>
          <Route path="/home" element={<Selectcard />}></Route>
          <Route path="/crm" element={<Crm />}></Route>
          <Route path="/customers_list" element={<Customers_list />}></Route>
          <Route path="/customers" element={<Qualifying_customers />}></Route>
          <Route path="/tracking" element={<Tracking />}></Route>
          <Route path="/" element={<LayoutOutlet />}>
            <Route path="/quote" element={<Home />} />
            <Route path="/pageone" element={<Pageone />} />
            <Route path="/pagetwo" element={<Pagetwo />} />
            <Route path="/pagethree" element={<Pagethree />} />
            <Route path="/choosecustomer" element={<ChooseCustomer />} />
            <Route path="/details" element={<Details />} />
            <Route path="/booking_number" element={<Booking_number />} />
            <Route path="/apo" element={<Apo />} />
            <Route path="/page/:page_num" element={<PageDynamic />} />
          </Route>
          <Route path="/:customerId/tracking" element={<Tracking />}></Route>
          <Route
            path="/:customerId/quote/:quote_id"
            element={<Quote_details />}
          ></Route>
          <Route path="/:customerId/*" element={<LayoutOutlet2 />}>
            <Route path="" element={<Home2 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
