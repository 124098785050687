import React, { useEffect, useState } from "react";
import { conditionalExp, getCargoMeasurements } from "../utils/commonFn";
import Select from "react-select";

function CustomInput({ question, setQuestionData, page, options,isCountryChange,setIsCountryChange }) {
  const [inputValue, setInputValue] = useState(
    conditionalExp(
      question?.input_type == "country_dropdown" ||
        question?.input_type == "port_dropdown" || question?.input_type == "airport_dropdown" || question?.input_type == 'incoterm_dropdown' || question?.input_type == 'commodity_dropdown',
      "",
      undefined
    )
  );
  const [inputValueMeasure, setInputValueMeasure] = useState("")

  const incotermOptions = [
    { label: 'EXW', value: 'EXW' },
    { label: 'CIF', value: 'CIF' },
    { label: 'FOB', value: 'FOB' },
    { label: 'FAS', value: 'FAS' },
    { label: 'CFR', value: 'CFR' },
    { label: 'DDP', value: 'DDP' },
    { label: 'DAP', value: 'DAP' },
    { label: 'DPU', value: 'DPU' },
    { label: 'CIP', value: 'CIP' },
    { label: 'CPT', value: 'CPT' },
    { label: 'FCA', value: 'FCA' },
    { label: 'EXW-FCA', value: 'EXW-FCA' }
  ]
  useEffect(() => {
    if(question?.value){
      if(question?.input_type == "country_dropdown" || question?.input_type == "port_dropdown" ||question?.input_type == "airport_dropdown" || question?.input_type == 'incoterm_dropdown'){
        setInputValue( {label:question?.value,value:question?.value})
      }else if(question?.input_type == "commodity_dropdown"){
        setInputValue( options?.filter(op=> question?.value?.includes(op?.value)))
      }else if(question?.input_type == "text" || question?.input_type == "number"|| question?.input_type == "truck_type_buttons" || question?.input_type == "container_type_buttons"|| question?.input_type == "import_export_type_buttons"|| question?.input_type == "trade_type_buttons" ){
        setInputValue(question?.value)
      }else if(question?.input_type == "measurement_text"){
        setInputValueMeasure(question?.value)
      }
    }
  }, [question])
  

  useEffect(() => {
    if (question?.input_type == "port_dropdown" || question?.input_type == "airport_dropdown" ) {
      console.log('page',page);
      if(isCountryChange !== undefined && isCountryChange == question?.page_title ){
        console.log('country chagned',isCountryChange,question?.page_title);
      setInputValue("");
      setQuestionData((pre) =>
        pre.map((q) => {
          if (q.content_key == question?.content_key) {
            return {
              ...q,
              value: null,
            };
          } else {
            return q;
          }
        })
      );
      }
    }
  }, [isCountryChange,page]);

  return (
    <div key={question?.content_key} className="input-box mb-4">
      {conditionalExp(
        question?.input_type == "text" || question?.input_type == "number",
        <>
          {inputValue && inputValue != "" && (
            <label htmlFor={question?.content_key}>{question?.content}</label>
          )}
          <input
            type={question?.input_type}
            className="w-100 border-bottom pb-3 px-2"
            placeholder={"Enter " + question?.content}
            id={question?.content_key}
            value={inputValue || question?.value}
            onChange={(e) => {
              setInputValue(e.target.value);
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: e.target.value,
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
          />
          {inputValue == "" && (
            <p className="error-message text-danger">
              Please Enter {question?.content}.
            </p>
          )}
        </>,
        null
      )}
      {conditionalExp(
        question?.input_type == "confirmation_radio",
        <>
          <p className="my-2">{question?.content}</p>

          <input
            className="mx-2"
            type="radio"
            name={question?.content}
            defaultChecked={question?.value == "yes"}
            id={question?.content_key + "yes"}
            onClick={(e) => {
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: "yes",
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
          />
          <label htmlFor={question?.content_key + "yes"}>Yes</label>

          <input
            className="mx-2"
            type="radio"
            name={question?.content}
            defaultChecked={question?.value == "no"}
            id={question?.content_key + "no"}
            onClick={(e) => {
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: "no",
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
          />
          <label htmlFor={question?.content_key + "no"}>No</label>
        </>
      )}
      {conditionalExp(
        question?.input_type == "country_dropdown",
        <div className=" border-bottom ">
          {inputValue && (
            <label htmlFor={question?.content_key}>{question?.content}</label>
          )}
          <Select
            value={inputValue }
            isLoading={!options.length}
            onChange={(option,action) => {
              console.log(option,action, "value");
              setInputValue(option);
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: option?.value,
                    };
                  } else {
                    return q;
                  }
                })
              );
              setIsCountryChange(question?.page_title);
            }}
            options={options || []}
            isSearchable={true}
            placeholder={question?.content + "*"}
            isClearable
          />
          {inputValue == null && (
            <p className="mx-2" style={{ color: "red", fontWeight: "lighter" }}>
              Please select {question?.content}
            </p>
          )}
        </div>
      )}
      {conditionalExp(
        question?.input_type == "commodity_dropdown",
        <div className=" border-bottom ">
          {inputValue && (
            <label htmlFor={question?.content_key}>{question?.content}</label>
          )}
          <Select
            value={inputValue}
            isLoading={!options.length}
            onChange={(option,action) => {
              setInputValue(option);
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: option?.map(v=>v?.value)?.join(',,'),
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
            options={options || []}
            isSearchable={true}
            placeholder={question?.content + "*"}
            isClearable
            isMulti
          />
          {inputValue == null && (
            <p className="mx-2" style={{ color: "red", fontWeight: "lighter" }}>
              Please select {question?.content}
            </p>
          )}
        </div>
      )}
      {conditionalExp(
        question?.input_type == "port_dropdown" || question?.input_type == 'airport_dropdown',
        <div className=" border-bottom ">
          {inputValue && (
            <label htmlFor={question?.content_key}>{question?.content}</label>
          )}
          <Select
          isLoading={!options.length}
            value={inputValue }
            onChange={(option) => {
              console.log(option, "value");
              setInputValue(option);
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: option?.value,
                    };
                  } else {
                    return q;
                  }
                })
              );
             conditionalExp(isCountryChange === question?.page_title,setIsCountryChange())
            }}
            options={options || []}
            isSearchable={true}
            placeholder={question?.content + "*"}
            isClearable
          />
          {inputValue == null && (
            <p className="mx-2" style={{ color: "red", fontWeight: "lighter" }}>
              Please select {question?.content}
            </p>
          )}
        </div>
      )}
      {conditionalExp(
        question?.input_type == "container_type_buttons",
       <> {
        options?.map((item) => (
          <div className="mb-4" key={item?.container_type}>
            <button
              type="button"
              className={
                inputValue ==
                item?.container_type
                  ? `w-100 type-choose-btn`
                  : `w-100 type-btn ${
                      item?.clickup_product_name != "20 DRY" &&
                      item?.clickup_product_name != "40 DRY" &&
                      item?.clickup_product_name != "40 HC" &&
                      item?.clickup_product_name !=
                        "20' + 40'" &&
                      "type-btn-red"
                    }`
              }
              onClick={() => {
                setInputValue(item?.container_type);
                setQuestionData((pre) =>
                  pre.map((q) => {
                    if (q.content_key == question?.content_key) {
                      return {
                        ...q,
                        value: item?.container_type,
                      };
                    } else {
                      return q;
                    }
                  })
                );
              }}
            >
              {item?.clickup_product_name} 

              {inputValue ==
                item?.container_type && (
                <span>
                  <svg className="icon fs-20">
                    <use href="#icon_check"></use>
                  </svg>
                </span>
              )}
            </button>
          </div>
        ))
       }
       </>
      )}
      {conditionalExp(
        question?.input_type == "truck_type_buttons",
       <> {
        options?.map((item) => (
          <div className="mb-4" key={item?.container_type}>
            <button
              type="button"
              className={
                inputValue ==
                item?.container_type
                  ? `w-100 type-choose-btn`
                  : `w-100 type-btn`
              }
              onClick={() => {
                setInputValue(item?.container_type);
                setQuestionData((pre) =>
                  pre.map((q) => {
                    if (q.content_key == question?.content_key) {
                      return {
                        ...q,
                        value: item?.container_type,
                      };
                    } else {
                      return q;
                    }
                  })
                );
              }}
            >
              {item?.clickup_product_name} 

              {inputValue ==
                item?.container_type && (
                <span>
                  <svg className="icon fs-20">
                    <use href="#icon_check"></use>
                  </svg>
                </span>
              )}
            </button>
          </div>
        ))
       }
       </>
      )}
      {conditionalExp(
        question?.input_type == "trade_type_buttons" || question?.input_type == "import_export_type_buttons",
       <> {
        options?.map((item) => (
          <div className="mb-4" key={item?.clickup_product_id}>
            <button
              type="button"
              className={
                inputValue ==
                item?.clickup_product_id
                  ? `w-100 type-choose-btn`
                  : `w-100 type-btn `
              }
              onClick={() => {
                setInputValue(item?.clickup_product_id);
                setQuestionData((pre) =>
                  pre.map((q) => {
                    if (q.content_key == question?.content_key) {
                      return {
                        ...q,
                        value: item?.clickup_product_id,
                      };
                    } else {
                      return q;
                    }
                  })
                );
              }}
            >
              {item?.clickup_product_name} 

              {inputValue ==
                item?.clickup_product_id && (
                <span>
                  <svg className="icon fs-20">
                    <use href="#icon_check"></use>
                  </svg>
                </span>
              )}
            </button>
          </div>
        ))
       }
       </>
      )}
      {conditionalExp(
        question?.input_type == "measurement_text",
        <>
         
            <label htmlFor={question?.content_key}>{question?.content}</label>
          
          <div className="w-100 pb-3 px-2 d-flex justify-content-between">
            
          <input
            type='number'
            // className="w-100 border-bottom pb-3 px-2"
            placeholder={"Enter length"}
            id={question?.content_key + 'l'}
            value={inputValueMeasure?.split('X')[0] || getCargoMeasurements(question?.value)[0] || null}
            onChange={(e) => {
              let inv = inputValueMeasure?.split('X') || []
              inv[0] = e.target.value;
              setInputValueMeasure(inv?.join('X'));
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: inv?.join('X'),
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
          />
           <input
            type='number'
            // className="w-100 border-bottom pb-3 px-2"
            placeholder={"Enter Width"}
            id={question?.content_key + 'w'}
            value={inputValueMeasure?.split('X')[1] || getCargoMeasurements(question?.value)[1] || null}
            onChange={(e) => {
              let inv = inputValueMeasure?.split('X') || []
              inv[1] = e.target.value;
              setInputValueMeasure(inv?.join('X'));
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: inv?.join('X'),
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
          />
           <input
            type='number'
            // className="w-100 border-bottom pb-3 px-2"
            placeholder={"Enter Height"}
            id={question?.content_key + 'h'}
            value={inputValueMeasure?.split('X')[2] || getCargoMeasurements(question?.value)[2]||null}
            onChange={(e) => {
              let inv = inputValueMeasure?.split('X') || []
              inv[2] = e.target.value;
              setInputValueMeasure(inv?.join('X'));
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: inv?.join('X'),
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
          />
          </div>
          {conditionalExp(inputValueMeasure == "",false,!inputValueMeasure?.split('X')?.every(i=> i)) && (
            <p className="error-message text-danger">
              Please Enter {question?.content}.
            </p>
          )}
        </>,
        null
      )}
      {conditionalExp(
        question?.input_type == "incoterm_dropdown",
        <div className=" border-bottom ">
          {inputValue && (
            <label htmlFor={question?.content_key}>{question?.content}</label>
          )}
          <Select
            value={inputValue }
            onChange={(option) => {
              setInputValue(option);
              setQuestionData((pre) =>
                pre.map((q) => {
                  if (q.content_key == question?.content_key) {
                    return {
                      ...q,
                      value: option?.value,
                    };
                  } else {
                    return q;
                  }
                })
              );
            }}
            options={incotermOptions}
            isSearchable={true}
            placeholder={question?.content + "*"}
            isClearable
          />
          {inputValue == null && (
            <p className="mx-2" style={{ color: "red", fontWeight: "lighter" }}>
              Please select {question?.content}
            </p>
          )}
        </div>
      )}
     
    </div>
  );
}

export default CustomInput;
