import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import axios from "axios";
import IconComponent from "../components/IconComponent";
import ButtonComponent from "../components/ButtonComponent";
import Loader from "../components/Loader";
import ModalCustomer from "../components/ModalCustomer";

function Crm() {
  const navigate = useNavigate();
  const [recentList, setrecentList] = useState({});
  const [loading, setloading] = useState(true);
  const [titles, settitles] = useState([]);
  const getRecentList = async () => {
    setloading(true);
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmcrm/customers/recentlist`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        },
      });
      setloading(false);
      if (!response.data.error) {
        setrecentList(response.data.customers);
      }
    } catch (error) {
      setloading(false);
      console.log(error);
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  console.log(recentList, "recent list");

  useEffect(() => {
    const titles1 = [
      {
        title: "Qualifying Customer",
        backend: "qualifying",
        api: "qualifying",
      },
      { title: "Opportunities", backend: "opportunities", api: "opportunity" },
      { title: "Current Customers", backend: "current", api: "current" },
    ];

    if (JSON.parse(localStorage.getItem("mtm_user_data"))?.user_type == 1) {
      titles1.push(
        {
          title: "Blacklisted Customers ",
          backend: "blacklisted",
          api: "blacklisted",
        },
        {
          title: "Disqualified Customers",
          backend: "disqualified",
          api: "disqualified",
        }
      );
    }
    settitles(titles1);
    getRecentList();
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box mt-3">
              <div className="d-flex justify-content-between">
                <div
                  className="w-100 py-3 d-flex align-items-center "
                >
                  <button  style={{cursor: "pointer" }}
                  onClick={() => navigate("/home")} type="button" className="back-btn">
                    <svg className="icon me-2 fs-22">
                      <use href="#icon_leftarrow"></use>
                    </svg>
                  <strong className="fs-14">Back</strong>
                  </button>
                </div>
                <div className="ms-auto mb-3">
                  <ModalCustomer getRecentList={getRecentList} from="crm" />
                </div>
              </div>
              {loading && (
                <div className="text-center">
                  <Loader />
                </div>
              )}
              {!loading && (
                <Row className="row-cols-3 cols-lg-3 g-4">
                  {titles?.map((titles, title_index) => (
                    <Col key={titles.title}>
                      <div className="crm-box">
                        <ul className="">
                          <li>
                            <button
                              type="button"
                              className="w-100"
                              onClick={() =>
                                navigate("/customers", {
                                  state: {
                                    title: titles.title,
                                    backend: titles.backend,
                                    api: titles.api,
                                  },
                                })
                              }
                            >
                              <strong>{titles.title}</strong>
                              <span>
                                <IconComponent />
                              </span>
                            </button>
                          </li>
                          {recentList[titles?.backend]?.map((item, index) => (
                            <li
                              key={item?.organization_name}
                              onClick={() => {
                                localStorage.setItem(
                                  "from_navigation",
                                  JSON.stringify({
                                    title: titles.title,
                                    backend: titles.backend,
                                    api: titles.api,
                                    place: "crm",
                                  })
                                );
                                navigate(`/${item?.customer_id}`);
                              }}
                            >
                              <ButtonComponent
                                organization_name={item?.organization_name}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Crm;
