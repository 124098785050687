import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import NavBar from "../components/NavBar";
import axios from "axios";
import Loader from "../components/Loader";
import usePostQuoteHook from "../hooks/post-quote-details";
import { conditionalExp } from "../utils/commonFn";
import QuoteMenu from "../components/quoteMenu";

function Details() {
  const navigate = useNavigate();
  const { postQuoteDetailsFn } = usePostQuoteHook();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dropdownView, setDropdownView] = useState({
    product_type: "",
    trade: "",
    import_export: "",
    container_type: "",
  });

  const [questionData, setQuestionData] = useState([]);
  const [
    quoteDetailsCameFromLocalStorage,
    setquoteDetailsCameFromLocalStorage,
  ] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const postQuoteDetails = async () => {
    setIsLoading(true);

    try {
      let dataToSent = {};
      let pre_modal_questions = JSON.parse(
        localStorage.getItem("pre_modal_questions")
      );
      if (pre_modal_questions) {
        pre_modal_questions.forEach((q) => {
          if (q.content_key == "origin_port_name") {
            dataToSent["loading_port"] = null;
          } else if (q.content_key == "destination_port_name") {
            dataToSent["discharge_port"] = null;
          }
          dataToSent[q.content_key] = null;
        });
      }
      questionData.forEach((q) => {
        if (q.content_key == "origin_port_name") {
          dataToSent["loading_port"] =
            q?.value?.split("|")[1]?.trim() ||
            quoteDetailsCameFromLocalStorage["loading_port"] ||
            null;
        } else if (q.content_key == "destination_port_name") {
          dataToSent["discharge_port"] =
            q?.value?.split("|")[1]?.trim() ||
            quoteDetailsCameFromLocalStorage["discharge_port"] ||
            null;
        }
        dataToSent[q.content_key] =
          q.value || quoteDetailsCameFromLocalStorage[q.content_key] || null;
      });
      const data = {
        ...quoteDetailsCameFromLocalStorage,
        ...dataToSent,
        quote_states: 1,
        contact_person: JSON.parse(localStorage.getItem("mtm_user_data"))
          ?.user_full_name,
        contact_person_email: JSON.parse(localStorage.getItem("mtm_user_data"))
          ?.email,
        user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        quote_action: quoteDetailsCameFromLocalStorage?.task_id
          ? "update"
          : "add",
      };

      let response = await postQuoteDetailsFn(data, "home", 1);
      if (!response?.data?.error) {
        localStorage.removeItem("quoteDetailsStored");
        localStorage.removeItem("portOfLoadingName");
        localStorage.removeItem("portOfDischargeName");
        localStorage.removeItem("containerTypeid");
        localStorage.removeItem("customer_list");
        localStorage.removeItem("quoteDetailsStored_customer_type");
        localStorage.removeItem("service_type_id");
        localStorage.removeItem("modal_questions");
        localStorage.removeItem("pre_modal_questions");
        localStorage.removeItem("modal_id");
        localStorage.removeItem("typeOfProductId");
        handleShow();
        setTimeout(() => {
          handleClose();
          navigate("/quote");
        }, 2500);

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      return clickup_data?.data?.dropdown_details;
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  const convertDateFormat = (inputDate) => {
    const dateObj = new Date(inputDate);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month because it's zero-based
    const year = dateObj.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    async function getDropdownData() {
      setIsPageLoading(true);
      const tempClickupData = await getDropdown();

      if (localStorage.getItem("quoteDetailsStored")) {
        let data = JSON.parse(localStorage.getItem("quoteDetailsStored"));
        setquoteDetailsCameFromLocalStorage(
          JSON.parse(localStorage.getItem("quoteDetailsStored"))
        );

        let dropdownServiceType = tempClickupData?.find(
          (clickup) => clickup.clickup_product_id === data?.product_type
        )?.clickup_product_name;
        let dropdownImpExp = tempClickupData?.find(
          (clickup) => clickup.clickup_product_id === data?.import_export
        )?.clickup_product_name;
        let dropdownTrade = tempClickupData?.find(
          (clickup) => clickup.clickup_product_id === data?.trade
        )?.clickup_product_name;
        let clickupId = tempClickupData?.find(
          (clickup) =>
            clickup.dropdown_type == "container_type" &&
            clickup.container_type == data?.container_type
        );

        setDropdownView({
          product_type: dropdownServiceType,
          import_export: dropdownImpExp,
          trade: dropdownTrade,
          clickupId: clickupId?.clickup_product_id,
          container_type: clickupId?.clickup_product_name,
        });
      }

      if (localStorage.getItem("modal_questions")) {
        setQuestionData(JSON.parse(localStorage.getItem("modal_questions")));
      }
      setIsPageLoading(false);
    }

    getDropdownData();
  }, []);

  return (
    <>
      <NavBar />
      <div className="bg-gray">
        <Container>
          <Row>
            <QuoteMenu page={"details"} />
            <Col sm={10}>
              <div className="wrapper-box">
                <div className="position-relative">
                  <h2 className="text-center fw-700 fs-30">
                    Request for Quote
                  </h2>
                </div>
                <div className="content-wrapper">
                  <form className="container">
                    <div className="row justify-content-between gap-sm-5 gap-5 pb-3">
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Opportunity Detail
                          </label>
                          <input
                            readOnly
                            type="text"
                            className=" w-100"
                            placeholder=""
                            value={
                              quoteDetailsCameFromLocalStorage?.opportunity_name
                            }
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Quote Receiver Email
                          </label>
                          <input
                            readOnly
                            type="text"
                            className=" w-100"
                            placeholder=""
                            value={
                              quoteDetailsCameFromLocalStorage?.quote_receiver_email
                            }
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Departure Date
                          </label>
                          <input
                            readOnly
                            type="text"
                            className=" w-100"
                            placeholder=""
                            value={convertDateFormat(
                              quoteDetailsCameFromLocalStorage?.departure_date
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Type of product(service)
                          </label>
                          <input
                            readOnly
                            type="text"
                            className=" w-100"
                            placeholder=""
                            value={
                              dropdownView?.product_type ||
                              quoteDetailsCameFromLocalStorage?.product_type
                            }
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="border-bottom py-3">
                          <label className="form-label fw-600 fs-20">
                            Service Details
                          </label>
                          <input
                            readOnly
                            type="text"
                            className=" w-100"
                            placeholder=""
                            value={
                              quoteDetailsCameFromLocalStorage?.service_type
                            }
                          />
                        </div>
                      </div>
                      {questionData?.map((question) => {
                        if (question?.dependent_question_id) {
                          return conditionalExp(
                            questionData?.find(
                              (q) => q?.id == question?.dependent_question_id
                            )?.value == "yes",
                            <div className="col-5 border-bottom  py-3">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              <input
                                readOnly
                                type="text"
                                className="w-100"
                                placeholder=""
                                value={
                                  quoteDetailsCameFromLocalStorage[
                                    question?.content_key
                                  ]
                                }
                              />
                            </div>
                          );
                        } else if (
                          question?.content_key == "container_type" ||
                          question?.content_key == "trade" ||
                          question?.content_key == "import_export"
                        ) {
                          return (
                            <div className="col-5 border-bottom  py-3">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              <input
                                readOnly
                                type="text"
                                className="w-100"
                                placeholder=""
                                value={
                                  dropdownView[question?.content_key] ||
                                  quoteDetailsCameFromLocalStorage[
                                    question?.content_key
                                  ]
                                }
                              />
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-5 border-bottom  py-3">
                              <label className="form-label fw-600 fs-20">
                                {question?.content}
                              </label>
                              <input
                                readOnly
                                type="text"
                                className="w-100"
                                placeholder=""
                                value={
                                  quoteDetailsCameFromLocalStorage[
                                    question?.content_key
                                  ]
                                }
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div className="d-flex justify-content-end py-3">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <div>
                            <button
                              type="button"
                              className="px-4 py-2 rounded-pill grdline-btn fs-20 fw-600 d-flex text-center align-items-center me-4"
                              onClick={() => {
                                let lastPage = JSON.parse(
                                  localStorage.getItem("modal_questions")
                                )
                                  ?.sort((a, b) => a.page_order - b.page_order)
                                  ?.pop()?.page_order;
                                navigate("/page/" + lastPage);
                              }}
                            >
                              <svg className="icon me-2 fs-24">
                                <use href="#icon_leftarrow"></use>
                              </svg>
                              Back
                            </button>
                          </div>
                          <div>
                            <button
                              disabled={
                                !questionData
                                  ?.filter(
                                    (item) =>{
                                // filter out the pickup_zip_code and delivery_zip_code if modal_id is 1

                                      if([1].includes(item?.modal_id) && ['pickup_zip_code','delivery_zip_code'].includes(item?.content_key)){
                                        return false;
                                      }
                                      else{
                                       return item?.content_key != "palletized_cargo" &&
                                      item?.content_key !=
                                        "number_of_packages" &&
                                      item?.content_key != "stackable_cargo"
                                    }
                                  }
                                  )
                                  ?.every((q) => {
                                    if (q?.dependent_question_id) {
                                      return conditionalExp(
                                        questionData?.find(
                                          (que) =>
                                            que?.id == q?.dependent_question_id
                                        )?.value == "yes",
                                        q?.value,
                                        true
                                      );
                                    } else {
                                      return conditionalExp(
                                        q?.content_key == "cbm" ||
                                          q?.content_key == "total_weight",
                                        conditionalExp(
                                          q?.value > 0,
                                          true,
                                          false
                                        ),
                                        conditionalExp(q?.value, true, false)
                                      );
                                    }
                                  })
                              }
                              type="button"
                              className="px-4 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                              onClick={postQuoteDetails}
                            >
                              Submit
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ==== Modal ==== */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body className="text-center py-5">
          <div className="d-flex justify-content-center">
            <div className="check-box">
              <svg className="icon fs-30">
                <use href="#icon_check"></use>
              </svg>
            </div>
          </div>
          <p className="fw-600 fs-26 py-4">Thank you</p>
          <p className="fw-400 fs-20">Your Submission Has Been Received</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Details;
