import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import axios from "axios";
import Loader from "../components/Loader";
import Progress from "../components/Progress";
import usePostQuoteHook from "../hooks/post-quote-details";
import QuoteMenu from "../components/quoteMenu";

function Pagetwo() {
  const navigate = useNavigate();
  const { postQuoteDetailsFn } = usePostQuoteHook();
  const [quoteDetails, setquoteDetails] = useState({});
  const [clickupData, setClickupData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [
    quoteDetailsCameFromLocalStorage,
    setquoteDetailsCameFromLocalStorage,
  ] = useState({});
  const now = 13.333;

  const postQuoteDetails = async () => {
    try {
      let clickupId = clickupData?.find(
        (clickup) =>
          clickup.dropdown_type == "product" &&
          clickup.clickup_product_name == quoteDetails?.type_of_product
      );
      let data = {
        ...quoteDetailsCameFromLocalStorage,
        product_type: clickupId?.clickup_product_id,
        product_type_name: quoteDetails?.type_of_product,
        current_state: "2",
      };
      await postQuoteDetailsFn(data, "/pagethree", 0);
      localStorage.setItem("service_type_id", quoteDetails?.type_of_product_id);
    } catch (error) {
      console.log(error);
    }
  };

  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      return clickup_data?.data?.dropdown_details;
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  useEffect(() => {
    async function getDropdownData() {
      const tempClickupData = await getDropdown();
      setClickupData(tempClickupData);
      if (localStorage.getItem("quoteDetailsStored")) {
        let data = JSON.parse(localStorage.getItem("quoteDetailsStored"));
        setquoteDetailsCameFromLocalStorage(
          JSON.parse(localStorage.getItem("quoteDetailsStored"))
        );
        let currentClickup = tempClickupData?.find(
          (clickup) => clickup.clickup_product_id == data?.product_type
        );
        console.log(currentClickup, "currentClickup");
        setquoteDetails({
          ...quoteDetails,
          type_of_product: currentClickup?.clickup_product_name,
          type_of_product_id: currentClickup?.product_id,
        });
      }
    }
    getDropdownData();
  }, []);

  return (
    <>
      <NavBar />
      <div className="">
        <Container>
          <Row>
        <QuoteMenu page={2}/>
            <Col sm={10}>
              <div className="wrapper-box">
                <Progress now={now} page={2} />
                <div className="content-wrapper">
                  <p className="fw-600 fs-26 text-center ">
                    Type of services
                    <div className=" fw-600 fs-26 pt-1">
                      Disclaimer: Freightify supports only OCEAN FCL and OCEAN LCL.
                    </div>
                  </p>
                  {clickupData?.length == 0 ? (
                    <div className="mt-5 text-center">
                      <Loader />
                    </div>
                  ) : (
                    <form className="pt-4">
                      {clickupData
                        ?.filter((item) => item.dropdown_type == "product")
                        ?.map((item) => (
                          <>
                            <div className="mb-4">
                              <button
                                type="button"
                                className={
                                  quoteDetails?.type_of_product ==
                                  item?.clickup_product_name
                                    ? "w-100 type-choose-btn"
                                    : "w-100 type-btn"
                                }
                                onClick={() => {
                                  setquoteDetails({
                                    ...quoteDetails,
                                    type_of_product: item?.clickup_product_name,
                                    type_of_product_id: item?.product_id,
                                  });
                                  setIsSelected(false);
                                }}
                              >
                                {item?.clickup_product_name}
                                {quoteDetails?.type_of_product ==
                                  item?.clickup_product_name && (
                                  <span>
                                    <svg className="icon fs-20">
                                      <use href="#icon_check"></use>
                                    </svg>
                                  </span>
                                )}
                              </button>
                            </div>
                          </>
                        ))}
                      {isSelected && (
                        <div className="d-flex justify-content-end pb-3">
                          <p style={{ color: "red" }}>
                            Please select Type of product
                          </p>
                        </div>
                      )}
                      <div className="d-flex justify-content-end pb-3">
                        <div>
                          <button
                            type="button"
                            className="px-4 py-2 rounded-pill grdline-btn fs-20 fw-600 d-flex text-center align-items-center me-4"
                            onClick={() => navigate("/pageone")}
                          >
                            <svg className="icon me-2 fs-24">
                              <use href="#icon_leftarrow"></use>
                            </svg>
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="px-4 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                            onClick={(e) => {
                              if (!quoteDetails.type_of_product) {
                                return setIsSelected(true);
                              }
                              postQuoteDetails();
                            }}
                          >
                            Next
                            <svg className="icon ms-2 fs-24">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Pagetwo;
