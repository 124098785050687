import React, { createContext, useMemo, useState } from "react";

// Create the context
export const ContainerContext = createContext();

// Create a provider component
export const ContainerProvider = ({ children }) => {
  const [containers, setContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState({});
  // Function to update the containers object
  const updateContainers = (newContainers) => {
    setContainers(newContainers);
  };
  // Function to set the selected container object
  const updateSelectedContainer = (newContainer) => {
    setSelectedContainer(newContainer);
  };
  const valueMemo = useMemo(() => ({
    containers,
    updateContainers,
    selectedContainer,
    updateSelectedContainer,
  }), [containers,
    selectedContainer]);
  return (
    <ContainerContext.Provider
      value={valueMemo}
    >
      {children}
    </ContainerContext.Provider>
  );
};
