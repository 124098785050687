import React from 'react'

function IconComponent() {
  return (
    <>
        <svg className="icon fs-22">
            <use href="#icon_rightlongarrow"></use>
        </svg>
    </>
  )
}

export default IconComponent
