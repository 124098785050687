import React, { createContext, useMemo, useState } from "react";

// Create the context
export const CustomerContext = createContext();

// Create a provider component
export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);

  // Function to update the customer object
  const updateCustomer = (newCustomer) => {
    setCustomer(newCustomer);
  };

  const valueMemo = useMemo(() => ({ customer, updateCustomer }), [customer]);

  return (
    <CustomerContext.Provider value={valueMemo}>
      {children}
    </CustomerContext.Provider>
  );
};
