import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Loader from "./Loader";
import { getCarrier } from "../utils/commonFn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

export function AddBookingModal({
  showAddBooking,
  handleClose,
  page,
  customerId,
  quote_id,
  selectedDocNumber,
  setSelectedDocNumber,
  getQuotes,
  getPoList,
  getShippingDocuments,
  state,
  setfinalPoError,
  setShowErrModal2,
  errormsg,
  setErrormsg,
}) {
  const [bookingNumber, setBookingNumber] = useState();
  const [bookingIdLoading, setBookingIdLoading] = useState(false);
  const [carrierList, setCarrierList] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const navigate = useNavigate();

  function handleClose1() {
    setBookingNumber();
    setBookingIdLoading(false);
    setSelectedOption();
    setSelectedOption();
    setSelectedDocNumber && setSelectedDocNumber();
    handleClose();
  }
  async function createShipment(e) {
    e.preventDefault();
    setBookingIdLoading(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmbooking/shipment/create`,
        {
          quote_id: quote_id,
          booking_id: bookingNumber,
          career_scac: selectedOption.value,
          customer_id: customerId,
          purchase_order_number: selectedDocNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        fnOnSuccess(response);
      } else {
        fnOnFailure(response)
      }
    } catch (err) {
     
      fnOnCatchBlock(err)
    }
  }

  async function fnOnSuccess(response) {
    getQuotes && (await getQuotes(customerId));
    getPoList && (await getPoList());
    getShippingDocuments && (await getShippingDocuments());
    setBookingIdLoading(false);
    handleClose1();

    if (page == "tracking" || page == "booking") {
      navigate(`/${customerId}/booking_number`, {
        state: {
          ...state,
          quote: {
            ...state?.quote,
            shipment_id: response.data?.shipment_id,
          },
        },
      });
      window.location.reload();
    }
  }

  async function fnOnFailure(response){
    setErrormsg && setErrormsg(response?.data?.message);
        setBookingIdLoading(false);
        setfinalPoError && setfinalPoError(response?.data?.message);
        setShowErrModal2 && setShowErrModal2(true);
  }

  async function fnOnCatchBlock(err){
    setBookingIdLoading(false);
    if (err.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    alert("error while adding booking  ", err.message);
  }

  useEffect(() => {
    getCarrier(setCarrierList, navigate);
  }, []);

  return (
    <Modal
      show={showAddBooking}
      centered
      onHide={handleClose1}
      className="tracking-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <h2 className="fw-600 fs-26 text-center">Add Booking Number</h2>
        <form onSubmit={createShipment} className="p-4">
          <div className="input-box mb-4">
            {bookingNumber && bookingNumber != "" && (
              <label>Booking Number *</label>
            )}
            <input
              required
              value={bookingNumber}
              onChange={(e) => setBookingNumber(e.target.value)}
              type="text"
              className="w-100"
              placeholder="Enter Your Booking Number*"
            />
          </div>
          <div className="mb-4">
            {selectedOption && <label className="mb-1">Carrier</label>}
            <Select
              placeholder="Select Carrier"
              required
              value={selectedOption}
              onChange={setSelectedOption}
              options={carrierList}
              isLoading={!carrierList?.length}
            />
          </div>

          <div className="d-flex flex-col justify-content-end">
            <p style={{ color: "red" }}>{errormsg}</p>
            <div>
              <button
                disabled={bookingIdLoading}
                type="submit"
                className="mt-4 py-2 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
              >
                {bookingIdLoading ? <Loader /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
