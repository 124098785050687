import { useMemo, useState } from "react";
import UserContext from "./UserContext";

const UserState = (props) => {
  const [quotationDetails, setquotationDetails] = useState([]);
  const [dataFetched, setdataFetched] = useState(true);
  const [selectedQuote, setselectedQuote] = useState({});
  // filter

  const [selectedDate, setselectedDate] = useState([]);
  const [clkData, setclkData] = useState([]);

  const [selectedStatus, setselectedStatus] = useState(
    ""
  );
  const [selectedCustomerId, setselectedCustomerId] = useState();

  const [selectedProductType, setselectedProductType] = useState("");

  const [editSave, setEditSave] = useState(false);
  // profit margin disbled
  const [profitMargin, setprofitMargin] = useState(false);

  const contextValue = useMemo(() => {
    return {
      quotationDetails,
      setquotationDetails,
      dataFetched,
      setdataFetched,
      selectedQuote,
      setselectedQuote,
      selectedDate,
      setselectedDate,
      clkData,
      setclkData,
      editSave,
      setEditSave,
      profitMargin,
      setprofitMargin,
      selectedStatus,
      setselectedStatus,
      selectedCustomerId,
      setselectedCustomerId,
      selectedProductType,
      setselectedProductType,
    };
  }, [
    quotationDetails,
    setquotationDetails,
    dataFetched,
    setdataFetched,
    selectedQuote,
    setselectedQuote,
    selectedDate,
    setselectedDate,
    clkData,
    setclkData,
    editSave,
    setEditSave,
    profitMargin,
    setprofitMargin,
    selectedStatus,
    setselectedStatus,
    selectedCustomerId,
    setselectedCustomerId,
    selectedProductType,
    setselectedProductType,
  ]);
  
  return (
    <UserContext.Provider
      value={contextValue}
    >
      {props?.children}
    </UserContext.Provider>
  );
};
export default UserState;
