import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
function Progress({ now, page }) {
  const [lastPage, setLastPage] = useState(10)
  const [nowCalculate, setNowCalculate] = useState(now)
  useEffect(() => {
    if(localStorage.getItem("modal_questions")){
      let l = JSON.parse(localStorage.getItem("modal_questions"))?.sort((a, b) => a.page_order - b.page_order)?.pop()?.page_order
     setLastPage(l)
     setNowCalculate((page / l) * 100);
     console.log(nowCalculate,'nowCalculate',l);
    } 
  }, [page])
  
  return (
    <div className="position-relative">
      <h2 className="text-center fw-700 fs-30">Request for Quote</h2>
      <div className="progress-count">
        <div className="prodress-box">
          <ProgressBar
            now={nowCalculate}
            label={`${nowCalculate}%`}
            variant="SOME_NAME"
            visuallyHidden
          />
        </div>
        <div className="d-flex pt-2">
          <div className="text-gray ms-auto">
            <span>{page}/{lastPage || 3}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Progress;
