import React from 'react'
import {Container, Row, Col,Tab,Tabs} from 'react-bootstrap';
import { CustomIcon } from '../components/Common';


function Booking_number() {
  return (
    <>
        <Container fluid className='booking-box'>
            <Row>
                <Col className='col-12 py-3'>
                    <div className='d-flex align-items-center justify-content-end pb-3'>
                        <div className='d-flex align-items-center'>
                            <svg className="icon me-2 fs-20">
                                <use href="#icon_clock"></use>
                            </svg> 
                            <strong>Last Update: &nbsp; </strong> May 31, 2023 12:06 GMT +5:30
                        </div>
                    </div>
                    <h2 className='fw-700 fs-30 text-center'>Booking Number: SNG0377074</h2>
                </Col>
            </Row>
            <Row className='border-left py-4 border-bottom'>
                <Col className='d-flex justify-content-center left-border'>
                    <div className='d-flex'>
                        <div className='track-icon'>
                            <svg className="icon me-2 fs-20">
                                <use href="#icon_origin"></use>
                            </svg>
                        </div>
                        <div>
                            <h6>Origin</h6>
                            <p>Veracruz, Mexico</p>
                        </div>
                    </div>
                </Col>
                <Col className='d-flex justify-content-center left-border'>
                    <div className='d-flex'>
                        <div className='track-icon'>
                            <svg className="icon me-2 fs-20">
                                <use href="#icon_location"></use>
                            </svg>
                        </div>
                        <div>
                            <h6>Destination</h6>
                            <p>Hamburg, Germany</p>
                        </div>
                    </div>
                </Col>
                <Col className='d-flex justify-content-center left-border'>
                    <div className='d-flex'>
                        <div className='track-icon'>
                            <svg className="icon me-2 fs-20">
                                <use href="#icon_type"></use>
                            </svg>
                        </div>
                        <div>
                            <h6>Type</h6>
                            <p>Ocean</p>
                        </div>
                    </div>
                </Col>
                <Col className='d-flex justify-content-center left-border'>
                    <div className='d-flex'>
                        <div className='track-icon'>
                            <svg className="icon me-2 fs-20">
                                <use href="#icon_calender"></use>
                            </svg>
                        </div>
                        <div>
                            <h6>Booking Date</h6>
                            <p>May 01, 2023</p>
                        </div>
                    </div>
                </Col>
                <Col className='d-flex justify-content-center left-border'>
                    <div className='d-flex'>
                        <div className='track-icon'>
                            <svg className="icon me-2 fs-20">
                                <use href="#icon_calender"></use>
                            </svg>
                        </div>
                        <div>
                            <h6>Delivery Date</h6>
                            <p>June 01, 2023</p>
                        </div>
                    </div>
                </Col>                
            </Row>
            <Row className='pt-5'>
                <Col className='col-6 number-grid'>
                    <div className="row d-flex ">
                        <div className="col-6">
                            <div className='icon-wrapper'>
                                <CustomIcon name="icon_origin" className="fs-4"/>
                            </div>
                            <div className="" style={{paddingLeft: "80px"}}>
                                <h6>Origin / Pickup </h6>
                                <p className="pt-2">
                                    Mexico City<br/>Mexico, 123456
                                </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <p className="">May 01, 2023 </p>
                            <p>12:06 PM </p>
                        </div>
                    </div>
                    <div className="row d-flex ">
                        <div className="col-6">
                            <div className='icon-wrapper'>
                                <CustomIcon name="icon_loading" className="fs-4"/>
                            </div>
                            <div className="" style={{paddingLeft: "80px"}}>
                                <h6>Port Of Loading </h6>
                                <p className="pt-2">
                                    Veracruz<br/>Mexico, 123456
                                </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <p className="">May 01, 2023 </p>
                            <p>12:06 PM </p>
                        </div>
                    </div>
                    <div className="row d-flex ">
                        <div className='icon-wrapper'>
                            <CustomIcon name="icon_transit" className="fs-5"/>
                        </div>
                        <div className="col-6">
                            <div className="" style={{paddingLeft: "80px"}}>
                                <h6>PIn Transit (Ocean) </h6>
                                <p className="pt-2">Carrier Name: Maersk </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <p className=""></p>
                        </div>
                    </div>
                    <div className="row d-flex ">
                        <div className='icon-wrapper'>
                            <CustomIcon name="icon_port" className="fs-3"/>
                        </div>
                        <div className="col-6">
                            <div className="" style={{paddingLeft: "80px"}}>
                                <h6>Port Of Discharge </h6>
                                <p className="pt-2">
                                    Hamburg<br/>Germany, 98764
                                </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <p className="">May 31, 2023 </p>
                            <p>12:06 PM </p>
                        </div>
                    </div>
                    <div className="row d-flex ">
                        <div className='icon-wrapper'>
                            <CustomIcon name="icon_delivered" className="fs-4"/>
                        </div>
                        <div className="col-6">
                            <div className="" style={{paddingLeft: "80px"}}>
                                <h6>Delivered </h6>
                                <p className="pt-2">
                                    Hamburg<br/>Germany, 98764
                                </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <p className="">May 01, 2023 </p>
                            <p>12:06 PM </p>
                        </div>
                    </div>
                </Col>
                <Col className='col-6'>
                    <div className=''>
                        <img className='w-100' src='images/map.png'/>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default Booking_number