import React from "react"; 
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./components/Routing";
import Icons from "./components/Icons";
import UserState from "./context/UserState";
import ErrorBoundary from "./components/ErrorBoundary";
import { CustomerProvider } from "./context/customerContext";
import { ContainerProvider } from "./context/containerContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <CustomerProvider>
        <ContainerProvider>
          <UserState>
            <Routing></Routing>
            <Icons></Icons>
          </UserState>
        </ContainerProvider>
      </CustomerProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
reportWebVitals();
