import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import axios from "axios";

function AddSkuModal({ addSKUDetails,
    setaddSKUDetails,
    addSKUDetailsError,
    setaddSKUDetailsError, showSKUAdd,handleCloseSKUAdd, setselectedSKUListOption,customerId,getSKUList,setmodalData }) {
    const [submittingAddNewSku, setsubmittingAddNewSku] = useState(false);
    const [submitAddNewSKUError, setsubmitAddNewSKUError] = useState(null);
 

  const navigate = useNavigate()

  const handleAddNewSKUChange = (e) => {
    setaddSKUDetails({
      ...addSKUDetails,
      [e.target.id]: e.target.value,
    });
    setaddSKUDetailsError({
      ...addSKUDetailsError,
      [e.target.id]: null,
    });
  };

  function smallCheckFnForSku(){
    let item_code =
      addSKUDetails?.item_code?.length == 0 ? "Please enter SKU" : null;
    let description =
      addSKUDetails.description?.length == 0
        ? "Please enter Description"
        : null;
    let unit_cost =
      addSKUDetails.unit_cost?.length == 0
        ? "Please enter Cost per unit"
        : null;

    let item_per_box =
      addSKUDetails.item_per_box?.length == 0
        ? "Please enter Items per box"
        : null;
    let weight =
      addSKUDetails.weight?.length == 0 ? "Please enter Weight" : null;

      if (item_code || description || unit_cost || item_per_box || weight) {
        setaddSKUDetailsError({
          item_code,
          description,
          unit_cost,
          item_per_box,
          weight,
        });
        return true
      }else{
        return false;
      }

  }

  const onSubmitAddNewSku = async (from) => {
    const token = localStorage.getItem("mtm_user_token");
    
    
    if (smallCheckFnForSku()) {
      return;
    }
    let data = {
      ...addSKUDetails,
      unit_cost: parseFloat(addSKUDetails.unit_cost),
      item_per_box: parseFloat(addSKUDetails.item_per_box),
      weight: parseFloat(addSKUDetails.weight),
    };
    delete data.qty;
    data["customer_id"] = customerId;
    setsubmittingAddNewSku(true);
    setsubmitAddNewSKUError(null);
    try {
      let response = await axios({
        url:
          from == "add"
            ? `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/add`
            : `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/edit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });
      if (response.status == 200) {
        if (!response.data.error) {
          getSKUList();
          setTimeout(() => {
            setmodalData && setmodalData({
              sku: addSKUDetails.item_code,
              qty: "",
              unit_cost: addSKUDetails.unit_cost,
              description: addSKUDetails.description,
              volume: "",
              pallet: "",
            });

            setselectedSKUListOption && setselectedSKUListOption({
              label: addSKUDetails.item_code,
              value: addSKUDetails.item_code,
            });
            setaddSKUDetails({
              customer_id: customerId,
              item_code: "",
              description: "",
              qty: "",
              unit_cost: "",
              item_per_box: "",
              weight: "",
            });
            handleCloseSKUAdd();
            setsubmittingAddNewSku(false);
          }, 2000);
        } else {
          setsubmitAddNewSKUError(response.data.message);
        }
      }
    } catch (error) {
      setsubmittingAddNewSku(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(error);
    }
  };

  
  

  return (
    <Modal
      show={showSKUAdd}
      centered
      onHide={() => {
        handleCloseSKUAdd();
        setselectedSKUListOption && setselectedSKUListOption(null);
      }}
      scrollable={true}
    >
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-4">
        <p className="text-center fs-26 fw-bold pb-2">Add SKU</p>

        <div className="input-box mb-3">
          {addSKUDetails.item_code && addSKUDetails.item_code != "" && (
            <label htmlFor="item_code">SKU *</label>
          )}
          <input
            type="text"
            className="w-100"
            id="item_code"
            value={addSKUDetails.item_code}
            onChange={handleAddNewSKUChange}
            placeholder="Enter SKU*"
          />
        </div>
        {addSKUDetailsError.item_code && (
          <div className="text-danger mb-3">{addSKUDetailsError.item_code}</div>
        )}

        <div className="input-box mb-3">
          {addSKUDetails.description && addSKUDetails.description != "" && (
            <label htmlFor="description">Description *</label>
          )}
          <input
            type="text"
            className="w-100"
            id="description"
            value={addSKUDetails.description}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Description*"
          />
        </div>
        {addSKUDetailsError.description && (
          <div className="text-danger mb-3">
            {addSKUDetailsError.description}
          </div>
        )}

        <div className="input-box mb-3">
          {addSKUDetails.unit_cost && addSKUDetails.unit_cost != "" && (
            <label htmlFor="unit_cost">Cost per unit *</label>
          )}
          <input
            type="number"
            className="w-100"
            id="unit_cost"
            value={addSKUDetails.unit_cost}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Cost per unit*"
          />
        </div>
        {addSKUDetailsError.unit_cost && (
          <div className="text-danger mb-3">{addSKUDetailsError.unit_cost}</div>
        )}
        <div className="input-box mb-3">
          {addSKUDetails.item_per_box && addSKUDetails.item_per_box != "" && (
            <label htmlFor="item_per_box">Items per box *</label>
          )}
          <input
            type="number"
            className="w-100"
            id="item_per_box"
            value={addSKUDetails.item_per_box}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Items per box*"
          />
        </div>
        {addSKUDetailsError.item_per_box && (
          <div className="text-danger mb-3">
            {addSKUDetailsError.item_per_box}
          </div>
        )}
        <div className="input-box mb-3">
          {addSKUDetails.weight && addSKUDetails.weight != "" && (
            <label htmlFor="weight">Weight *</label>
          )}
          <input
            type="number"
            className="w-100"
            id="weight"
            value={addSKUDetails.weight}
            onChange={handleAddNewSKUChange}
            placeholder="Enter Weight*"
          />
        </div>
        {addSKUDetailsError.weight && (
          <div className="text-danger mb-3">{addSKUDetailsError.weight}</div>
        )}

        {submitAddNewSKUError && (
          <div className="mb-3 text-danger">{submitAddNewSKUError}</div>
        )}

        <div className="d-flex">
          <div className="ms-auto">
            <button
              className="grd-btn rounded-pill"
              onClick={() => {
                if (addSKUDetails?.item_id) {
                  onSubmitAddNewSku("edit");
                } else {
                  onSubmitAddNewSku("add");
                }
              }}
              disabled={submittingAddNewSku}
            >
              {submittingAddNewSku ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddSkuModal;
