import React from 'react'

function ButtonComponent({organization_name}) {
  return (
    <>
       <button type='button' className='w-100'>
            <span>{organization_name}</span>
            <span>
            <svg className="icon fs-22">
                <use href="#icon_rightlongarrow"></use>
            </svg>
            </span>
        </button>
    </>
  )
}

export default ButtonComponent
