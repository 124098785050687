import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import axios from "axios";
import Loader from "../components/Loader";
import Progress from "../components/Progress";
import usePostQuoteHook from "../hooks/post-quote-details";
import { getQuestions } from "../utils/commonFn";
import QuoteMenu from "../components/quoteMenu";

function Pagethree() {
  const navigate = useNavigate();
  const [quoteDetails, setquoteDetails] = useState({});
  const [
    quoteDetailsCameFromLocalStorage,
    setquoteDetailsCameFromLocalStorage,
  ] = useState({});
  const { postQuoteDetailsFn } = usePostQuoteHook();
  const [isSelected, setIsSelected] = useState(false);
  const [allModals, setallModals] = useState([]);
  const [allProducts, setAllProducts] = useState([])
  const [loadingModals, setLoadingModals] = useState(false)
  const now = 19.999;
  const postQuoteDetails = async () => {
    try {
      let data = {
        ...quoteDetailsCameFromLocalStorage,
        service_type: quoteDetails?.modal_name,
        modal_id: quoteDetails?.modal_id,
        modal_name: quoteDetails?.modal_name,
        current_state: "3",
      };
      localStorage.setItem('modal_id', quoteDetails?.modal_id);
      await getQuestions(quoteDetails,navigate);
      await postQuoteDetailsFn(data, "/page/4", 0);
    } catch (error) {
      console.log(error);
    }
  };
  
  // const getAllModals = async () => {
  //   try {
  //     const token = localStorage.getItem("mtm_user_token");
  //     let response = await axios({
  //       url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/getModals`,
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         jwt_token: token,
  //       },
  //     });

  //     if (response.status == 200 && !response.data.error) {
  //       const filteredData = await response.data?.modals?.filter( modal=> allProducts.find(product=> product.clickup_product_id == modal.clickup_id))
  //       // console.log(filteredData);
  //       setallModals(filteredData);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error.response.status == 401) {
  //       localStorage.removeItem("mtm_user_token");
  //       localStorage.removeItem("mtm_user_data");
  //       navigate("/login");
  //       return;
  //     }
  //   }finally{
  //     setTimeout(() => {
  //       setLoadingModals(false)
  //     }, 1000);
  //   }
  // };
  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns/service_type`,
        {
          product_id: localStorage.getItem('service_type_id'),
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
          
        }
      );
      // console.log(clickup_data?.data?.dropdown_details);
      setAllProducts(clickup_data?.data?.dropdown_details || []);
      setallModals(clickup_data?.data?.dropdown_details || []);
      return clickup_data?.data?.dropdown_details 
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return 
      }
      console.log(err);
    }
  }

  useEffect(() => {
    async function getDropdownData() {
      setLoadingModals(true)
     let respModals =  await getDropdown() || []
      if (localStorage.getItem("quoteDetailsStored")) {
        let data = JSON.parse(localStorage.getItem("quoteDetailsStored"));
        setquoteDetailsCameFromLocalStorage(
          JSON.parse(localStorage.getItem("quoteDetailsStored"))
        );
        let modal = respModals.find(modal=> modal?.modal_name == data?.modal_name)
        console.log(modal,'found modal');
        setquoteDetails({
          ...quoteDetails,
          modal_id: modal?.modal_id,
          modal_name: modal?.modal_name,
        });
      }
      setLoadingModals(false)
    }
    getDropdownData();
  }, []);


  

  return (
    <>
      <NavBar />
      <div className="">
        <Container>
          <Row>
          <QuoteMenu page={3}/>
            <Col sm={10}>
              <div className="wrapper-box">
                <Progress now={now} page={3} />
                <div className="content-wrapper">
                  <p className="fw-600 fs-26 text-center ">Modal Types
                  {quoteDetailsCameFromLocalStorage?.product_type_name?.toLowerCase() == 'ocean lcl' && <div className=" fw-600 fs-26 pt-1">
                  Disclaimer: Please provide correct Zip Code/Postcode For modal types Port to Door, Door to Port and Door to Door.
                    </div>}
                  </p>
                  {  loadingModals? (
                    <div className="mt-5 text-center">
                      <Loader />
                    </div>
                  ) : (
                    <form className="pt-5">
                      {allModals?.map((item) => (
                        <>
                          <div className="mb-4">
                            <button
                              type="button"
                              className={
                                quoteDetails?.modal_name == item?.modal_name
                                  ? "w-100 type-choose-btn"
                                  : "w-100 type-btn"
                              }
                              onClick={() => {
                                setquoteDetails({
                                  ...quoteDetails,
                                  modal_name: item?.modal_name,
                                  modal_id: item?.modal_id,
                                });
                                setIsSelected(false);
                              }}
                            >
                              {item?.modal_name}
                              {quoteDetails?.modal_name == item?.modal_name && (
                                <span>
                                  <svg className="icon fs-20">
                                    <use href="#icon_check"></use>
                                  </svg>
                                </span>
                              )}
                            </button>
                          </div>
                        </>
                      ))}
                      {isSelected && (
                        <div className="d-flex justify-content-end pb-3">
                          <p style={{ color: "red" }}>
                            Please select Service Details
                          </p>
                        </div>
                      )}
                      <div className="d-flex justify-content-end py-3">
                        <div>
                          <button
                            type="button"
                            className="px-4 py-2 rounded-pill grdline-btn fs-20 fw-600 d-flex text-center align-items-center me-4"
                            onClick={() => navigate("/pagetwo")}
                          >
                            <svg className="icon me-2 fs-24">
                              <use href="#icon_leftarrow"></use>
                            </svg>
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="px-4 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                            onClick={() => {
                              if (!quoteDetails.modal_name) {
                                return setIsSelected(true);
                              }
                              postQuoteDetails();
                            }}
                          >
                            Next
                            <svg className="icon ms-2 fs-24">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Pagethree;
