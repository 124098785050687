import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

function Apo() {
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsModified, setoptionsModified] = useState([
    {
      label: "Vendor #1",
      value: "Vendor #1",
    },
    {
      label: "Vendor #2",
      value: "Vendor #2",
    },
    {
      label: "Vendor #3",
      value: "Vendor #3",
    },
    {
      label: "Vendor #4",
      value: "Vendor #4",
    },
    {
      label: "Add Vendor",
      value: "Add Vendor",
    },
  ]);

  const handleChange = (option) => {
    console.log(option, "selected");
    setSelectedOption(option);
  };

  const [selectedOptionSeaport, setSelectedOptionSeaport] = useState(null);
  const [optionsSeaport, setoptionsSeaport] = useState([
    {
      label: "India",
      value: "india",
    },
    {
      label: "Nepal",
      value: "nepal",
    },
    {
      label: "USA",
      value: "usa",
    },
    {
      label: "Japan",
      value: "japan",
    },
  ]);

  const handleChangeSeaport = (option) => {
    console.log(option, "selected");
    setSelectedOptionSeaport(option);
  };

  const [selectedOptionAirport, setSelectedOptionAirport] = useState(null);
  const [optionsAirport, setoptionsAirport] = useState([
    {
      label: "Agra",
      value: "agra",
    },
    {
      label: "Lucknow",
      value: "lucknow",
    },
    {
      label: "Kanpur",
      value: "kanpur",
    },
    {
      label: "Delhi",
      value: "delhi",
    },
  ]);

  const handleChangeAir = (option) => {
    console.log(option, "selected");
    setSelectedOptionAirport(option);
  };

  //vendor modal
  const [showvendor, setShowvendor] = useState(false);

  const handleClosevendor = () => setShowvendor(false);
  const handleShowvendor = () => setShowvendor(true);
  //

  useEffect(() => {
    if (selectedOption?.label == "Add Vendor") {
      handleShowvendor();
    }
  }, [selectedOption?.label]);

  return (
    <>
      <div className="pb-4">
        <div className="py-3">
          <button
            type="button"
            className="back-btn d-flex alig-items-center"
            onClick={() => {
              navigate("/");
            }}
          >
            <svg className="icon me-2 fs-22">
              <use href="#icon_leftarrow"></use>
            </svg>
            <strong className="fs-14">Back</strong>
          </button>
        </div>
        <div className="content-wrapper">
          <h2 className="fw-700 fs-24 text-center pb-4">Add Purchase Order</h2>
          <div className="mb-3">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={optionsModified}
              isSearchable={true}
              placeholder="Assign Vendor"
              isClearable
            />
          </div>
          <div className="input-box mb-3">
            <input type="text" className="w-100" placeholder="PO Number*" />
          </div>
          <div className="input-box mb-3">
            <input type="text" className="w-100" placeholder="SKU*" />
          </div>
          <div className="input-box mb-3">
            <input type="text" className="w-100" placeholder="Description*" />
          </div>
          <div className="input-box mb-3">
            <input type="text" className="w-100" placeholder="Quantity(pcs)" />
          </div>
          <div className="input-box mb-3">
            <input type="text" className="w-100" placeholder="Volume CBM" />
          </div>
          <div className="input-box mb-3">
            <input type="text" className="w-100" placeholder="# Pallets" />
          </div>
          <div className="input-box mb-3">
            <input type="text" className="w-100" placeholder="Unit Cost" />
          </div>
          <div className="input-box mb-3">
            <input type="date" className="w-100" />
          </div>
          <div className="d-flex">
            <div className="ms-auto">
              <button className="grd-btn rounded-pill">Submit</button>
            </div>
          </div>
        </div>
      </div>
      {/* Vendor Modal */}
      <Modal
        show={showvendor}
        centered
        onHide={handleClosevendor}
        scrollable={true}
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>
        <Modal.Body className="px-4">
          <p className="text-center fs-26 fw-bold pb-2">Add Vendor</p>
          <div className="input-box mb-3">
            <input
              type="text"
              className="w-100"
              placeholder="Enter Company Name*"
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="text"
              className="w-100"
              placeholder="Enter Origin Country*"
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="text"
              className="w-100"
              placeholder="Enter Contact Person Name*"
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="email"
              className="w-100"
              placeholder="Enter Contact Person Email*"
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="tel"
              className="w-100"
              placeholder="Enter Contact Person Phone*"
            />
          </div>
          <div className="input-box mb-3">
            <div className="form-floating">
              <textarea
                className="form-control"
                placeholder="We chat or other communication way "
                id="floatingTextarea"
              ></textarea>
            </div>
          </div>
          <div className="mb-3">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={optionsModified}
              isSearchable={true}
              placeholder="Origin Country"
              isClearable
            />
          </div>
          <div className="mb-3">
            <Select
              value={selectedOptionSeaport}
              onChange={handleChangeSeaport}
              options={optionsSeaport}
              isSearchable={true}
              placeholder="Nearest Sea Port of Loading"
              isClearable
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="text"
              className="w-100"
              placeholder="Nearest Air Port of Loading"
            />
          </div>
          <div className="input-box mb-3">
            <input
              type="text"
              className="w-100"
              placeholder="Nearest Inland Transport"
            />
          </div>
          <div className="d-flex">
            <div className="ms-auto">
              <button
                className="grd-btn rounded-pill"
                onClick={handleClosevendor}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Apo;
