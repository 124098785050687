import React from 'react'

export const CustomIcon = (props) => {
  return (
    <svg className={`icon ${props.className}`}><use href={`#${props.name}`}></use></svg>
  )
}

export const Alert = (props) =>{
  return(
    <div className={`alert alert-${props.type}`}>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt omnis ad repudiandae sint! Consequatur illo debitis, perspiciatis amet eum, maiores, a magnam neque eos dolore assumenda ipsum? Non, molestias saepe.
    </div>
  )
}