import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const useTokenExpiry = () => {
  const navigate = useNavigate();

  function isTokenExpired() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      if (!token) {
        navigate("/"); // Navigate to the home route if the token is not present
      }

      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        navigate("/"); // Navigate to the home route if the token is expired
      }
    } catch (error) {
      navigate("/"); // Navigate to the home route if there's an error decoding the token
    }
  }

  useEffect(() => {
    isTokenExpired(); // Check token expiry when the component mounts or whenever the token is updated
  }, []);

  return { isTokenExpired };
};

export default useTokenExpiry;
