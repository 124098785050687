import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import NavBar from "../components/NavBar";

function LayoutOutlet() {
  const navigate = useNavigate();

  useEffect(() => {
    // Get the token from local storage
    const token = localStorage.getItem("mtm_user_token");

    // Check if the token is present and not expired
    const isTokenValid = token && !isTokenExpired(token);

    if (!isTokenValid) {
      // Redirect to the login page if the token is not valid or expired
      navigate("/");
    }
  }, []);

  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      // If the token is invalid or cannot be decoded, consider it expired
      return true;
    }
  };

  return (
    <>
      <NavBar />
      <div className="wrapper bg-gray">
        <Outlet />
      </div>
    </>
  );
}

export default LayoutOutlet;
