import React, { useEffect, useState } from "react";
import useTokenExpiry from "../hooks/use-token-expiry";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Loader from "./Loader";

function ModalCustomer({
  postGetList,
  handleCurrentPage,
  getRecentList,
  from,
}) {
  const { isTokenExpired } = useTokenExpiry();
  const [showCustomer, setshowCustomer] = useState(false);
  const handleCloseCustomer = () => {
    setshowCustomer(false);
  };
  const handleShowCustomer = () => setshowCustomer(true);
  const [creating, setcreating] = useState(false);
  const handleChangeCustomer = (e) => {
    const { id, value } = e.target;
    setcreateCustomer((prevCreateCustomer) => ({
      ...prevCreateCustomer,
      [id]: value,
    }));
    // Clear the error message for the input box
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: "",
    }));
  };
  const [createCustomer, setcreateCustomer] = useState({
    customer_id: "",
    organization_name: "",
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    creator_name: "",
    creator_email: "",
    status: "",
    web_url: "",
    director_name: "",
    director_email: "",
    director_phone: "",
    finance_name: "",
    finance_email: "",
    finance_phone: "",
    sales_name: "",
    sales_email: "",
    sales_phone: "",
    operation_name: "",
    operation_email: "",
    operation_phone: "",
    tax_id: "",
    credit_days: "",
    credit_amount_limit: "",
    opportunity_size: "",
    currency: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    industry: "",
    customer_type: "",
    customer_designation: "",
    customer_type_everest: "",
    clickup_space_name: "",
  });
  const [errors, setErrors] = useState({});
  const submitCreateCustomer = async (e) => {
    e.preventDefault();
    isTokenExpired();
    setErrors({
      ...errors,
      finalCreating: null,
    });
    if (validateForm()) {
      // Proceed with form submission
      // ...
      setcreating(true);

      try {
        let token = localStorage.getItem("mtm_user_token");

        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/create`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
          data: {
            ...createCustomer,
            user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
            everest_type: createCustomer.customer_type_everest == "yes" ? 1 : 0,
          },
        });
        setcreating(false);
        if (!response.data.error) {
          handleCloseCustomer();
          setcreateCustomer({
            customer_id: "",
            organization_name: "",
            customer_name: "",
            customer_email: "",
            customer_designation: "",
            customer_phone: "",
            creator_name: "",
            creator_email: "",
            status: "",
            web_url: "",
            director_name: "",
            director_email: "",
            director_phone: "",
            finance_name: "",
            finance_email: "",
            finance_phone: "",
            sales_name: "",
            sales_email: "",
            sales_phone: "",
            operation_name: "",
            operation_email: "",
            operation_phone: "",
            tax_id: "",
            credit_days: "",
            credit_amount_limit: "",
            opportunity_size: "",
            currency: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            industry: "",
            customer_type: "",
            customer_type_everest: "",
            clickup_space_name: "",
          });
          if (from == "crm") {
            getRecentList();
          } else {
            handleCurrentPage();
            postGetList();
          }
        } else {
          setErrors({
            ...errors,
            finalCreating: response.data.message,
          });
        }
      } catch (error) {
        setcreating(false);
        setErrors({
          ...errors,
          finalCreating: error.message,
        });
        if (error.code === "ECONNABORTED") {
          console.log("Request timed out");
        } else {
          console.log("An error occurred:", error);
        }
      }
    }
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Check each input value
    if (createCustomer.organization_name === "") {
      newErrors.organization_name = "Please Enter Organization Name";
      valid = false;
    }

    if (createCustomer.industry === "") {
      newErrors.industry = "Please Enter Industry";
      valid = false;
    }

    if (createCustomer.customer_name === "") {
      newErrors.customer_name = "Please Enter Contact Name";
      valid = false;
    }

    if (createCustomer.customer_designation === "") {
      newErrors.customer_designation = "Please Enter Contact Title";
      valid = false;
    }

    if (createCustomer.customer_email === "") {
      newErrors.customer_email = "Please Enter Contact Email";
      valid = false;
    }

    if (createCustomer.customer_phone === "") {
      newErrors.customer_phone = "Please Enter Contact Phone";
      valid = false;
    }

    if (createCustomer.opportunity_size === "") {
      newErrors.opportunity_size = "Please Enter Opportunity size";
      valid = false;
    }

    if (createCustomer.customer_type === "") {
      newErrors.customer_type = "Please Choose Customer Type";
      valid = false;
    }

    if (createCustomer.customer_type_everest === "") {
      newErrors.customer_type_everest =
        "Please Choose Is Customer Type Everest";
      valid = false;
    }
    if (createCustomer.clickup_space_name === "") {
      newErrors.clickup_space_name = "Please Enter Clickup Space Name";
      valid = false;
    }

    // Set the error messages
    setErrors(newErrors);

    return valid;
  };

  return (
    <>
      <button
        type="button"
        className="grd-btn rounded-pill"
        onClick={() => {
          handleShowCustomer();
        }}
      >
        Add Customer
      </button>
      <Modal
        show={showCustomer}
        centered
        onHide={handleCloseCustomer}
        className="tracking-modal"
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setErrors({});
            setcreateCustomer({
              customer_id: "",
              organization_name: "",
              customer_name: "",
              customer_email: "",
              customer_designation: "",
              customer_phone: "",
              creator_name: "",
              creator_email: "",
              status: "",
              web_url: "",
              director_name: "",
              director_email: "",
              director_phone: "",
              finance_name: "",
              finance_email: "",
              finance_phone: "",
              sales_name: "",
              sales_email: "",
              sales_phone: "",
              operation_name: "",
              operation_email: "",
              operation_phone: "",
              tax_id: "",
              credit_days: "",
              credit_amount_limit: "",
              opportunity_size: "",
              currency: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              country: "",
              zip_code: "",
              industry: "",
              customer_type: "",
              customer_type_everest: "",
              clickup_space_name: "",
            });
          }}
        >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">Add Customer</h2>
          <form className="p-4">
            <div className="input-box mb-4">
              {createCustomer?.organization_name &&
                createCustomer?.organization_name != "" && (
                  <label htmlFor="organization_name" className="">
                    Organization Name
                  </label>
                )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Organization name"
                id="organization_name"
                value={createCustomer?.organization_name}
                onChange={handleChangeCustomer}
              />
              {errors.organization_name && (
                <p className="error-message text-danger">
                  {errors.organization_name}
                </p>
              )}
            </div>
            <div className="input-box mb-4">
              {createCustomer?.clickup_space_name &&
                createCustomer?.clickup_space_name != "" && (
                  <label htmlFor="clickup_space_name" className="">
                    Clickup Space Name
                  </label>
                )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Clickup Space Name"
                id="clickup_space_name"
                value={createCustomer?.clickup_space_name}
                onChange={handleChangeCustomer}
              />
              {errors.clickup_space_name && (
                <p className="error-message text-danger">
                  {errors.clickup_space_name}
                </p>
              )}
            </div>
            <div className="input-box mb-4">
              {createCustomer?.industry && createCustomer?.industry != "" && (
                <label htmlFor="industry" className="">
                  Industry
                </label>
              )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Industry"
                id="industry"
                value={createCustomer?.industry}
                onChange={handleChangeCustomer}
              />
              {errors.industry && (
                <p className="error-message text-danger">{errors.industry}</p>
              )}
            </div>
            <div className="input-box mb-4">
              {createCustomer?.customer_name &&
                createCustomer?.customer_name != "" && (
                  <label htmlFor="customer_name" className="">
                    Contact Name
                  </label>
                )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Contact Name"
                id="customer_name"
                value={createCustomer?.customer_name}
                onChange={handleChangeCustomer}
              />
              {errors.customer_name && (
                <p className="error-message text-danger">
                  {errors.customer_name}
                </p>
              )}
            </div>
            <div className="input-box mb-4">
              {createCustomer?.customer_designation &&
                createCustomer?.customer_designation != "" && (
                  <label htmlFor="customer_designation" className="">
                    Contact Title
                  </label>
                )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Contact Title"
                id="customer_designation"
                value={createCustomer?.customer_designation}
                onChange={handleChangeCustomer}
              />
              {errors.customer_designation && (
                <p className="error-message text-danger">
                  {errors.customer_designation}
                </p>
              )}
            </div>
            <div className="input-box mb-4">
              {createCustomer?.customer_email &&
                createCustomer?.customer_email != "" && (
                  <label htmlFor="customer_email" className="">
                    Contact Email
                  </label>
                )}
              <input
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Contact Email"
                id="customer_email"
                value={createCustomer?.customer_email}
                onChange={handleChangeCustomer}
              />
              {errors.customer_email && (
                <p className="error-message text-danger">
                  {errors.customer_email}
                </p>
              )}
            </div>
            <div className="input-box mb-2">
              {createCustomer?.customer_phone &&
                createCustomer?.customer_phone != "" && (
                  <label htmlFor="customer_phone" className="">
                    Contact Phone
                  </label>
                )}
              <input
                required
                type="number"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Contact Phone"
                id="customer_phone"
                value={createCustomer?.customer_phone}
                onChange={handleChangeCustomer}
              />
              {errors.customer_phone && (
                <p className="error-message text-danger mb-3">
                  {errors.customer_phone}
                </p>
              )}
            </div>
            <label className="mb-2">Customer Type</label>
            <div
              className={
                errors.customer_type
                  ? "d-flex border-bottom pb-3 "
                  : "d-flex border-bottom pb-3 mb-3"
              }
            >
              <input
                checked={createCustomer?.customer_type === "spot"}
                type="radio"
                name="cargo"
                id="spot"
                onChange={(e) => {
                  setcreateCustomer({
                    ...createCustomer,
                    customer_type: "spot",
                  });
                  setErrors({ ...errors, customer_type: "" });
                }}
              />
              <label className="mx-2 me-3" htmlFor="spot">
                Spot
              </label>
              <input
                checked={createCustomer?.customer_type === "regular"}
                type="radio"
                name="cargo"
                id="regular"
                onChange={(e) => {
                  setcreateCustomer({
                    ...createCustomer,
                    customer_type: "regular",
                  });
                  setErrors({ ...errors, customer_type: "" });
                }}
              />

              <label className="mx-2" htmlFor="regular">
                Regular
              </label>
            </div>
            {errors.customer_type && (
              <p className="error-message text-danger mb-3">
                {errors.customer_type}
              </p>
            )}
            <div className="input-box mb-4">
              {createCustomer?.opportunity_size &&
                createCustomer?.opportunity_size != "" && (
                  <label htmlFor="opportunity_size" className="">
                    Opportunity size
                  </label>
                )}
              <input
                required
                type="number"
                className="w-100 border-bottom pb-3"
                placeholder="Enter Opportunity size"
                id="opportunity_size"
                value={createCustomer?.opportunity_size}
                onChange={handleChangeCustomer}
              />
              {errors.opportunity_size && (
                <p className="error-message text-danger mb-3">
                  {errors.opportunity_size}
                </p>
              )}
            </div>

            <label className="mb-2">Is customer type 5PL</label>
            <div
              className={
                errors.customer_type
                  ? "d-flex border-bottom pb-3 "
                  : "d-flex border-bottom pb-3 mb-3"
              }
            >
              <input
                checked={createCustomer?.customer_type_everest === "yes"}
                type="radio"
                name="cargo_everest"
                id="yes"
                onChange={(e) => {
                  setcreateCustomer({
                    ...createCustomer,
                    customer_type_everest: "yes",
                  });
                  setErrors({ ...errors, customer_type_everest: "" });
                }}
              />
              <label className="mx-2 me-3" htmlFor="spot">
                Yes
              </label>
              <input
                checked={createCustomer?.customer_type_everest === "no"}
                type="radio"
                name="cargo_everest"
                id="no"
                onChange={(e) => {
                  setcreateCustomer({
                    ...createCustomer,
                    customer_type_everest: "no",
                  });
                  setErrors({ ...errors, customer_type_everest: "" });
                }}
              />

              <label className="mx-2" htmlFor="regular">
                No
              </label>
            </div>
            {errors.customer_type_everest && (
              <p className="error-message text-danger mb-3">
                {errors.customer_type_everest}
              </p>
            )}

            <div className="d-flex justify-content-end">
              <div>
                {errors.finalCreating && (
                  <p className="error-message text-danger mb-3">
                    {errors.finalCreating}
                  </p>
                )}
                <button
                  type="submit"
                  className="mt-4 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                  disabled={creating}
                  onClick={(e) => submitCreateCustomer(e)}
                >
                  {creating ? <Loader /> : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalCustomer;
