import { React, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";
import Loader from "../components/Loader";

function Login() {
  const navigate = useNavigate();
  const [loginDetails, setloginDetails] = useState({
    email: "",
    password: "",
    eyeShown: false,
  });
  const [error, seterror] = useState({
    status: false,
    message: "",
  });
  const [loading, setloading] = useState(false);
  const loginButton = async () => {
    seterror({
      status: false,
      message: "",
    });
    try {
      setloading(true);
      const cipherText = CryptoJS.AES.encrypt(
        loginDetails.password,
        process.env.REACT_APP_SECRET
      );
      console.log(cipherText.toString(), "cipher test password");
      const data = {
        email: loginDetails.email,
        password: cipherText.toString(),
        app_type: "sales",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmuser/login`,
        data
      );
      console.log(response, "result of login");
      if (response.status == 200) {
        setloading(false);
        if (response.data.success) {
          localStorage.setItem("mtm_user_token", response?.data?.token);
          localStorage.setItem(
            "mtm_user_data",
            JSON.stringify(response?.data?.data)
          );
          navigate("/home");
        }
      } else {
        setloading(false);
        console.log("Error");
      }
    } catch (error) {
      setloading(false);
      seterror({
        status: true,
        message: error?.response?.data?.msg,
      });
      console.log(error);
    }
  };

  const handleChange = async (e) => {
    setloginDetails({
      ...loginDetails,
      [e.target.id]: e.target.value,
    });
  };
  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      return;
    }
    const decodedToken = jwt_decode(token);
    if (decodedToken.exp < Date.now() / 1000) {
      // Token has expired
    } else {
      // Token is still valid
      navigate("/home");
    }
  }, []);
  return (
    <>
      <div className="signin-box">
        <Row className="h-100 w-100 g-0">
          <Col sm={5} className="signin-left">
            <img src="images/left-img.png"></img>
          </Col>
          <Col sm={7} className="signin-right">
            <div className="d-flex justify-content-center pb-4">
              <img
                src="images/logo.png"
                className="w-100"
                alt="...."
                style={{ maxWidth: "240px", maxHeight: "80px" }}
              />
            </div>
            <h2 className="text-center fw-700 fs-40">Sales</h2>
            <h2 className="fw-700 fs-40 pt-5">Log In</h2>
            <form className="my-5">
              <div className="mb-3">
                <label for="exampleFormControlInputEmail" class="form-label">
                  Email ID
                </label>
                <input
                  type="email"
                  class="form-control form-control-lg input-shadow border-0"
                  id="email"
                  value={loginDetails?.email}
                  placeholder="Enter your email"
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label
                  for="exampleFormControlInputPassword"
                  class="form-label fs-18 fw-400"
                >
                  Password
                </label>
                <div className="position-relative">
                  <input
                    type={loginDetails.eyeShown ? "text" : "password"}
                    class="form-control form-control-lg input-shadow border-0"
                    id="password"
                    value={loginDetails?.password}
                    placeholder="Enter your password"
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="vision"
                    onClick={() =>
                      setloginDetails({
                        ...loginDetails,
                        eyeShown: !loginDetails.eyeShown,
                      })
                    }
                  >
                    {loginDetails.eyeShown ? (
                      <svg className="icon">
                        <use href="#icon_vision_withoutslash"></use>
                      </svg>
                    ) : (
                      <svg className="icon">
                        <use href="#icon_vision"></use>
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center text-danger fw-600 fs-20">
              {error.status && <>{error.message}</>}
            </div>
            <button
              type="button"
              disabled={loading}
              className="mb-5 mt-1 grd-btn fs-20 fw-600 w-100 d-flex text-center p-2"
              onClick={loginButton}
            >
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                "Login"
              )}
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;
