import { useNavigate } from "react-router-dom";
import useTokenExpiry from "./use-token-expiry";
import axios from "axios";

const usePostQuoteHook = () => {
  const navigate = useNavigate();
  const { isTokenExpired } = useTokenExpiry();

  const postQuoteDetailsFn = async (data, navigate_to, quote_states) => {
    isTokenExpired();
    try {
      let token = localStorage.getItem("mtm_user_token");
      let dataAdd = {
        ...data,
        quote_action: data?.task_id ? "update" : "add",
        quote_states: quote_states,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/opportunity/quote/create`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: dataAdd,
      });
      if (navigate_to == "home") {
        return response;
      }
      if (!response.data.error) {
        delete data.quote_action;
        data["quote_id"] = response?.data?.quote_id;
        localStorage.setItem("quoteDetailsStored", JSON.stringify(data));
        navigate(navigate_to);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  return { postQuoteDetailsFn };
};

export default usePostQuoteHook;
