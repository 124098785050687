import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import validator from "email-validator";
import Progress from "../components/Progress";
import usePostQuoteHook from "../hooks/post-quote-details";
import { DatePicker } from "antd";
import moment from "moment";
import QuoteMenu from "../components/quoteMenu";
import { conditionalExp } from "../utils/commonFn";

function Pageone() {
  const navigate = useNavigate();
  const { postQuoteDetailsFn } = usePostQuoteHook();
  const [quoteDetails, setquoteDetails] = useState({});
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);

  const [
    quoteDetailsCameFromLocalStorage,
    setquoteDetailsCameFromLocalStorage,
  ] = useState({});
  const handleChange = (e) => {
    if (e.target.id == "opportunity_name" && e.target.value == "") {
      setIsValidName(true);
    } else {
      setIsValidName(false);
    }
    if (
      e.target.id == "opportunity_email" &&
      !validator.validate(e.target.value)
    ) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
    setquoteDetails({ ...quoteDetails, [e.target.id]: e.target.value });
  };

  console.log(quoteDetails, "quote details.......");
  const now = 6.666;
  const update_clicked = localStorage.getItem("update_clicked");
  const postQuoteDetails = async () => {
    try {
      let data = {
        ...quoteDetailsCameFromLocalStorage,
        opportunity_name: quoteDetails?.opportunity_name,
        quote_receiver_email: quoteDetails?.opportunity_email,
        current_state: "1",
        quote_states: 0,
        departure_date: quoteDetails?.dispatch_date,
      };
      await postQuoteDetailsFn(data, "/pagetwo", 0);
    } catch (error) {
      console.log(error);
    }
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (localStorage.getItem("quoteDetailsStored")) {
      let data = JSON.parse(localStorage.getItem("quoteDetailsStored"));
      setquoteDetailsCameFromLocalStorage(
        JSON.parse(localStorage.getItem("quoteDetailsStored"))
      );
      setquoteDetails({
        opportunity_name: data?.opportunity_name,
        opportunity_email: data?.quote_receiver_email,
        dispatch_date: data?.departure_date,
      });
    }
  }, []);

  return (
    <>
      <NavBar />
      <div className="">
        <Container>
          <Row>
          <QuoteMenu page={1}
          />
            <Col sm={10}>
              <div className="wrapper-box">
                <Progress now={now} page={1} />
                <div className="content-wrapper">
                  <p className="fw-600 fs-26 text-center ">
                    Opportunity Detail
                  </p>
                  <form className="mb-3 py-3">
                    <div className=" border-bottom py-3">
                      <input
                        type="text"
                        className=" w-100"
                        placeholder="Opportunity Name*"
                        id="opportunity_name"
                        value={quoteDetails?.opportunity_name}
                        onChange={handleChange}
                      />
                    </div>
                    {isValidName && (
                      <p
                        className="mb-3"
                        style={{ color: "red", fontWeight: "lighter" }}
                      >
                        Please provide Opportunity name.
                      </p>
                    )}
                    <div className=" border-bottom py-3">
                      <input
                        type="email"
                        className=" w-100"
                        placeholder="Quote Receiver Email*"
                        id="opportunity_email"
                        value={quoteDetails?.opportunity_email}
                        onInputCapture={handleChange}
                      />
                    </div>
                    {isValidEmail && (
                      <p
                        className="mb-3"
                        style={{ color: "red", fontWeight: "lighter" }}
                      >
                        Please provide a valid Receivers Email.
                      </p>
                    )}
                    <div className=" border-bottom py-3">
                      <label htmlFor="dispatch_date">Departure Date</label>
                      {/* <input

                        type="date"
                        className=" w-100"
                        placeholder=""
                        id="dispatch_date"
                        value={quoteDetails?.dispatch_date}
                        min={getCurrentDate()}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setquoteDetails({
                            ...quoteDetails,
                            dispatch_date: e.target.value,
                          });
                          if (e.target.value) {
                            setIsValidDate(false);
                          }
                        }}
                      /> */}
                      <DatePicker
                        className=" w-100 custom-datepicker"
                        rootClassName="custom-datepicker"
                        placeholder={
                          quoteDetails?.dispatch_date || "Select date"
                        }
                        id="dispatch_date"
                        disabledDate={(current) => {
                          // Can not select days before today and today
                          return current && current < moment().startOf("day");
                        }}
                        onChange={(e) => {
                          setquoteDetails({
                            ...quoteDetails,
                            dispatch_date: e?.format("YYYY-MM-DD"),
                          });
                          if (e) {
                            setIsValidDate(false);
                          }
                        }}
                      />
                    </div>
                    <p className="fw-600">
                      For getting better result the max suggested Departure Date
                      is one month from today
                    </p>
                    {isValidDate && (
                      <p
                        className="mb-3"
                        style={{ color: "red", fontWeight: "lighter" }}
                      >
                        Please select Dispatch Date
                      </p>
                    )}
                    <div className="d-flex justify-content-end py-3">
                      {conditionalExp(!update_clicked,<div>
                        <button
                          type="button"
                          className="px-4 py-2 rounded-pill grdline-btn fs-20 fw-600 d-flex text-center align-items-center me-4"
                          onClick={() => navigate("/choosecustomer")}
                        >
                          <svg className="icon me-2 fs-24">
                            <use href="#icon_leftarrow"></use>
                          </svg>
                          Back
                        </button>
                      </div>)}
                      <div>
                        <button
                          disabled={isValidName || isValidEmail || isValidDate}
                          type="button"
                          className="px-4 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                          onClick={() => {
                            if (
                              !quoteDetails?.opportunity_email ||
                              !quoteDetails?.opportunity_name
                            ) {
                              !quoteDetails?.opportunity_email &&
                                setIsValidEmail(true);
                              !quoteDetails?.opportunity_name &&
                                setIsValidName(true);
                              return;
                            }
                            if (!quoteDetails.dispatch_date) {
                              setIsValidDate(true);
                              return;
                            }
                            postQuoteDetails();
                          }}
                        >
                          Next
                          <svg className="icon ms-2 fs-24">
                            <use href="#icon_rightarrow"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Pageone;
