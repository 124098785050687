import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment";
import axios from "axios";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import UserContext from "../context/UserContext";
import useTokenExpiry from "../hooks/use-token-expiry";
import { DatePicker, Switch } from "antd";
import { conditionalExp, getQuestions } from "../utils/commonFn";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { CustomIcon } from "../components/Common";

const { RangePicker } = DatePicker;
function Home() {
  const context = useContext(UserContext);
  const { isTokenExpired } = useTokenExpiry();
  const {
    selectedStatus,
    setselectedStatus,
    selectedProductType,
    setselectedProductType,
    selectedDate,
    setselectedDate,
    selectedCustomerId,
    setselectedCustomerId,
  } = context;
  const navigate = useNavigate();
  const [quoteDetailsFetched, setquoteDetailsFetched] = useState([]);
  const [dataFetched, setdataFetched] = useState(false);
  const [clickupData, setClickupData] = useState([]);
  const [showDelete, setshowDelete] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const [selectedOpprotunityQuote, setselectedOpprotunityQuote] = useState({});
  const [lostReason, setlostReason] = useState("");
  const [lostDetailsFetched, setlostDetailsFetched] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showThankyou, setShowThankyou] = useState(false);
  const handleCloseThankyou = () => setShowThankyou(false);
  const handleShowThankyou = () => setShowThankyou(true);
  const handleCloseDelete = () => setshowDelete(false);
  const handleShowDelete = (quote_id) => setshowDelete(quote_id);
  const [deleteId, setdeleteId] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, sethasMore] = useState(false);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) {
        return;
      }
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        console.log(entries, "entries");
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );

  console.log(quoteDetailsFetched, "qu");

  const dateFormat = "DD-MM-YYYY";
  const getFormattedDate = (item) => {
    const currentDate = item["$d"];
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getFilterDatesFn = async (delete_id="") => {
    isTokenExpired();
    setIsLoadingMore(true);

    try {
      let token = localStorage.getItem("mtm_user_token");
      let from_date =
        selectedDate?.length > 0 ? getFormattedDate(selectedDate[0]) : "";
      let to_date =
        selectedDate?.length > 0 ? getFormattedDate(selectedDate[1]) : "";
      let data = {};

      data = {
        from_date: from_date,
        to_date: to_date,
        quote_status: selectedStatus,
        product_type: selectedProductType,
        user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        page: currentPage?.toString(),
        customer_id: selectedCustomerId?.value,
      };

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/opportunity/quote/search`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: data,
      });

      if (!response.data.error) {
        setIsLoadingMore(false);
        setdataFetched(true);
        let responseData=response.data.quote_details?.filter(item=>item?.quote_id!=delete_id)
        let newQuotes = responseData.filter((newQuote) => {
          return !quoteDetailsFetched.some(
            (existingQuote) => existingQuote.quote_id === newQuote.quote_id
          );
        });
        newQuotes = newQuotes?.map((item) => {
          return { ...item, toggleSwitch: false };
        });
        setquoteDetailsFetched([...quoteDetailsFetched, ...newQuotes]);
        sethasMore(response?.data?.quote_details?.length);
      }
    } catch (error) {
      setdataFetched(true);
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  const submitButtonFromModal = async () => {
    isTokenExpired();

    setlostDetailsFetched(false);
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/lost`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        // timeout: 10000,
        data: {
          quote_id: selectedOpprotunityQuote?.quote_id,
          lost_reason: lostReason,
        },
      });

      if (!response.data.error) {
        getFilterDatesFn();
        setlostDetailsFetched(true);
        setlostReason("");
        handleClose();
        handleShowThankyou();
        setTimeout(() => {
          handleCloseThankyou();
        }, 2500);
      }
    } catch (error) {
      setlostDetailsFetched(true);
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  const toggleItemSwitch = (item) => {
    return {
      ...item,
      toggleSwitch: !item?.toggleSwitch || true,
    };
  };

  const toggleOnChange = (quote) => {
    if (quote) {
      setquoteDetailsFetched((prev) => {
        return prev?.map((item) => {
          if (item?.quote_id === quote?.quote_id) {
            setselectedOpprotunityQuote(quote);
            if (!item?.toggleSwitch) {
              handleShow();
            }
            return toggleItemSwitch(item);
          } else {
            return item;
          }
        });
      });
    }
  };

  const getCreatedDate = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate - createdAtDate);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    let color = "";
    if (hoursDifference <= 24) {
      color = "green-bottom";
    } else if (hoursDifference <= 48) {
      color = "yellow-bottom";
    } else {
      color = "maroon-bottom";
    }

    let sinceText = "";
    if (daysDifference > 1) {
      sinceText = `(Since ${daysDifference} days)`;
    } else {
      sinceText = `(Since ${daysDifference} day)`;
    }

    const formattedDate = `${createdAtDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(createdAtDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${createdAtDate.getFullYear()}`;
    return {
      formattedDate,
      daysDifference,
      sinceText,
      color,
    };
  };
  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };
  const navigateToCurrentPageFn = async (quote_details) => {
    let data = { ...quote_details };
    data["modal_name"] = quote_details?.service_type;
    data["total_valume"] = quote_details?.total_volume;
    localStorage.setItem("quoteDetailsStored", JSON.stringify(data));
    localStorage.setItem(
      "service_type_id",
      clickupData?.find(
        (cl) => cl?.clickup_product_id == quote_details?.product_type
      )?.product_id
    );
    localStorage.setItem("update_clicked", "true");

    await getQuestions(quote_details, navigate);

    let current_state = quote_details?.current_state;
    let lastPage = JSON.parse(localStorage.getItem("modal_questions"))
      ?.sort((a, b) => a.page_order - b.page_order)
      ?.pop()?.page_order;
    if (current_state == 1) {
      navigate("/pageone");
    } else if (current_state == 2) {
      navigate("/pagetwo");
    } else if (current_state == 3) {
      navigate("/pagethree");
    } else if (current_state >= 4 && current_state <= lastPage) {
      navigate("/page/" + current_state);
    } else if (current_state > lastPage) {
      navigate("/page/" + lastPage);
    }
  };

  async function getDropdown() {
    isTokenExpired();

    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      setClickupData(clickup_data?.data?.dropdown_details);
    } catch (err) {
      console.log(err);
    }
  }

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomIcon name="icon_search" className="fs-5" />
      </components.DropdownIndicator>
    );
  };
  function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const loadOptions = async (inputValue, callback) => {
    try {
      if (inputValue?.length < 3) {
        return callback([]);
      }
      let token = localStorage.getItem("mtm_user_token");
      // Make API call to fetch options based on user input
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/quote/search_customers`,
        {
          query: inputValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      // Assuming the API response contains an array of options
      const options = response.data?.customers?.map((item) => ({
        label: item?.organization_name,
        value: item?.customer_id,
      }));

      // Callback with the fetched options
      callback(options);
    } catch (error) {
      console.error("Error fetching options:", error);
      callback([]);
    }
  };
  const deleteLableFn = async () => {
    try {
      setdeleting(true);
      let token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/opportunity/quote/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          quote_id: showDelete,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        if (response.data.error) {
          alert(response.data.message);
        } else {
          setquoteDetailsFetched([]);
          setCurrentPage(0);
          getFilterDatesFn(showDelete);
          setdeleteId(prev=>{
            return [...prev,showDelete]
          })
          localStorage.removeItem("quoteDetailsStored");
          localStorage.removeItem("portOfLoadingName");
          localStorage.removeItem("portOfDischargeName");
          localStorage.removeItem("containerTypeid");
          localStorage.removeItem("customer_list");
          localStorage.removeItem("quoteDetailsStored_customer_type");
          localStorage.removeItem("service_type_id");
          localStorage.removeItem("modal_questions");
          localStorage.removeItem("pre_modal_questions");
          localStorage.removeItem("modal_id");
          localStorage.removeItem("typeOfProductId");
          localStorage.removeItem("update_clicked");
          handleCloseDelete();
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };

  useEffect(() => {
    getFilterDatesFn();
    localStorage.removeItem("quoteDetailsStored");
    localStorage.removeItem("portOfLoadingName");
    localStorage.removeItem("portOfDischargeName");
    localStorage.removeItem("containerTypeid");
    localStorage.removeItem("customer_list");
    localStorage.removeItem("quoteDetailsStored_customer_type");
    localStorage.removeItem("service_type_id");
    localStorage.removeItem("modal_questions");
    localStorage.removeItem("pre_modal_questions");
    localStorage.removeItem("modal_id");
    localStorage.removeItem("typeOfProductId");
    localStorage.removeItem("update_clicked");
  }, [
    selectedDate?.length,
    selectedProductType,
    selectedStatus,
    currentPage,
    selectedCustomerId,
  ]);

  
  useEffect(() => {
    getDropdown();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div>
                <div className="w-100 py-3 d-flex align-items-center ">
                  <button
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/home")}
                    type="button"
                    className="back-btn"
                  >
                    <svg className="icon me-2 fs-22">
                      <use href="#icon_leftarrow"></use>
                    </svg>
                    <strong className="fs-14">Back</strong>
                  </button>
                </div>
              </div>
              <div className="py-4">
                <form className="d-flex ">
                  <div className="d-flex me-auto align-items-center">
                    <div className="me-4">
                      <RangePicker
                        defaultValue={[selectedDate[0], selectedDate[1]]}
                        onChange={(value) => {
                          if (value) {
                            setselectedDate(value.map((item) => item));
                          } else {
                            setselectedDate([]);
                          }
                          setquoteDetailsFetched([]);
                          setCurrentPage(0);
                        }}
                        disabledDate={disabledDate}
                        style={{ height: "38px" }}
                        format={dateFormat}
                      />
                    </div>
                    <div className="me-4">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={selectedStatus}
                        onChange={(e) => {
                          setselectedStatus(e.target.value);
                          setquoteDetailsFetched([]);
                          setCurrentPage(0);
                        }}
                        style={{ width: "200px" }}
                      >
                        <option value="">All Statuses</option>
                        <option value="Opportunity Identified">
                          Opportunity Identified
                        </option>
                        <option value="Quote Ready">Quote Ready</option>
                        <option value="Quote Sent">Quote Sent</option>
                        <option value="Won">Won</option>
                        <option value="Negotiating with customer">
                          Negotiating with customer
                        </option>
                        <option value="Negotiating with vendor">
                          Negotiating with vendor
                        </option>
                        <option value="Waiting on customer">
                          Waiting on customer
                        </option>
                        <option value="Lost">Lost</option>
                        <option value="Uncompleted">Uncompleted</option>
                      </select>
                    </div>
                    <div>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ width: "200px" }}
                        value={selectedProductType}
                        onChange={(e) => {
                          setselectedProductType(e.target.value);
                          setquoteDetailsFetched([]);
                          setCurrentPage(0);
                        }}
                      >
                        <option value="">All Products</option>
                        {clickupData
                          ?.filter((item) => item?.dropdown_type == "product")
                          ?.map((item) => (
                            <option value={item?.clickup_product_id}>
                              {item?.clickup_product_name}
                            </option>
                          ))}
                        {/* <option value="OCEAN / FCL">OCEAN (FCL)</option>
                      <option value="TRUCK">TRUCK</option>
                      <option value="CONTROL TOWER">CONTROL TOWER</option>
                      <option value="INTERMODAL">INTERMODAL</option>
                      <option value="LCL">LCL</option>
                      <option value="LTL">LTL</option>
                      <option value="Air">AIR</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="px-4 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                      onClick={() => {
                        if (localStorage.getItem("quoteDetailsStored")) {
                          localStorage.removeItem("quoteDetailsStored");
                        }
                        navigate("/choosecustomer");
                      }}
                    >
                      Add new opprtunity
                    </button>
                  </div>
                </form>
                <div style={{ maxWidth: 400, marginTop: 10 }}>
                  <AsyncSelect
                    className="form-control p-0 m-0"
                    value={selectedCustomerId}
                    onChange={(option) => {
                      setselectedCustomerId(option);
                      setquoteDetailsFetched([]);
                      setCurrentPage(0);
                    }}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    placeholder={"Search Organization Name"}
                    isClearable
                    cacheOptions
                    isSearchable
                    loadOptions={debounce(loadOptions, 500)}
                  />
                </div>
              </div>
              {dataFetched && (
                <div className="row row-cols-3 row-cols-lg-3 g-4 align-items-end">
                  {quoteDetailsFetched?.filter(item=>!deleteId?.includes(item?.quote_id))?.map((quote, quote_index) => (
                    <div
                      ref={conditionalExp(
                        quoteDetailsFetched.length == quote_index + 1,
                        lastBookElementRef
                      )}
                      className="col cardz-wrapper"
                      key={quote?.opportunity_name}
                    >
                      <div
                        className={`cardz  rounded-lg  ${
                          getCreatedDate(quote?.quote_date)?.color
                        } `}
                      >
                        <div className="card-box p-0">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="bg-lightblue p-2 rounded-2 d-flex align-items-center text-nowrap icon-box">
                              <p className="fw-600 text-blue">
                                {quote?.clickup_status}
                              </p>
                              {quote?.clickup_status ==
                                "Waiting on customer" && (
                                <p className="mx-2 fw-600">
                                  Lost
                                  <Switch
                                    className="toggle-switch mx-2"
                                    style={{
                                      backgroundColor:
                                        quote?.toggleSwitch && "#A80D0D",
                                    }}
                                    checked={quote?.toggleSwitch}
                                    onChange={(e) => toggleOnChange(quote)}
                                  />
                                </p>
                              )}
                            </div>
                            <div className="p-3 ">
                              <svg className="icon fs-22">
                                <use
                                  href={`#${conditionalExp(
                                    quote?.product_type_name
                                      ?.toLowerCase()
                                      ?.includes("air"),
                                    "icon_air",
                                    conditionalExp(
                                      quote?.product_type_name
                                        ?.toLowerCase()
                                        ?.includes("truck"),
                                      "icon_truck",
                                      conditionalExp(
                                        quote?.product_type_name
                                          ?.toLowerCase()
                                          ?.includes("ocean"),
                                        "icon_ocean"
                                      )
                                    )
                                  )}`}
                                ></use>
                              </svg>
                            </div>
                          </div>
                          <ul>
                            <li className="text-truncate">
                              <strong>Opportunity Name: </strong>
                              <span>{quote?.opportunity_name}</span>
                            </li>
                            <li className="text-truncate">
                              <strong>Organization Name: </strong>
                              <span>{quote?.customer?.organization_name}</span>
                            </li>
                            <li>
                              <strong>Product Type: </strong>
                              <span>{quote?.product_type_name}</span>
                            </li>
                            <li>
                              <strong>Created: </strong>
                              <span>
                                {" "}
                                {
                                  getCreatedDate(quote?.quote_date)
                                    ?.formattedDate
                                }{" "}
                                {getCreatedDate(quote?.quote_date)?.sinceText}
                              </span>
                            </li>
                            {conditionalExp(
                              JSON.parse(
                                localStorage.getItem("mtm_user_data") || "{}"
                              )?.user_type == 1,
                              <li>
                                <strong>Created By: </strong>
                                <span>{quote?.contact_person}</span>
                              </li>
                            )}

                            <li className="h-100 ">
                              <div className="btn-box d-flex justify-content-center align-items-center ">
                                {(quote?.clickup_status == "Uncompleted" ||
                                  quote?.clickup_status == "Quote Ready" ||
                                  quote?.clickup_status ==
                                    "Opportunity Identified") && (
                                  <button
                                    type="button"
                                    className="delete-btn p-0 m-0 me-2"
                                    onClick={() =>
                                      handleShowDelete(quote?.quote_id)
                                    }
                                  >
                                    <svg className="icon fs-24">
                                      <use href="#icon_delete"></use>
                                    </svg>
                                  </button>
                                )}
                                {quote?.clickup_status !=
                                  "Waiting on customer" &&
                                  quote?.clickup_status != "Won" &&
                                  quote?.clickup_status != "Lost" &&
                                  quote?.clickup_status != "Quote Sent" && (
                                    <button
                                      type="button"
                                      // className="next-btn"
                                      className="tracking-btn d-flex justify-content-center px-3 w-100"
                                      onClick={() =>
                                        navigateToCurrentPageFn(quote)
                                      }
                                    >
                                      <span className="fw-600">Edit</span>
                                      <svg className="icon fs-30">
                                        <use href="#icon_arrow"></use>
                                      </svg>
                                    </button>
                                  )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {(isLoadingMore || !dataFetched) && (
                <div className="w-100 text-center">
                  <Loader />
                </div>
              )}
              {!isLoadingMore &&
                dataFetched &&
                quoteDetailsFetched?.length == 0 && (
                  <div className="w-100 text-center mt-5">
                    <h1>No opportunities available</h1>
                  </div>
                )}
            </div>
          </Col>
        </Row>
      </Container>
      {/* ==== Modal ==== */}
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          toggleOnChange(selectedOpprotunityQuote);
          setlostReason("");
        }}
        centered
      >
        <Modal.Body className="text-center  w-100">
          <Modal.Header
            className="w-100 text-center"
            closeButton
            style={{ border: "none" }}
          ></Modal.Header>
          <h3>Reason To Lost</h3>

          <div className="w-100 ">
            <div className="w-100 content-wrapper">
              <textarea
                className="w-100 "
                placeholder="  Enter text here..."
                value={lostReason}
                style={{
                  minHeight: "150px",
                  borderRadius: "10px",
                  borderColor: "gray",
                }}
                onChange={(e) => setlostReason(e.target.value)}
              ></textarea>
            </div>

            <div className="mt-3 w-100  d-flex justify-content-end ">
              {" "}
              <div>
                {!lostDetailsFetched ? (
                  <Loader />
                ) : (
                  <button
                    type="button"
                    className="px-4 py-1 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                    disabled={lostReason?.length == 0}
                    onClick={submitButtonFromModal}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Thank you*/}
      <Modal show={showThankyou} onHide={handleCloseThankyou} centered>
        <Modal.Body className="text-center py-5">
          <div className="d-flex justify-content-center">
            <div className="check-box">
              <svg className="icon fs-30">
                <use href="#icon_check"></use>
              </svg>
            </div>
          </div>
          <p className="fw-600 fs-26 py-4">Thank you</p>
          <p className="fw-400 fs-20">Your Submission Has Been Received</p>
        </Modal.Body>
      </Modal>
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Quote?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseDelete}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteLableFn();
              }}
            >
              {conditionalExp(deleting, <Loader />, "Delete")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Home;
