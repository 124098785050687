import { React, useState } from "react";
import { Form, Link, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";

function Selectcard() {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col md={12} className="mt-5">
            <div className="wrapper-box d-flex justify-content-around mt-5">
              <button
                type="button"
                className="card-btn"
                onClick={() => navigate("/quote")}
              >
                <div className="card-box d-flex align-items-center justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <div className="icon-box">
                        <svg className="icon fs-50">
                          <use href="#icon_opportunity"></use>
                        </svg>
                      </div>
                    </div>
                    <p className="fw-700 fs-20 text-center py-4">Opportunity</p>
                  </div>
                </div>
              </button>

              <button
                type="button"
                className="card-btn"
                onClick={() => navigate("/crm")}
              >
                <div className="card-box d-flex align-items-center justify-content-center">
                  <div>
                    <div className="d-flex justify-content-center">
                      <div className="icon-box">
                        <svg className="icon fs-50">
                          <use href="#icon_crm"></use>
                        </svg>
                      </div>
                    </div>
                    <p className="fw-700 fs-20 text-center py-4">CRM</p>
                  </div>
                </div>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Selectcard;
