import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import NavBar from "../components/NavBar";
import { Link, useLocation, useParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import { AddBookingModal } from "../components/AddBookingModal";

function Tracking() {
  const [errormsg, setErrormsg] = useState("");
  const { state } = useLocation();
  const [showAddBooking, setShowAddBooking] = useState(false);
  const handleClose = () => {
    setShowAddBooking(false);
    setErrormsg("");
  };
  const handleShow = () => {
    setShowAddBooking(true);
  };
  const { customerId } = useParams();

  return (
    <>
      <NavBar />
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <Breadcrumb
                separator=">"
                className="fs-5"
                items={[
                  {
                    title: (
                      <Link
                        className="text-decoration-none "
                        to={`/${customerId}`}
                        state={state}
                      >
                        Quotes
                      </Link>
                    ),
                    key: "home",
                  },
                  {
                    title: state?.quote?.custom_task_id || "Quote Id",
                  },
                ]}
              />

              <h2 className="fw-700 fs-30 text-center">Tracking</h2>
            </div>
            <div className="d-flex justify-content-center my-3">
              <div className="tracking-box">
                <img src={window.location.origin + "/images/tracking.png"} />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button type="button" className="add-btn" onClick={handleShow}>
                <div>
                  <svg className="icon fs-18">
                    <use href="#icon_plus"></use>
                  </svg>
                </div>
                <span>Add Booking Number</span>
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      {/* ===Modle */}

      <AddBookingModal
        selectedDocNumber={null}
        showAddBooking={showAddBooking}
        handleClose={handleClose}
        customerId={customerId}
        page={"tracking"}
        quote_id={state?.quote?.quote_id || null}
        state={state}
        errormsg={errormsg}
        setErrormsg={setErrormsg}
      />
    </>
  );
}

export default Tracking;
