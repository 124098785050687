import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import usePostQuoteHook from "../hooks/post-quote-details";
import QuoteMenu from "../components/quoteMenu";

function ChooseCustomer() {
  const navigate = useNavigate();
  const { postQuoteDetailsFn } = usePostQuoteHook();
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setoptions] = useState([]);
  const [optionsModified, setoptionsModified] = useState([]);
  const [chooseCustomerMandatory, setchooseCustomerMandatory] = useState(false);
  const [createCustomer, setcreateCustomer] = useState({
    customer_type: "",
  });
  const [
    quoteDetailsCameFromLocalStorage,
    setquoteDetailsCameFromLocalStorage,
  ] = useState({
    product_type: "",
    product_type_name: "",
    container_type: "",
    origin_country: "",
    loading_port: "",
    destination_country: "",
    discharge_port: "",
    pickup_address1: "",
    pickup_address2: "",
    pickup_city: "",
    pickup_state: "",
    pickup_zip_code: "",
    delivery_address1: "",
    delivery_address2: "",
    delivery_city: "",
    delivery_state: "",
    delivery_zip_code: "",
    import_export: "",
    import_export_name: "",
    monthly_volume: "",
    cargo_description: "",
    hs_code: "",
    hazmat_code: "",
    customer_clearance: "",
    special_request: "",
    trade: "",
    require_free_time: "",
    require_insurance: "",
    incase_insurance_required: "",
    quote_id: "",
    cargo_un: "",
    cargo_class: "",
    cargo_type: "",
    monthly_volume_cargo: "",
    departureDate: "",
    total_weight: "",
    total_valume: "",
    quote_states: 0,
    departure_date: "",
    modal_id: 0,
    modal_name: "",
    service_type: "",
    stackable_cargo: "",
  });
  const [quoteDetails, setquoteDetails] = useState({});

  const handleChange = (option) => {
    console.log(option, "selected");
    setSelectedOption(option);
  };
  const getCustomerList = async () => {
    try {
      let token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/list`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (!response.data.error) {
        let customers = response?.data?.customers;
        let optionsForCustomers = [];
        for (const element of customers) {
          let obj = {
            label: element?.organization_name,
            value: element?.organization_name,
            id: element?.customer_id,
            customer_type: element?.customer_type,
          };
          optionsForCustomers.push(obj);
        }
        setoptions(optionsForCustomers);
        setoptionsModified(optionsForCustomers);
        localStorage.setItem(
          "customer_list",
          JSON.stringify(optionsForCustomers)
        );
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };
  const handleApiResponse = (response, data) => {
    if (!response.data.error) {
      data["quote_id"] = response?.data?.quote_id;
      localStorage.setItem("quoteDetailsStored", JSON.stringify(data));
      localStorage.setItem(
        "quoteDetailsStored_customer_type",
        selectedOption?.customer_type
      );
      navigate("/pageone");
    }
  };
  const handleApiError = (error) => {
    if (error?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
    } else if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  };
  const postQuoteDetails = async () => {
    try {
      let data = {
        contact_person: JSON.parse(localStorage.getItem("mtm_user_data"))
          ?.user_full_name,
        contact_person_email: JSON.parse(localStorage.getItem("mtm_user_data"))
          ?.email,
        user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        customer_id: selectedOption?.id,
        opportunity_name: selectedOption?.label,
        quote_receiver_email: quoteDetails?.opportunity_email,
        current_state: "1",
        ...quoteDetailsCameFromLocalStorage,
        quote_action: quoteDetailsCameFromLocalStorage.task_id
          ? "update"
          : "add",
        modal_id: quoteDetailsCameFromLocalStorage.modal_id || 0,
      };
      let response = await postQuoteDetailsFn(data, "home", 0);

      handleApiResponse(response, data);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    if (options?.length > 0) {
      if (createCustomer?.customer_type != "") {
        let modified = options?.filter(
          (item) => item?.customer_type == createCustomer?.customer_type
        );
        setoptionsModified(modified);
      } else {
        setoptionsModified(options);
      }
    } else {
      setoptionsModified(options);
    }
  }, [options?.length, createCustomer?.customer_type]);

  useEffect(() => {
    if (localStorage.getItem("quoteDetailsStored") && options?.length > 0) {
      let data = JSON.parse(localStorage.getItem("quoteDetailsStored"));
      setquoteDetailsCameFromLocalStorage({
        ...quoteDetailsCameFromLocalStorage,
        ...data,
      });
      let customers = options;
      for (const element of customers) {
        if (element?.label === data?.opportunity_name) {
          let obj = {
            label: element?.label,
            value: element?.label,
            id: element?.id,
            customer_type: element?.customer_type,
          };
          setSelectedOption(obj);
          break;
        }
      }
      setquoteDetails({
        opportunity_name: data?.opportunity_name,
        customer_id: data?.customer_id,
      });
    }
  }, [options?.length]);
  useEffect(() => {
    if (localStorage.getItem("quoteDetailsStored_customer_type")) {
      setcreateCustomer({
        ...createCustomer,
        customer_type: localStorage.getItem("quoteDetailsStored_customer_type"),
      });
    }
    let customer_list_local = JSON.parse(localStorage.getItem("customer_list"));
    if (customer_list_local?.length) {
      setoptions(customer_list_local);
      setoptionsModified(customer_list_local);
    } else {
      getCustomerList();
    }
  }, []);

  return (
    <>
      <NavBar />
      <div className=" ">
        <Container>
          <Row>
        <QuoteMenu page={0}/>
            <Col sm={10}>
              <div className="wrapper-box">
                <div className="content-wrapper">
                 
                  <p className="fw-600 fs-26 text-center ">Choose Customer</p>
                  <div className="d-flex mt-5 mx-2">
                    <input
                      checked={createCustomer?.customer_type === "spot"}
                      type="radio"
                      name="cargo"
                      id="spot"
                      onClick={(e) => {
                        if (createCustomer?.customer_type === "spot") {
                          setcreateCustomer({
                            ...createCustomer,
                            customer_type: "",
                          });
                        } else {
                          setcreateCustomer({
                            ...createCustomer,
                            customer_type: "spot",
                          });
                        }
                        setSelectedOption(null);
                      }}
                    />
                    <label className="mx-2 me-3" htmlFor="spot">
                      Spot
                    </label>
                    <input
                      checked={createCustomer?.customer_type === "regular"}
                      type="radio"
                      name="cargo"
                      id="regular"
                      onClick={(e) => {
                        if (createCustomer?.customer_type === "regular") {
                          setcreateCustomer({
                            ...createCustomer,
                            customer_type: "",
                          });
                        } else {
                          setcreateCustomer({
                            ...createCustomer,
                            customer_type: "regular",
                          });
                        }
                      }}
                    />
                    <label className="mx-2" htmlFor="regular">
                      Regular
                    </label>
                  </div>
                  <form className="pt-2">
                    <div className="mb-3 border-bottom py-3">
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={optionsModified}
                        isSearchable={true}
                        placeholder="Search"
                        isClearable
                      />
                    </div>
                    {chooseCustomerMandatory && (
                      <div className="d-flex justify-content-center text-danger">
                        Please choose customer
                      </div>
                    )}

                    <div className="my-5 d-flex justify-content-center  ">
                      <div>
                        <button
                          type="button"
                          className="px-5 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                          onClick={() => {
                            if (selectedOption) {
                              setchooseCustomerMandatory(false);
                              localStorage.setItem(
                                "quoteDetailsStored",
                                JSON.stringify({
                                  opportunity_name: selectedOption?.label,
                                })
                              );
                              setquoteDetails({
                                opportunity_name: selectedOption?.label,
                                customer_id: selectedOption?.id,
                              });
                              localStorage.setItem(
                                "customerId",
                                JSON.stringify({
                                  customer_id: selectedOption?.id,
                                })
                              );
                            } else {
                              setchooseCustomerMandatory(true);
                              return;
                            }
                            if (localStorage.getItem("update_clicked")) {
                              localStorage.removeItem("update_clicked");
                            }
                            postQuoteDetails();
                          }}
                        >
                          Create Quote
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ChooseCustomer;
