import axios from "axios";

export const getCountriesFn = async (
  setoptionsForCountries,
  setallCountries,
  navigate,
  customerId
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/country/list`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
    });

    console.log(response, "result");
    if (response.data.status) {
      let optionsForCountries = [];
      let countries = response?.data?.country;
      for (let i of countries) {
        const element = i;
        let obj = {};
        obj["label"] = element?.country;
        obj["value"] = element?.country;
        optionsForCountries.push(obj);
      }
      setoptionsForCountries && setoptionsForCountries(optionsForCountries);

      setallCountries && setallCountries(response?.data?.country);
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate && navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getPortDetails = async (
  countryCode,
  setoptionsForPorts,
  setallPortsForCountries,
  navigate
) => {
  try {
    let token = localStorage.getItem("mtm_user_token");

    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/country/port/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        country_sort_code: countryCode,
      },
    });

    console.log(response, "port details");
    if (response.data.status) {
      let optionsForPorts = [];
      let ports = response?.data?.port_details;
      for (let i of ports) {
        const element = i;
        let obj = {};
        obj["label"] = `${element?.port_name} | ${element?.global_port_code}`;
        obj["value"] = `${element?.port_name} | ${element?.global_port_code}`;
        optionsForPorts.push(obj);
      }
      setoptionsForPorts && setoptionsForPorts(optionsForPorts);
      setallPortsForCountries &&
        setallPortsForCountries(response?.data?.port_details);
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    if (error.code === "ECONNABORTED") {
      console.log("Request timed out");
    } else {
      console.log("An error occurred:", error);
    }
  }
};

export const getCountryCode = (allCountries, selectedOptionCountry) => {
  let countryCode = "";
  for (let i = 0; i < allCountries?.length; i++) {
    const element = allCountries[i];
    if (element?.country == selectedOptionCountry?.label || element?.country == selectedOptionCountry) {
      countryCode = element?.country_sort_code;
      break;
    }
  }
  return countryCode;
};

export async function getCarrier(setCarrierList, navigate) {
  const token = localStorage.getItem("mtm_user_token");
  try {
    let carriers = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/career/dropdowns`,
      {
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      }
    );
    setCarrierList &&
      setCarrierList(
        carriers?.data?.career_details?.map((option) => {
          return {
            value: option.career_scac,
            label: `${option?.career_name} (${option.career_scac})`,
          };
        })
      );
  } catch (err) {
    if (err?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate && navigate("/login");
      return;
    }
    console.log(err);
  }
}

export const getVendorList = async (
  customerId,
  setoptionsModified,
  setvendorListDetailsRaw,
  setvendorListDetails,
  navigate
) => {
  console.log(customerId, "getVendorList customer_id");
  try {
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/vendor/all`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id: customerId,
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        let data = response.data.vendors?.map((item) => {
          return {
            label: item.vendor_name,
            value: item.vendor_name,
          };
        });
        setoptionsModified &&
          setoptionsModified([
            {
              label: "Add Vendor",
              value: "Add Vendor",
            },
            ...data,
          ]);
        // console.log(data,"data")
        setvendorListDetails && setvendorListDetails(response.data.vendors);
        setvendorListDetailsRaw &&
          setvendorListDetailsRaw(response.data.vendors);
      }
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  }
};

export const getSKUList = async (
  customerId,
  setskuListDetails,
  setskuListDetailsRaw,
  navigate,
  setskuListOptions
) => {
  try {
    const token = localStorage.getItem("mtm_user_token");
    let response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/all`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
        customer_id: customerId,
      },
    });
    if (response.status == 200) {
      if (!response.data.error) {
        setskuListDetails && setskuListDetails(response.data.skuItems);
        let options = response.data.skuItems?.map((item) => {
          return {
            label: item?.item_code,
            value: item?.item_code,
          };
        });
        setskuListOptions &&
          setskuListOptions([
            ...options,
            {
              label: "Add SKU",
              value: "Add SKU",
            },
          ]);
        setskuListDetailsRaw && setskuListDetailsRaw(response.data.skuItems);
      }
    }
  } catch (error) {
    console.log(error);
    if (error?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
  }
};

export async function getQuotes(
  customer_id,
  page,
  setoptionsModifiedQuote,
  setQuoteDetailsFetched,
  navigate,
  {selectedProductType,
  selectedDate,
  selectedShipment,
  clickupData,
  setCurrentClickup,
  setdataFetched,
  getBookingList}
) {
  const token = localStorage.getItem("mtm_user_token");
  let data = { customer_id: customer_id };

  if (page === "home") {
    let clickupId = clickupData?.find(
      (clickup) =>
        clickup.dropdown_type == "product" &&
        clickup.clickup_product_name == selectedProductType
    );
    setCurrentClickup && setCurrentClickup(clickupId);

    data = {
      ...data,
      from_date: selectedDate[0],
      to_date: selectedDate[1],
      shipment_status: selectedShipment,
      product_type: clickupId?.clickup_product_id,
    };
  }
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/customer/opportunity`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: data,
    });

    if (!response.data.error) {
      const filteredQuotes = await response?.data?.quotes
        .filter((quote) => quote.customer_id == customer_id)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      let data = filteredQuotes?.map((item) => {
        return {
          label: `${item?.opportunity_name} | ${item?.custom_task_id}`,
          value: item?.quote_id,
        };
      });
      setoptionsModifiedQuote && setoptionsModifiedQuote(data);
      setQuoteDetailsFetched && setQuoteDetailsFetched(filteredQuotes);
      setdataFetched && setdataFetched(true);
      getBookingList && getBookingList(filteredQuotes);
    }
  } catch (error) {
    if (error?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    console.log(error);
    setdataFetched && setdataFetched(true);
  }
}

export function conditionalExp (condition,a,b){
  if(condition){
    return a;
  }else{
    return b;
  }
}

export function getCargoMeasurements(value) {
  if( typeof value == 'string' ){
    return value?.split('X')
  }else {
    return []
  }
}


export async function getQuestions(quoteDetails,navigate) {
  try {
    let previous_questions = JSON.parse(localStorage.getItem("modal_questions"));
    let pre_questions_bank = {};
    if(previous_questions){
      localStorage.setItem("pre_modal_questions", JSON.stringify(previous_questions));
      previous_questions?.forEach(q=>{
        pre_questions_bank[q.content_key] = q.value;
      })
    }
    const token = localStorage.getItem("mtm_user_token");
    let clickup_data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/quote/modal/questions`,
      {modal_id:quoteDetails?.modal_id},
      {
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      }
    );
    localStorage.setItem("modal_questions", JSON.stringify(clickup_data?.data?.questions?.map(q=>({...q,value:q.value || pre_questions_bank[q.content_key] || quoteDetails[q.content_key]  || null}))|| []));
  } catch (err) {
    if (err?.response?.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
      return;
    }
    console.log(err);
  }
}

export async function getCustomerDetails(setIsLoading,customerId,navigate,setCustomerData,updateCustomer,setRfcNumber,setVatRegistrationNumber) {
  setIsLoading && setIsLoading(true);
  const token = localStorage.getItem("mtm_user_token");
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/single`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwt_token: token,
      },
      data: {
        customer_id: customerId,
      },
    });

    if (!response.data.error) {
      const userData = JSON.parse(localStorage.getItem('mtm_user_data'))
      // if(!response?.data?.ops_users?.find(e=> e.user_id == userData?.id) && userData?.user_type != 1){
      //   console.log('not authorized',response?.data?.ops_users);
      //   navigate('/unauthorized')
      //   return 
      // }
      setCustomerData && setCustomerData(response.data?.custumer);
      updateCustomer && updateCustomer(response.data?.custumer);
      setRfcNumber && setRfcNumber(response.data?.custumer?.rfc_number);
      setVatRegistrationNumber && setVatRegistrationNumber(response.data?.custumer?.vat_registration_number);
      setIsLoading && setIsLoading(false);
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
      return;
    }
    console.log(error);
    setIsLoading && setIsLoading(false);
  }
}