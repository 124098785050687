import React from "react";
import { conditionalExp } from "../utils/commonFn";
import { Link, useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";

function QuoteMenu({ page, setQuestionData, setoptions, }) {
  const modalQuestions = localStorage.getItem("modal_questions");
  const update_clicked = localStorage.getItem("update_clicked");
  const navigate = useNavigate();
  const modal_questions = conditionalExp(
    modalQuestions,
    JSON.parse(modalQuestions),
    []
  );
  return (
    <Col sm={2}>
    <nav className="quoteMenu ">
      <button
        style={{ padding: "18px", cursor: "pointer" }}
        onClick={() => {
          navigate("/quote");
          localStorage.removeItem("quoteDetailsStored");
          localStorage.removeItem("portOfLoadingName");
          localStorage.removeItem("portOfDischargeName");
          localStorage.removeItem("containerTypeid");
          localStorage.removeItem("customer_list");
          localStorage.removeItem("quoteDetailsStored_customer_type");
          localStorage.removeItem("service_type_id");
          localStorage.removeItem("modal_questions");
          localStorage.removeItem("pre_modal_questions");
          localStorage.removeItem("modal_id");
          localStorage.removeItem("typeOfProductId");
        }}
        type="button"
        className="back-btn"
      >
        <svg className="icon me-2 fs-22">
          <use href="#icon_leftarrow"></use>
        </svg>
        <strong className="fs-14">Back</strong>
      </button>
      {conditionalExp(
        update_clicked,
        <>
          <Link
           onClick={() => {
              setQuestionData && setQuestionData([]);
              setoptions && setoptions([]);
            }}
            to="/pageone"
            className={conditionalExp(
              page == 1,
              "text-primary fs-20",
              "text-secondary fs-25"
            )}
          >
            Opportunity Detail
          </Link>
          <Link
           onClick={() => {
              setQuestionData && setQuestionData([]);
              setoptions && setoptions([]);
            }}
            to="/pagetwo"
            className={conditionalExp(
              page == 2,
              "text-primary fs-20",
              "text-secondary fs-25"
            )}
          >
            Type of services
          </Link>
          <Link
           onClick={() => {
              setQuestionData && setQuestionData([]);
              setoptions && setoptions([]);
            }}
            to="/pagethree"
            className={conditionalExp(
              page == 3,
              "text-primary fs-20",
              "text-secondary fs-25"
            )}
          >
            Modal Types
          </Link>
          {conditionalExp(
            modalQuestions,
            modal_questions
              ?.filter(
                (obj, index, self) =>
                  index ===
                  self.findIndex((o) => o.page_order === obj.page_order)
              )
              ?.map((q) => (
                <Link
                onClick={() => {
                  setQuestionData && setQuestionData([]);
                  setoptions && setoptions([]);
                }}
                  key={q?.page_title}
                  to={`/page/${q?.page_order}`}
                  className={
                    conditionalExp(
                      page == q?.page_order,
                      "text-primary fs-20",
                      "text-secondary fs-25"
                    ) +
                    " " +
                    conditionalExp(modalQuestions, "d-block", "d-none")
                  }
                >
                  {q?.page_title || q?.page_order}
                </Link>
              ))
          )}

          <Link
           onClick={() => {
           setQuestionData &&  setQuestionData([]);
           setoptions &&  setoptions([]);
          }}
            to="/details"
            className={
              conditionalExp(
                page == "details",
                "text-primary fs-20",
                "text-secondary fs-25"
              ) +
              " " +
              conditionalExp(modalQuestions, "d-block", "d-none")
            }
          >
            Preview
          </Link>
        </>
      )}
    </nav>
    </Col>
  );
}

export default QuoteMenu;
