import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Accordion } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker, Drawer } from "antd";
import axios from "axios";
import { CustomerContext } from "../context/customerContext";
import Loader from "../components/Loader";
import Select from "react-select";
import { CustomIcon } from "../components/Common";
import moment from "moment/moment";
import {
  getCarrier,
  getCountriesFn,
  getPortDetails,
  getQuotes,
  getSKUList,
  getVendorList,
} from "../utils/commonFn";
import { SuccessModal } from "../components/SuccessModal";
import { AddBookingModal } from "../components/AddBookingModal";
import AddSkuModal from "../components/AddSkuModal";
import AddVendorModal from "../components/AddVendorModal";
const { RangePicker } = DatePicker;
function Home2() {
  const { state } = useLocation();
  const { customerId } = useParams();
  const previousState = state?.previous;
  console.log(previousState, "previous state");
  const [isLoadingAgentChange, setisLoadingAgentChange] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [successMessagePopup, setsuccessMessagePopup] = useState("");
  const [showAddBookingModal, setShowAddBookingModal] = useState(false);
  const [selectedOption, setselectedOption] = useState();
  const [drVisible, setDrVisible] = useState(false);
  const [arrivedContainers, setArrivedContainers] = useState([]);
  const [errormsg, setErrormsg] = useState("");
  const [carrierList, setCarrierList] = useState([]);
  const [selectedDate, setselectedDate] = useState([]);
  const [selectedProductType, setselectedProductType] = useState("");
  const [quoteDetailsFetched, setQuoteDetailsFetched] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState("");
  const [clickupData, setClickupData] = useState([]);
  const [dataFetched, setdataFetched] = useState(true);
  const [sendingMail, setsendingMail] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFileUploadingSKU, setIsFileUploadingSKU] = useState(false);
  const [showimgpop, setShowimgpop] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [bookingList, setBookingList] = useState([]);
  const [poList, setPoList] = useState([]);
  const [uploadOption, setUploadOption] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentList, setDocumentList] = useState([]);
  const [shippingDocList, setShippingDocList] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState();
  const [selectedBookingid, setSelectedBookingid] = useState();
  const [submittingBrokerData, setsubmittingBrokerData] = useState(false);
  const [selectedBrokerId, setSelectedBrokerId] = useState();
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const [rawFileSKU, setrawFileSKU] = useState(null);
  const [previewUrlSKU, setpreviewUrlSKU] = useState(null);
  const [agentsList, setAgentsList] = useState([]);
  const [showVendor, setShowVendor] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [selectedDocNumber, setSelectedDocNumber] = useState();
  const [contactsData, setContactsData] = useState();
  const [designation, setDesignation] = useState("");
  const [contact_name, setContact_name] = useState("");
  const [contact_email, setContact_email] = useState("");
  const [contact_phone, setContact_phone] = useState("");
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedPoItems, setSelectedPoItems] = useState([]);
  const [showShippingDocModal, setShowShippingDocModal] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [isLoadingVendorChange, setisLoadingVendorChange] = useState(false);
  const [isLoadingDelete, setisLoadingDelete] = useState(false);

  const [isOrgEdit, setisOrgEdit] = useState();
  const [agentData, setAgentData] = useState({
    clickup_product_id: "",
    doc_number: "",
    clickup_task_id: "",
  });
  const [bookingAgentsWithCostArray, setbookingAgentsWithCostArray] = useState(
    []
  );
  const [showErrModal, setShowErrModal] = useState(false);
  const [showErrModal2, setShowErrModal2] = useState(false);
  const [finalPoError, setfinalPoError] = useState(false);
  const [error, setError] = useState();
  const [allModals, setallModals] = useState([]);
  const [vendorListDetails, setvendorListDetails] = useState([]);
  const [vendorListDetailsRaw, setvendorListDetailsRaw] = useState([]);
  const [skuListDetails, setskuListDetails] = useState([]);
  const [skuListDetailsRaw, setskuListDetailsRaw] = useState([]);
  const [addNewVendorDetails, setaddNewVendorDetails] = useState({
    customer_id: customerId,
    company_name: "",
    origin_country: "",
    contact_1_name: "",
    contact_2_name: "",
    contact_1_email: "",
    contact_2_email: "",
    contact_phone: "",
    communication_mode: "",
    nearest_sea_port_code: "",
    nearest_sea_port_name: "",
    nearest_air_port_code: "",
    nearest_air_port_name: "",
    nearest_land_port_code: "",
    nearest_land_port_name: "",
  });
  const [allBrokers, setallBrokers] = useState([]);
  const [showvendorAdd, setShowvendorAdd] = useState(false);
  const [showSKUAdd, setShowSKUAdd] = useState(false);
  const [showAddAgentsAdd, setShowAddAgentsAdd] = useState(false);
  const [showModalSKUBULK, setshowModalSKUBULK] = useState(false);

  const [selectedOptionCountry, setselectedOptionCountry] = useState(null);
  const [selectedOptionPort, setSelectedOptionPort] = useState(null);
  const [optionsForCountries, setoptionsForCountries] = useState([]);
  const [optionsForPorts, setoptionsForPorts] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  const [modalselected, setmodalselected] = useState({});

  const [addAddNewCostDetails, setaddAddNewCostDetails] = useState({
    agent_name: "",
    cost: "",
  });
  const [addAddNewCostDetailsError, setaddAddNewCostDetailsError] = useState({
    agent_name: null,
    cost: null,
  });
  const [addNewCostOptions, setaddNewCostOptions] = useState([]);
  const [selectedAgentNameFromModal, setselectedAgentNameFromModal] =
    useState(null);

  const handleClosevendorAdd = () => setShowvendorAdd(false);
  const handleShowvendorAdd = () => setShowvendorAdd(true);
  const handleCloseSKUAdd = () => setShowSKUAdd(false);
  const handleShowSKUAdd = () => setShowSKUAdd(true);
  const handleCloseAddAgentsAdd = () => setShowAddAgentsAdd(false);
  const handleShowAddAgentsAdd = () => setShowAddAgentsAdd(true);
  const handleCloseSKUBULKAdd = () => {
    setshowModalSKUBULK(false);
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
  };
  const handleShowSKUBULKAdd = () => {
    setshowModalSKUBULK(true);
  };
  const [documentNameError, setdocumentNameError] = useState(null);

  const [addSKUDetails, setaddSKUDetails] = useState({
    customer_id: customerId,
    item_code: "",
    description: "",
    qty: "",
    unit_cost: "",
    item_per_box: "",
    weight: "",
  });
  const [addSKUDetailsError, setaddSKUDetailsError] = useState({
    customer_id: customerId,
    item_code: null,
    description: null,
    qty: null,
    unit_cost: null,
    item_per_box: null,
    weight: null,
  });

  console.log(previousState, "previous satae");
  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  const checkRxType = () => {
    if (!rawFile) {
      return (
        <img
          src={previewUrl}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFile?.name?.split(".");
    return getTypeDoc(previewUrl, type);
  };
  const checkRxTypeSKU = () => {
    if (!rawFileSKU) {
      return (
        <img
          src={previewUrlSKU}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFileSKU?.name?.split(".");
    return getTypeDoc(previewUrlSKU, type);
  };
  const getTypeDoc = (path, typ) => {
    if (!path) {
      return "";
    }
    let type = typ ? typ : path?.split(".");
    console.log(type, "type ");
    if (type[type.length - 1] == "pdf") {
      return returnImgComp(window.location.origin + "/images/pdf.png");
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return returnImgComp(window.location.origin + "/images/exel.png");
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return returnImgComp(window.location.origin + "/images/doc.png");
    }

    return returnImgComp(path);

    function returnImgComp(src) {
      return (
        <img
          src={src}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
  };

  const handleClose = () => {
    setUploadOption();
    setSelectedDoc();
    setSelectedBookingid();
    setShow(false);
    setDocumentName("");
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
  };

  const handleShow = (doc) => {
    if (doc) {
      setUploadOption(doc.document_type);
      setSelectedDoc(doc);
      console.log("doc selected", doc);
    }
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
    setShow(true);
  };

  const handleCloseShippingModal = () => {
    setSelectedBookingid();
    setShowShippingDocModal(false);
    setDocumentName("");
    setpreviewUrl(null);
    setrawFile(null);
    setpreviewUrlSKU(null);
    setrawFileSKU(null);
  };
  const handleShowShippingModal = (booking_id) => {
    setShowShippingDocModal(true);
  };
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const handleClose2 = () => {
    setShowAddBookingModal(false);
    setErrormsg("");
    setselectedOption();
  };
  const handleClose3 = () => {
    setShowMemberModal(false);
    setDesignation("");
    setContact_name("");
    setContact_email("");
    setContact_phone("");
    setselectedOption("");
    setisOrgEdit();
  };
  const handleShow2 = () => {
    setShowAddBookingModal(true);
  };
  const handleShow3 = (desig) => {
    if (desig == "approver") {
      setDesignation("Demand Planning");
      setselectedOption({ label: "Approver", value: "approver" });
    } else if (desig == "new_item_manager") {
      setDesignation("Demand Planning");
      setselectedOption({
        label: "New Item Manager",
        value: "new_item_manager",
      });
    } else {
      setDesignation(desig);
    }
    setShowMemberModal(true);
  };
  const [ShowCustomBrokerModal, setShowCustomBrokerModal] = useState(false);

  const handleCloseVendor = () => setShowVendor(false);
  const handleShowVendor = () => setShowVendor(true);
  const handleCloseVendorModal = () => setShowVendorModal(false);
  const handleShowVendorModal = () => setShowVendorModal(true);
  const handleCloseCustomBrokerModal = () => setShowCustomBrokerModal(false);
  const handleShowCustomBrokerModal = () => setShowCustomBrokerModal(true);
  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedDoc();
    setisOrgEdit();
  };
  const handleShowDelete = (doc) => {
    setShowDelete(true);
    setSelectedDoc(doc);
  };
  const dateFormat = "DD-MM-YYYY";
  const { customer } = useContext(CustomerContext);
  const navigate = useNavigate();

  const handleChangeFileFun = (e) => {
    let file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      setfileUploadLimitRxError(true);
      return;
    }
    setfileUploadLimitRxError(false);
    setrawFile(file);
  };
  const handleChangeFileFunSKU = (e) => {
    let file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      setfileUploadLimitRxError(true);
      return;
    }
    setfileUploadLimitRxError(false);
    setrawFileSKU(file);
  };

  const getFormattedDate = (item) => {
    const currentDate = item["$d"];
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getCreatedDate = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDifference = Math.abs(currentDate - createdAtDate);
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    let color = "";
    if (hoursDifference <= 24) {
      color = "green-bottom";
    } else if (hoursDifference <= 48) {
      color = "yellow-bottom";
    } else {
      color = "maroon-bottom";
    }

    let sinceText = "";
    if (daysDifference > 1) {
      sinceText = `(Since ${daysDifference} days)`;
    } else {
      sinceText = `(Since ${daysDifference} day)`;
    }

    const formattedDate = `${createdAtDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(createdAtDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${createdAtDate.getFullYear()}`;
    return {
      formattedDate,
      daysDifference,
      sinceText,
      color,
    };
  };

  const getAgentName = (broker_id) => {
    if (broker_id) {
      let item = addNewCostOptions?.find((item) => item.value == broker_id);
      // console.log(addNewCostOptions,"addNewCostOptions")
      // console.log(item,"item")
      return item?.label;
    } else {
      return "";
    }
  };

  async function sendMailFn(booking_id) {
    setsendingMail(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/broker/sendMail`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        // timeout: 10000,
        data: {
          booking_id: booking_id,
          customer_id: customerId,
        },
      });
      setsendingMail(false);
      if (!response.data.error) {
        setsuccessMessagePopup(response.data.message);
        await getShippingDocuments();
        handleShowSuccess();
        setTimeout(() => {
          handleCloseSuccess();
        }, 2000);
      } else {
        setError({
          title: response.data.message,
          err: response.data.err?.toString(),
        });
        setShowErrModal(true);
      }
    } catch (error) {
      setsendingMail(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert("error sending mail: " + error.message);
      console.log(error);
    }
  }

  async function createOrEditMember(e) {
    e.preventDefault();
    setisLoading(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/contacts/${
          isOrgEdit ? "edit" : "add"
        }`,
        {
          customer_id: customerId,
          designation:
            designation == "Demand Planning"
              ? selectedOption?.value
              : designation,
          contact_name: contact_name,
          contact_email: contact_email,
          contact_phone: contact_phone,
          contact_id: isOrgEdit || null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      if (!response?.data?.error) {
        await getCustomerContacts();
        setisLoading(false);
        handleClose3();
      } else {
        setErrormsg(response?.data?.message);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert(
        `error while ${isOrgEdit ? "editing" : "adding"} member  `,
        err.message
      );
      console.log(err);
    } finally {
      setErrormsg("");
      setDesignation("");
      setContact_name("");
      setContact_email("");
      setContact_phone("");
    }
  }

  async function getDropdown() {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setClickupData(clickup_data?.data?.dropdown_details);
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }

  async function getStatuses() {
    const token = localStorage.getItem("mtm_user_token");
    try {
      let statuses = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/mtmbooking/shipment/status`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      setStatusOptions(
        statuses?.data?.result?.map((option) => {
          return { value: option.status_code, label: option?.status_name };
        })
      );
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      console.log(err);
    }
  }

  async function handleQuote(quote) {
    navigate(`quote/${quote?.quote_id}`, {
      state: {
        quote,
        previous: {
          tab: "quotes",
          selectedDate,
          quoteDetailsFetched,
        },
      },
    });
  }

  function handleTracking(quote, tab) {
    if (quote?.shipment_id) {
      navigate(`booking_number`, {
        state: {
          quote,
          previous: {
            tab: tab || "quotes",
            selectedDate,
            quoteDetailsFetched,
          },
        },
      });
    } else {
      navigate("tracking", {
        state: {
          quote,
          previous: {
            tab: tab || "quotes",
            selectedDate,
            quoteDetailsFetched,
          },
        },
      });
    }
  }

  function getCarrierName(career) {
    const carrer = carrierList.find((item) => item?.value === career);
    return carrer?.label;
  }

  function handleFileUploadSuccess(response) {
    if (response.status == 200 && !response.data.error) {
      setIsFileUploading(false);
      setsuccessMessagePopup("File Uploaded Successfully!");
      handleShowSuccess();
      setTimeout(() => {
        handleCloseSuccess();
      }, 2000);
      handleClose();
      setrawFile();
      setpreviewUrl();
      setfileUploadLimitRxError(false);
    } else {
      setIsFileUploading(false);
      setError({
        err: response.data.message,
        title: "error while uploading demand file",
      });
      setShowErrModal(true);
    }
  }

  function handleFileUploadError(error) {
    if (error.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/login");
      return;
    }
    alert("error uploading document: " + error.message);
    setIsFileUploading(false);
  }

  //function to upload Purchase Order file
  async function handleFileUpload() {
    if (rawFile) {
      setIsFileUploading(true);

      try {
        const uploadFormData = new FormData();
        const uploadFormDataDemand = new FormData();
        let response;
        if (
          uploadOption == "International Demand Planning" ||
          uploadOption == "Domestic Demand Planning"
        ) {
          uploadFormDataDemand.append("ord_file", rawFile);
          uploadFormDataDemand.append("customer_id", customerId);
          uploadFormDataDemand.append("doc_type", uploadOption);
          response = await axios.post(
            `${process.env.REACT_APP_EVEREST_BASE_URL}/everest/order/upload`,
            uploadFormDataDemand
          );
        }

        uploadFormData.append("document", rawFile);
        uploadFormData.append("customer_id", customerId);
        if (uploadOption == "Add new documents") {
          uploadFormData.append("document_type", documentName);
        } else {
          uploadFormData.append("document_type", uploadOption);
        }
        uploadFormData.append("booking_id", selectedBookingid || "");
        uploadFormData.append(
          "user_id",
          JSON.parse(localStorage.getItem("mtm_user_data")).id
        );
        response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/upload`,
          uploadFormData,
          {
            headers: {
              jwt_token: localStorage.getItem("mtm_user_token"),
            },
          }
        );

        handleFileUploadSuccess(response);
      } catch (error) {
        handleFileUploadError(error);
      } finally {
        getDocuments();
        getShippingDocuments();
      }
    } else {
      alert("Please select a file");
    }
  }
  async function handleFileUploadSKU() {
    if (rawFileSKU) {
      setIsFileUploadingSKU(true);
      try {
        const uploadFormData = new FormData();
        uploadFormData.append("document", rawFileSKU);
        uploadFormData.append("customer_id", customerId);

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/sku/bulkAdd`,
          uploadFormData,
          {
            headers: {
              jwt_token: localStorage.getItem("mtm_user_token"),
            },
          }
        );

        if (response.status == 200) {
          setIsFileUploadingSKU(false);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
          handleCloseSKUBULKAdd();
          getSKUList(
            customerId,
            setskuListDetails,
            setskuListDetailsRaw,
            navigate,
            null
          );
          setrawFileSKU(null);
          setpreviewUrlSKU(null);
          setfileUploadLimitRxError(false);
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
        alert("error uploading document: " + error.message);
        setIsFileUploadingSKU(false);
      }
    } else {
      alert("Please select a file");
    }
  }
  const checkConditionCustomer = (po) => {
    if (customer?.everest_type) {
      return po?.expected_pickup_date ? true : false;
    } else {
      return po?.expected_pickup_date && po?.modal_id ? true : false;
    }
  };

  async function handleUploadShippingDoc() {
    if (!documentName || documentName == "") {
      setdocumentNameError("Please Choose Document Name");
    }
    if (rawFile) {
      setdocumentNameError(null);
      setIsFileUploading(true);
      try {
        const uploadFormData = new FormData();
        uploadFormData.append("document", rawFile);
        uploadFormData.append("booking_id", selectedBookingid || "");
        uploadFormData.append("document_name", documentName);
        uploadFormData.append("customer_id", customerId);
        uploadFormData.append(
          "user_id",
          JSON.parse(localStorage.getItem("mtm_user_data")).id
        );
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/shipping/document/upload`,
          uploadFormData,
          {
            headers: {
              jwt_token: localStorage.getItem("mtm_user_token"),
            },
          }
        );

        if (response.status == 200) {
          setIsFileUploading(false);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
          handleCloseShippingModal();
          setrawFile();
          setpreviewUrl();
          setfileUploadLimitRxError(false);
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
        alert("error uploading document: " + error.message);
        setIsFileUploading(false);
      } finally {
        getDocuments();
        getShippingDocuments();
      }
    } else {
      alert("Please select a file");
    }
  }

  async function getDocuments() {
    setdataFetched(false);

    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        setDocumentList(response.data.documents);
        setdataFetched(true);
      } else {
        setDocumentList([]);
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  async function getBookingList(quotes) {
    setdataFetched(false);
    const quote_ids = quotes.map((quote) => quote.quote_id);
    console.log(quote_ids);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/bookings`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: {
          // quote_ids: quote_ids,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        setBookingList(response.data.bookingsList);
        setdataFetched(true);
      } else {
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  async function getAgents() {
    setdataFetched(false);

    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          type: "origin",
          customer_id: customerId,
        },

        // data: {
        //   // dropdown_type: "agent_aduanal",
        //   dropdown_type: "agent_origin",
        // },
      });

      if (response.status == 200 && !response.data.error) {
        setAgentsList(response.data.brokers);
        setdataFetched(true);
      } else {
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  function handlePdfView(po) {
    setSelectedPoItems(po.poItems);
    setImgURL(po.po_file_path);
    setPopupTitle("Purchase Order");
    setShowimgpop(true);
  }
  function handlePdfViewShipping(doc) {
    setImgURL(doc.document_path);
    setPopupTitle(doc.document_name);
    setShowimgpop(true);
  }
  const getAllModals = async () => {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/getModals`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        // console.log(response.data)
        setallModals(response.data.modals);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };

  async function handleAgentChange() {
    const { clickup_product_id, doc_number, clickup_task_id, broker_id } =
      agentData;

    if (clickup_product_id != "") {
      setisLoadingVendorChange(true);
      const clickup_product = agentsList.find(
        (agent) => agent.broker_id == broker_id
      );
      console.log(clickup_product, "clickup_product");
      try {
        const token = localStorage.getItem("mtm_user_token");
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/setAgent`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },

          data: {
            clickup_task_id: clickup_task_id,
            clickup_product_id: clickup_product?.clickup_id,
            clickup_product_name: clickup_product?.company_name,
            doc_number: doc_number,
            broker_id: broker_id,
            customer_id: customerId,
          },
        });

        if (response.status == 200 && !response.data.error) {
        
          handleCloseVendor();
        } else {
          setShowErrModal(true);
          setError({
            title: "Error While Assigning Agent",
            err: response.data.message,
          });
          setdataFetched(true);
          handleCloseVendor();
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/login");
          return;
        }
      } finally {
        setisLoadingVendorChange(false);
      }
    }
  }

  async function handleAgentChangeModalSet() {
    setisLoadingAgentChange(true);
    const { doc_number, modal_id } = modalselected;
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/setModal`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: {
          modal_id: modal_id,
          doc_number: doc_number,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {

        setdataFetched(true);
        handleCloseVendorModal();
      } else {
        setdataFetched(true);
        handleCloseVendorModal();
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setisLoadingAgentChange(false);
    }
  }
  async function handleAssignCustomBroker() {
    setsubmittingBrokerData(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/setCustomBroker`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },

        data: {
          booking_id: selectedBookingid,
          broker_id: selectedBrokerId,
          customer_id: customerId,
        },
      });
      setsubmittingBrokerData(false);
      if (response.status == 200 && !response.data.error) {
        await getShippingDocuments();
        handleCloseCustomBrokerModal();
      } else {
        alert(response.data.message);
        handleCloseCustomBrokerModal();
      }
    } catch (error) {
      setsubmittingBrokerData(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  async function getCustomerContacts() {
    setdataFetched(false);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/contacts/get`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });

      if (response.status == 200 && !response.data.error) {
        setContactsData(response.data.contacts);
        setdataFetched(true);
      } else {
        setContactsData();
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert("error:" + error);
    }
  }

  const getCountryCode = () => {
    let countryCode = "";
    for (let i = 0; i < allCountries?.length; i++) {
      const element = allCountries[i];
      if (element?.country == selectedOptionCountry?.label) {
        countryCode = element?.country_sort_code;
        break;
      }
    }
    return countryCode;
  };
  useEffect(() => {
    if (!selectedOptionCountry) {
      setSelectedOptionPort(null);
    }
    if (selectedOptionCountry && allCountries?.length > 0) {
      let countryCode = getCountryCode();
      getPortDetails(countryCode, setoptionsForPorts, null, navigate);
    }
  }, [selectedOptionCountry, allCountries?.length]);

  const downloadFile = async (filePath, fileType) => {
    setdataFetched(false);
    try {
      // Make a request to the proxy route with the 'url' header
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/proxy`, {
        headers: {
          url: filePath,
        },
      });

      // Check if the request was successful
      if (!response.ok) {
        throw new Error("Error while fetching the file.");
      }

      // Create a blob from the response data
      const blob = await response.blob();

      // Create a temporary link to initiate the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileType + "." + filePath.split(".").slice(-1); // Replace with your desired file name
      link.click();

      // Clean up the temporary link
      URL.revokeObjectURL(link.href);
      setdataFetched(true);
    } catch (error) {
      // Handle any errors
      setdataFetched(true);
      console.error("Error downloading the file:", error);
      alert("Error downloading the file: " + error);
    }
  };

  async function handleFileView(doc) {
    setImgURL(doc.document_path);
    setPopupTitle(doc.document_type);
    setShowimgpop(true);
  }

  async function handleDocDelete(doc_id) {
    setdataFetched(false);
    setisLoadingDelete(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/delete`,
        method: "POST",
        data: {
          customer_id: customerId,
          doc_id: doc_id,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (response.status == 200 && !response.data.error) {
        await getDocuments();
        await getShippingDocuments();
        setdataFetched(true);
      } else {
        await getDocuments();
        await getShippingDocuments();
        setdataFetched(true);
        console.log(response.data);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      handleCloseDelete();
      setisLoadingDelete(false);
    }
  }

  async function handleShippingDocDelete(doc_id, booking_id) {
    setdataFetched(false);
    setisLoadingDelete(true);
    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/shipping/document/delete`,
        method: "POST",
        data: {
          booking_id: booking_id,
          doc_id: doc_id,
          customer_id: customerId,
        },
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      });

      if (response.status == 200 && !response.data.error) {
        await getDocuments();
        await getShippingDocuments();
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      handleCloseDelete();
      setisLoadingDelete(false);
    }
  }

  async function handleDocEdit() {
    setIsFileUploading(true);
    try {
      const uploadFormData = new FormData();
      uploadFormData.append("document", rawFile);
      uploadFormData.append("customer_id", customerId);
      uploadFormData.append("doc_id", selectedDoc.doc_id);
      uploadFormData.append("booking_id", selectedBookingid || "");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/document/edit`,
        uploadFormData,
        {
          headers: {
            jwt_token: localStorage.getItem("mtm_user_token"),
          },
        }
      );

      if (response.status == 200) {
        setIsFileUploading(false);
        setUploadOption("");
        handleShowSuccess();
        setTimeout(() => {
          handleCloseSuccess();
        }, 2000);
        handleClose();
        setrawFile();
        setpreviewUrl();
        setfileUploadLimitRxError(false);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert("error uploading document: " + error.message);
      setIsFileUploading(false);
    } finally {
      await getDocuments();
      await getShippingDocuments();
    }
  }

  async function getShippingDocuments() {
    setdataFetched(false);

    try {
      const token = localStorage.getItem("mtm_user_token");
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/shipping/document/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });
      //agents with cost
      if (response.status == 200 && !response.data.error) {
        setShippingDocList(response.data.bookings);
        setdataFetched(true);
      } else {
        setShippingDocList([]);
        setdataFetched(true);
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  }

  function getFileType(type) {
    if (type == "pdf") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_pdf"></use>
        </svg>
      );
    } else if (type == "xls" || type == "xlsx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_exel"></use>
        </svg>
      );
    } else if (type == "doc" || type == "docx") {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_word"></use>
        </svg>
      );
    } else {
      return (
        <svg className="icon me-2 fs-22">
          <use href="#icon_doc"></use>
        </svg>
      );
    }
  }

  const getAllAgents = async () => {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/broker/all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
          customer_id: customerId,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          console.log(response.data, "response data agents");
          let { brokers } = response.data;
          console.log(brokers, "brokers");
          let options = brokers?.map((item) => {
            return {
              label: `${item.company_name} | ${item.type}`,
              value: item.broker_id,
            };
          });
          setallBrokers(brokers);
          setaddNewCostOptions(options);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };

  const syncToClickup = async (po) => {
    setisLoading(true);
    setdataFetched(false);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmpo/sync/shipment`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          doc_number: po?.doc_number,
          customer_id: customerId,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          console.log("syncToClickup done");
          setsuccessMessagePopup(response.data.message);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
        } else {
          setShowErrModal2(true);
          setfinalPoError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setisLoading(false);
      setdataFetched(true);
    }
  };

  function handleAddAgentCostCheckFn() {
    if (
      !selectedAgentNameFromModal ||
      !addAddNewCostDetails.cost ||
      addAddNewCostDetails.cost == ""
    ) {
      let selectedAgentNameFromModalError = !selectedAgentNameFromModal
        ? true
        : false;
      let costError = addAddNewCostDetails.cost == "" ? true : false;
      setaddAddNewCostDetailsError({
        ...addAddNewCostDetailsError,
        agent_name: selectedAgentNameFromModalError
          ? "Please select Agent Name"
          : null,
        cost: costError ? "Please Enter Cost" : null,
      });
      return true;
    } else {
      return false;
    }
  }
  const handleAddAgentCost = async () => {
    if (handleAddAgentCostCheckFn()) {
      return;
    }
    setisLoading(true);
    setdataFetched(false);
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtmbooking/shipping/broker/addCost`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        data: {
          broker_id: selectedAgentNameFromModal.value,
          booking_id: selectedBookingid,
          cost: addAddNewCostDetails.cost,
          customer_id: customerId,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          handleCloseAddAgentsAdd();
          setselectedAgentNameFromModal(null);
          setaddAddNewCostDetails({
            agent_name: "",
            cost: "",
          });
          await getShippingDocuments();
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    } finally {
      setisLoading(false);
      setdataFetched(true);
    }
  };

  async function handleOrgEdit(contact) {
    console.log("handle edit");
    setisOrgEdit(contact?.contact_id);
    setselectedOption(contact?.designation);
    setContact_name(contact?.contact_name);
    setContact_email(contact?.contact_email);
    setContact_phone(contact?.contact_phone);
    handleShow3(contact?.designation);
  }
  async function handleOrgDelete(contact_id) {
    setisLoadingDelete(true);
    const token = localStorage.getItem("mtm_user_token");
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/mtmclick/customer/contacts/delete`,
        {
          customer_id: customerId,
          contact_id: isOrgEdit,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );
      if (!response?.data?.error) {
        await getCustomerContacts();
        handleCloseDelete();
      } else {
        setErrormsg(response?.data?.message);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
      alert("error while deleting member  ", err.message);
      console.log(err);
    } finally {
      setisLoadingDelete(false);
    }
  }

  function mapPurchaseOrders(array) {
    return array?.map((po, index) => (
      <div key={po?.doc_number} className="border-card mb-3 mt-3">
        <div className="row pt-2 px-4 gap-4">
          <div className="col align-items-center">
            <p className="fw-700">Purchase Order Number: {po?.doc_number}</p>
            {po?.poquote?.opportunity_name && (
              <>
                <p className="fw-700 mt-1">
                  Opportunity Name:
                  <br />
                  {po?.poquote?.opportunity_name}{" "}
                  {po?.poquote?.custom_task_id && "|"}{" "}
                  {po?.poquote?.custom_task_id}
                </p>
              </>
            )}
          </div>

          <div className="col-2 align-items-center">
            <p className="fw-600">
              <span className="fw-700">Status:</span> {po?.clickup_status}
            </p>
            {po?.clickup_status == "en almacén" && (
              <button
                onClick={() => {
                  setDrVisible(true);
                  setArrivedContainers(po?.arrived_containers || []);
                }}
                type="button"
                className="quotes-btn d-flex justify-content-center my-2  d-block"
                style={{ minWidth: "max-content" }}
              >
                View Arrived Containers
              </button>
            )}
          </div>

          <div className="col-6 d-flex align-items-center justify-content-between ">
            {po?.clickup_error && (
              <button
                onClick={() => syncToClickup(po)}
                type="button"
                className="quotes-btn d-flex justify-content-center mx-4  d-block"
                style={{ minWidth: "max-content" }}
              >
                Sync To Clickup
              </button>
            )}
            <button
              onClick={() => handlePdfView(po)}
              type="button"
              className="quotes-btn d-flex justify-content-center mx-4  d-block"
            >
              <svg className="icon fs-22">
                <use href="#icon_eye"></use>
              </svg>
            </button>
            {
              <select
                className="form-select py-2  rounded-pill d-block me-2"
                value={po?.modal_id || ""}
                disabled={po?.modal_id}
                onChange={(e) => {
                  setmodalselected({
                    doc_number: po?.doc_number,
                    modal_id: e.target.value,
                  });
                  handleShowVendorModal();
                }}
              >
                <option value="">Choose Modal</option>
                {allModals?.map((item, index) => (
                  <option
                    key={item?.id + item?.modal_name}
                    value={item?.modal_id}
                  >
                    {item?.modal_name}
                  </option>
                ))}
              </select>
            }
            {checkConditionCustomer(po) ? (
              <>
                <select
                  className="form-select py-2  rounded-pill d-block"
                  value={po.broker_id || ""}
                  disabled={
                    po?.clickup_agent_id &&
                    po?.clickup_status != "pendiente de booking"
                  }
                  onChange={(e) => {
                    setAgentData({
                      broker_id: e.target.value,
                      clickup_product_id: e.target.value,
                      doc_number: po.doc_number,
                      clickup_task_id: po.clickup_task_id,
                    });
                    handleShowVendor();
                  }}
                >
                  <option value="">Assign Agent</option>
                  {agentsList?.map((agent) => (
                    <option
                      key={agent?.broker_id + agent?.company_name}
                      value={agent.broker_id}
                    >
                      {agent.company_name}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              ""
            )}

            {po.clickup_agent_id &&
              (po.shipment_id ? (
                <button
                  onClick={() =>
                    handleTracking(
                      { shipment_id: po.shipment_id },
                      "purchase-order"
                    )
                  }
                  type="button"
                  style={{ minWidth: "fit-content" }}
                  className="quotes-btn d-flex justify-content-center mx-4  d-block"
                >
                  <span style={{ wordBreak: "normal" }}>Tracking</span>
                </button>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setSelectedDocNumber(po.doc_number);
                      handleShow2();
                    }}
                    type="button"
                    style={{ minWidth: "fit-content" }}
                    className="quotes-btn d-flex justify-content-center mx-4  d-block "
                  >
                    <span style={{ wordBreak: "normal" }}>Add Tracking</span>
                  </button>
                </>
              ))}
          </div>
          <div className="row text-danger">
            {po?.assign_agent_error && (
              <p style={{ textAlign: "end" }}>
                <b>Error in clickup:</b> {po?.assign_agent_error}
              </p>
            )}
          </div>
        </div>
        <hr />
        <div className="row pb-2 px-4 gap-4">
          <div className="col d-flex justify-content-between align-items-center">
            <p className="fw-600">
              <span className="fw-700">Vendor Name: </span>{" "}
              {po?.vendor?.vendor_name}
            </p>
            <p className="fw-600">
              <span className="fw-700"> Country Origin: </span>{" "}
              {po?.vendor?.county}
            </p>
          </div>
          {po?.shipment_id ? (
            <div className="col d-flex justify-content-between align-items-center">
              <p className="fw-600 hn">
                <span className="fw-700"> Booking Number: </span>{" "}
                {po?.booking_id}
              </p>
              <p className="fw-600 hn">
                <span className="fw-700"> Carrier: </span>{" "}
                {po?.career_scac && getCarrierName(po?.career_scac)}
              </p>
            </div>
          ) : (
            <div className="col  align-items-center"> </div>
          )}
          <div className="col  align-items-center">
            <p className="fw-600 hn">
              <span className="fw-700"> Pickup Date: </span>{" "}
              {po?.expected_pickup_date}
            </p>
          </div>
        </div>
      </div>
    ));
  }

  function acceptFileTypesFn(modal) {
    if (modal == "demand") {
      if (
        uploadOption == "International Demand Planning" ||
        uploadOption == "Domestic Demand Planning"
      ) {
        return ".xls,.xlsx";
      } else {
        return "";
      }
    } else if (modal == "sku") {
      return ".xls,.xlsx";
    } else {
      return "";
    }
  }

  function documentsUploadModalDups(modal) {
    return (
      <>
        <div className="upload-box p-3">
          <label className="drag-box input-file">
            <img
              className="img-fluid rounded-start"
              width={80}
              height={55}
              src={window.location.origin + "/images/uploader.png"}
              alt="..."
            />
            <input
              type="file"
              accept={acceptFileTypesFn(modal)}
              id="fileUpload2"
              onChange={
                modal == "sku" ? handleChangeFileFunSKU : handleChangeFileFun
              }
            />
            <span>
              Drag and drop your image or <a href="">browse file</a> on your
              computer
            </span>
          </label>
        </div>
        <div className="d-block rounded-2 p-1 my-3">
          {previewUrlSKU || previewUrl ? (
            <div
              className="file-import d-flex ai-center mt-10"
              id="remove_${inputid}"
            >
              {modal == "sku" ? checkRxTypeSKU() : checkRxType()}
              <span style={{ marginRight: "auto" }}>
                {modal == "sku" ? rawFileSKU?.name : rawFile?.name}
              </span>
              <label htmlFor="fileUpload2" className="mr-10">
                <svg className="icon" style={{ width: "18px", height: "18px" }}>
                  <use href="#icon_edit"></use>
                </svg>
              </label>
              <button
                onClick={() => {
                  if (modal == "sku") {
                    setpreviewUrlSKU(null);
                    setrawFileSKU(null);
                  } else {
                    setpreviewUrl(null);
                    setrawFile(null);
                  }
                }}
              >
                <svg className="icon" style={{ width: "20px", height: "20px" }}>
                  <use href="#icon_fileclose"></use>
                </svg>
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {fileUploadLimitRxError && (
          <div className="mb-2 w-100 text-danger text-center">
            Please upload file less than 6MB
          </div>
        )}
      </>
    );
  }

  function pdfModalDups() {
    return (
      <>
        {!imgURL ? (
          <h4
            style={{
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            No file found!
          </h4>
        ) : (
          <iframe
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "5px",
              overflow: "scroll",
            }}
            // src={"https://docs.google.com/viewer?url=" + imgURL + "&embedded=true"}
            src={
              imgURL?.split(".")?.slice(-1) == "xls" ||
              imgURL?.split(".")?.slice(-1) == "xlsx" ||
              imgURL?.split(".")?.slice(-1) == "doc" ||
              imgURL?.split(".")?.slice(-1) == "docx" ||
              imgURL?.split(".")?.slice(-1) == "pptx" ||
              imgURL?.split(".")?.slice(-1) == "ppt"
                ? "https://view.officeapps.live.com/op/embed.aspx?src=" + imgURL
                : imgURL
            }
          ></iframe>
        )}
      </>
    );
  }

  function conditionalExp(condition, a, b) {
    if (condition) {
      return a;
    } else {
      return b;
    }
  }

  useEffect(() => {
    getStatuses();
    getDropdown();
    if (
      !quoteDetailsFetched.length ||
      selectedProductType ||
      selectedDate ||
      selectedShipment
    ) {
      getQuotes(customerId, "home", null, setQuoteDetailsFetched, navigate, {
        selectedProductType,
        selectedDate,
        selectedShipment,
        clickupData,
        setCurrentClickup: null,
        setdataFetched,
        getBookingList,
      });
    }
    getDocuments();
    getCustomerContacts();
    getShippingDocuments();
  }, [selectedDate, selectedProductType, selectedShipment]);

  useEffect(() => {
    getAllModals();
    getCarrier(setCarrierList, navigate);
    getCountriesFn(
      setoptionsForCountries,
      setallCountries,
      navigate,
      customerId
    );
    !vendorListDetailsRaw.length &&
      getVendorList(
        customerId,
        null,
        setvendorListDetailsRaw,
        setvendorListDetails,
        navigate
      );
    !skuListDetailsRaw.length &&
      getSKUList(
        customerId,
        setskuListDetails,
        setskuListDetailsRaw,
        navigate,
        null
      );
    getAllAgents();
  }, []);

  useEffect(() => {
    if (!rawFile) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  useEffect(() => {
    if (!rawFileSKU) {
      setpreviewUrlSKU(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFileSKU);
    setpreviewUrlSKU(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFileSKU]);
  useEffect(() => {
    if (!previousState) {
      navigate(`/${customerId}`, {
        state: {
          previous: {
            tab: "organization",
          },
        },
      });
    } else {
      return;
    }
  }, [previousState]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="col-12 py-3">
            <div className="w-100 tabs-row">
              <ul
                className="nav nav-pills mb-3 gap-3"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "organization",
                      "active",
                      ""
                    )}`}
                    id="pills-organization-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-organization"
                    type="button"
                    role="tab"
                    aria-controls="pills-organization"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "organization",
                          },
                        },
                      });
                    }}
                  >
                    Organization
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "document",
                      "active",
                      ""
                    )}`}
                    id="pills-document-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-document"
                    type="button"
                    role="tab"
                    aria-controls="pills-document"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "document",
                          },
                        },
                      });
                    }}
                  >
                    Document
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${conditionalExp(
                      previousState?.tab == "quotes",
                      "active",
                      ""
                    )}`}
                    id="pills-quotes-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-quotes"
                    type="button"
                    role="tab"
                    aria-controls="pills-quotes"
                    aria-selected="false"
                    onClick={() => {
                      navigate(`/${customerId}`, {
                        state: {
                          previous: {
                            tab: "quotes",
                          },
                        },
                      });
                    }}
                  >
                    Quotes
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "organization",
                  "show active",
                  ""
                )}`}
                id="pills-organization"
                role="tabpanel"
                aria-labelledby="pills-organization-tab"
                tabIndex="1"
              >
                <div className="py-3">
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Director Details</h5>
                      <button
                        onClick={() => handleShow3("Director")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.directors.length,
                        contactsData?.directors.map((director, index) => (
                          <Row
                            key={director?.contact_name + director?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={director?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={director?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={director?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(director)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(director?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No directors found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Finance Details</h5>
                      <button
                        onClick={() => handleShow3("Finance")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.finances.length,
                        contactsData?.finances.map((fincance, index) => (
                          <Row
                            key={fincance?.contact_name + fincance?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={fincance?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={fincance?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={fincance?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(fincance)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(fincance?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No finances found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Sales Details</h5>
                      <button
                        onClick={() => handleShow3("Sales")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.sales.length,
                        contactsData?.sales.map((sale, index) => (
                          <Row
                            key={sale?.contact_name + sale?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={sale?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={sale?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={sale?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(sale)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(sale?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No Sales found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  <Row className=" g-3 pb-4">
                    <Col className="col-12 d-flex align-items-center  plus-btn">
                      <h5 className="fw-700">Oprations Details</h5>
                      <button
                        onClick={() => handleShow3("Operation")}
                        type="button"
                      >
                        <CustomIcon name="icon_plusradius" className="fs-3" />
                      </button>
                    </Col>
                    {contactsData &&
                      conditionalExp(
                        contactsData?.operations.length,
                        contactsData?.operations.map((opration, index) => (
                          <Row
                            key={opration?.contact_name + opration?.contact_id}
                            className="row-cols-4 cols-lg-4 g-3 pb-4"
                          >
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Name</label>
                                <input
                                  type="text"
                                  className="w-100"
                                  value={opration?.contact_name}
                                  placeholder="Enter First Name"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Email</label>
                                <input
                                  type="email"
                                  className="w-100"
                                  value={opration?.contact_email}
                                  placeholder="Enter Your Email"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="input-box">
                                <label className="fw-600">Phone</label>
                                <input
                                  type="tel"
                                  className="w-100"
                                  value={opration?.contact_phone}
                                  placeholder="Enter Your Number"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="d-flex  align-items-center  h-100 ">
                                <button
                                  onClick={() => handleOrgEdit(opration)}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                    margin: "10px",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_edit"
                                    className="fs-4"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setisOrgEdit(opration?.contact_id);
                                    setShowDelete(true);
                                  }}
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  type="button"
                                >
                                  <CustomIcon
                                    name="icon_delete"
                                    className="fs-3"
                                  />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Col className="col-12 ">
                          {" "}
                          <p className="fw-600">No Oprations found</p>{" "}
                        </Col>
                      )}
                  </Row>
                  {customer?.everest_type && (
                    <Col className=" g-3 pb-4 ">
                      <Col className="col-12 d-flex align-items-center  plus-btn">
                        <h5 className="fw-700">Demand Planning Management</h5>
                        <button
                          onClick={() => handleShow3("Demand Planning")}
                          type="button"
                        >
                          <CustomIcon name="icon_plusradius" className="fs-3" />
                        </button>
                      </Col>
                      {contactsData &&
                        conditionalExp(
                          contactsData?.approvers.length,
                          <Col style={{ paddingLeft: "4px" }}>
                            <h5 className="fw-700 mb-2">Approvers</h5>
                            {contactsData?.approvers.map((approver, index) => (
                              <Row
                                key={
                                  approver?.contact_name + approver?.contact_id
                                }
                                className="row-cols-3 cols-lg-3 g-3 pb-4"
                              >
                                <Col>
                                  <div className="input-box">
                                    <label className="fw-600">Name</label>
                                    <input
                                      type="text"
                                      className="w-100"
                                      value={approver?.contact_name}
                                      placeholder="Enter First Name"
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="input-box">
                                    <label className="fw-600">Email</label>
                                    <input
                                      type="email"
                                      className="w-100"
                                      value={approver?.contact_email}
                                      placeholder="Enter Your Email"
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="d-flex  align-items-center  h-100 ">
                                    <button
                                      onClick={() => handleOrgEdit(approver)}
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        margin: "10px",
                                      }}
                                      type="button"
                                    >
                                      <CustomIcon
                                        name="icon_edit"
                                        className="fs-4"
                                      />
                                    </button>
                                    <button
                                      onClick={() => {
                                        setisOrgEdit(approver?.contact_id);
                                        setShowDelete(true);
                                      }}
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                      }}
                                      type="button"
                                    >
                                      <CustomIcon
                                        name="icon_delete"
                                        className="fs-3"
                                      />
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            ))}
                          </Col>,
                          null
                        )}
                      {contactsData &&
                        conditionalExp(
                          contactsData?.new_item_managers.length,
                          <Col style={{ paddingLeft: "4px" }}>
                            <h5 className="fw-700 mb-2">New Item Managers</h5>
                            {contactsData?.new_item_managers.map(
                              (new_item_manager, index) => (
                                <Row
                                  key={
                                    new_item_manager?.contact_name +
                                    new_item_manager?.contact_id
                                  }
                                  className="row-cols-3 cols-lg-3 g-3 pb-4"
                                >
                                  <Col>
                                    <div className="input-box">
                                      <label className="fw-600">Name</label>
                                      <input
                                        type="text"
                                        className="w-100"
                                        value={new_item_manager?.contact_name}
                                        placeholder="Enter First Name"
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="input-box">
                                      <label className="fw-600">Email</label>
                                      <input
                                        type="email"
                                        className="w-100"
                                        value={new_item_manager?.contact_email}
                                        placeholder="Enter Your Email"
                                      />
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="d-flex  align-items-center  h-100 ">
                                      <button
                                        onClick={() =>
                                          handleOrgEdit(new_item_manager)
                                        }
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          margin: "10px",
                                        }}
                                        type="button"
                                      >
                                        <CustomIcon
                                          name="icon_edit"
                                          className="fs-4"
                                        />
                                      </button>
                                      <button
                                        onClick={() => {
                                          setisOrgEdit(
                                            new_item_manager?.contact_id
                                          );
                                          setShowDelete(true);
                                        }}
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                        }}
                                        type="button"
                                      >
                                        <CustomIcon
                                          name="icon_delete"
                                          className="fs-3"
                                        />
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              )
                            )}
                          </Col>,
                          null
                        )}
                    </Col>
                  )}
                  {/* <Row className="row-cols-3 cols-lg-3 g-3 pb-4">
                    <Col className="col-12">
                      <h5 className="fw-700">Tax and Credit</h5>
                    </Col>
                    <Col>
                      <div className="input-box">
                        <label className="fw-600">Tax ID</label>
                        <input
                          type="text"
                          className="w-100"
                          value={customer?.tax_id}
                          placeholder="Tax Id"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-box">
                        <label className="fw-600">Credit Days Approved</label>
                        <input
                          type="text"
                          className="w-100"
                          value={customer?.credit_days}
                          placeholder="Credit Days"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="input-box">
                        <label className="fw-600">Credit Amount Approved</label>
                        <input
                          type="text"
                          className="w-100"
                          value={customer?.credit_amount_limit}
                          placeholder="Credit Amount"
                        />
                      </div>
                    </Col>
                  </Row> */}
                </div>
              </div>

              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "document",
                  "show active",
                  ""
                )}`}
                id="pills-document"
                role="tabpanel"
                aria-labelledby="pills-document-tab"
                tabIndex="3"
              >
                {conditionalExp(
                  dataFetched,
                  <>
                    <div className="d-flex py-3">
                      <div style={{ width: "580px" }}>
                        <select
                          className="form-select py-2 rounded-pill"
                          value={uploadOption}
                          onChange={(e) => setUploadOption(e.target.value)}
                        >
                          <option value="">Please select Document</option>
                          {customer?.everest_type && (
                            <>
                              <option value="International Demand Planning">
                                International Demand Planning
                              </option>
                              <option value="Domestic Demand Planning">
                                Domestic Demand Planning
                              </option>
                            </>
                          )}
                          <option value="ID Legal Rep">ID Legal Rep</option>
                          <option value="Company Legal Documents">
                            Company Legal Documents
                          </option>
                          <option value="Address ID Documents">
                            Address ID Documents
                          </option>
                          <option value="Service Contract">
                            Service Contract
                          </option>
                          <option value="Tax ID Documents">
                            Tax ID Documents
                          </option>
                          <option value="Onboarding Files">
                            Onboarding Files
                          </option>
                          <option value="Add new documents">
                            Add new documents
                          </option>
                        </select>
                      </div>
                      <div className="ms-4">
                        <button
                          disabled={uploadOption === ""}
                          onClick={() => handleShow(null)}
                          type="button"
                          className="upload-btn fs-20 fw-600 d-flex text-center"
                        >
                          Upload
                        </button>
                      </div>
                    </div>

                    <div className="col-7 py-4">
                      {conditionalExp(
                        documentList.length > 0,
                        documentList.map((doc, index) => (
                          <div
                            key={doc.doc_id + "DOC"}
                            className="rounded-pill my-2 py-2 px-3 d-flex align-items-center"
                            style={{
                              border: " 1px solid #F1F1F1",
                              background: "#FDFDFD",
                            }}
                          >
                            <div className="d-flex align-items-center">
                              {doc &&
                                getFileType(
                                  doc?.document_path?.split(".")?.slice(-1)
                                )}
                              <div>
                                <b>
                                  <p>{doc.document_type}</p>
                                </b>
                                <i>
                                  <strong>Uploaded By:</strong>{" "}
                                  {doc.user_full_name}
                                </i>
                              </div>
                              <div className="mx-1">
                                <p>&nbsp;</p>
                                <i>
                                  <strong>Uploaded Date:</strong>{" "}
                                  {doc.createdAt &&
                                    moment(doc.createdAt).format("MM-DD-YYYY")}
                                </i>
                              </div>
                            </div>
                            <div className="icon-btn ms-auto">
                              <button
                                disabled={isLoading}
                                onClick={() =>
                                  downloadFile(
                                    doc.document_path,
                                    doc.document_type
                                  )
                                }
                                type="button"
                                className=""
                              >
                                {isLoading ? (
                                  <Loader />
                                ) : (
                                  <svg className="icon fs-22">
                                    <use href="#icon_download"></use>
                                  </svg>
                                )}
                              </button>
                              <button
                                onClick={() => handleFileView(doc)}
                                type="button"
                                className=""
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_eye"></use>
                                </svg>
                              </button>
                              <button
                                onClick={() => handleShow(doc)}
                                type="button"
                                className=""
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_edit"></use>
                                </svg>
                              </button>
                              <button
                                onClick={() => handleShowDelete(doc)}
                                type="button"
                                className="delete-btn"
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_delete"></use>
                                </svg>
                              </button>
                            </div>
                          </div>
                        )),
                        null
                      )}
                    </div>
                  </>,
                  <div
                    style={{
                      marginTop: "100px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade ${conditionalExp(
                  previousState?.tab == "quotes",
                  "show active",
                  ""
                )}`}
                id="pills-quotes"
                role="tabpanel"
                aria-labelledby="pills-quotes-tab"
                tabIndex="4"
              >
                <div className="pt-5 pb-4">
                  <form className="d-flex me-auto align-items-center ">
                    <div className="d-flex align-items-center">
                      <div className="me-4">
                        <RangePicker
                          onChange={(values) => {
                            if (!values) {
                              setselectedDate([]);
                            } else {
                              setselectedDate(
                                values?.map((item) => {
                                  return getFormattedDate(item);
                                })
                              );
                            }
                          }}
                          style={{ height: "38px" }}
                          format={dateFormat}
                        />
                      </div>

                      <div className="me-4">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          style={{ width: "200px" }}
                          value={selectedProductType}
                          onChange={(e) =>
                            setselectedProductType(e.target.value)
                          }
                        >
                          <option value="">All Products</option>
                          <option value="OCEAN / FCL">OCEAN (FCL)</option>
                          <option value="TRUCK">TRUCK</option>
                          <option value="CONTROL TOWER">CONTROL TOWER</option>
                          <option value="INTERMODAL">INTERMODAL</option>
                          <option value="LCL">LCL</option>
                          <option value="LTL">LTL</option>
                          <option value="Air">AIR</option>
                        </select>
                      </div>
                      <div>
                        <select
                          aria-label="Default select example"
                          style={{ width: "200px" }}
                          className="form-select"
                          value={selectedShipment}
                          onChange={(e) => setSelectedShipment(e.target.value)}
                        >
                          <option value="">All Shipping</option>
                          {statusOptions.map((option, index) => (
                            <option
                              key={option?.label + option?.value}
                              value={option?.value}
                            >
                              {option?.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>

                {!dataFetched && (
                  <div className="w-100 text-center">
                    <Loader />
                  </div>
                )}

                {dataFetched &&
                  quoteDetailsFetched?.map((quote, index) => (
                    <div
                      key={quote?.opportunity_name + quote?.custom_task_id}
                      className="border-card mb-4"
                    >
                      <div className="row py-3 px-4 gap-4">
                        <div className="col">
                          <p className="fw-600 mxw-250">
                            Opportunity Name: {quote?.opportunity_name}
                          </p>
                          <p className="fw-600 mxw-250">
                            {quote?.custom_task_id}
                          </p>
                        </div>
                        <div className="col-auto">
                          <p className="fw-600">
                            Product Type: {quote?.product_type_name}
                          </p>
                        </div>
                        <div className="col-auto">
                          <div className="bg-lightgreen p-2 rounded-2 d-flex align-items-center">
                            <div className="w30px h30px bg-green rounded-2 d-flex align-items-center justify-content-center me-2">
                              <svg className="icon fs-22 text-white">
                                <use href="#icon_won"></use>
                              </svg>
                            </div>
                            <span className="fw-600 text-green">
                              {quote?.clickup_status}
                            </span>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="bg-lightblue p-2 rounded-2 d-flex align-items-center">
                            <div className="w30px h30px bg-blue rounded-2 d-flex align-items-center justify-content-center me-2">
                              <svg className="icon fs-22 text-white">
                                <use href="#icon_transit"></use>
                              </svg>
                            </div>
                            <span className="fw-600 text-blue">
                              {quote?.shipment_status}
                            </span>
                          </div>
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={() => handleQuote(quote)}
                            type="button"
                            className="quotes-btn d-flex justify-content-between px-3"
                          >
                            <span>Quotes</span>
                            <svg className="icon fs-22">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div
                        style={{ height: "1px", background: "#E0E0E0" }}
                      ></div>
                      <div className="row py-3 px-4 gap-4">
                        <div className="col">
                          <p>
                            Created:{" "}
                            {getCreatedDate(quote?.createdAt)?.formattedDate}{" "}
                            {getCreatedDate(quote?.createdAt)?.sinceText}
                          </p>
                        </div>
                        <div className="col-auto">
                          <p>Created By: {quote?.created_by}</p>
                        </div>
                        <div className="col-auto">
                          <p>Total Quotes: {quote?.quote_count}</p>
                        </div>
                        <div className="col-auto">
                          <button
                            onClick={() => handleTracking(quote, "quotes")}
                            type="button"
                            className="quotes-btn d-flex justify-content-between px-3"
                          >
                            <span>Tracking</span>
                            <svg className="icon fs-22">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}

                {dataFetched && quoteDetailsFetched?.length == 0 && (
                  <div className="w-100 text-center mt-5">
                    <h1>No opportunities available</h1>
                  </div>
                )}
              </div>
            </div>

            {/* Tabs End */}
          </Col>
        </Row>
      </Container>

      {/* ===Modle */}
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">{uploadOption}</h4>
          {conditionalExp(
            uploadOption == "International Demand Planning" ||
              uploadOption == "Domestic Demand Planning",
            <p className="text-center pb-3">
              <b>Note:</b> Only excel files are accepted. <br />
              <a
                className="text-center mb-3 "
                href={conditionalExp(
                  uploadOption == "International Demand Planning",
                  "International Demand Planning.xlsx",
                  "Domestic Demand Planning.xlsx"
                )}
                download
              >
                Download Sample File
              </a>
            </p>,
            null
          )}

          {uploadOption == "Add new documents" && (
            <div className="input-box pb-3">
              <label className="fw-600">Document Name</label>
              <input
                type="tel"
                className="w-100"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                placeholder="Enter document name"
              />
            </div>
          )}

          {documentsUploadModalDups("demand")}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={selectedDoc ? handleDocEdit : handleFileUpload}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalSKUBULK}
        centered
        onHide={handleCloseSKUBULKAdd}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">BULK Upload</h4>

          <p className="text-center pb-3">
            <b>Note:</b> Only excel files are accepted. <br />
            <a
              className="text-center mb-3 "
              href={"SKU Demo File.xlsx"}
              download
            >
              Download Sample File
            </a>
          </p>

          {documentsUploadModalDups("sku")}
          <div className="mt-1">
            <button
              disabled={isFileUploadingSKU}
              onClick={handleFileUploadSKU}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploadingSKU, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* upload modal */}

      {/* shipping  doc upload modal */}

      <Modal
        show={showShippingDocModal}
        centered
        onHide={handleCloseShippingModal}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">Shipping Document</h4>
          <div className="input-box pb-3">
            {documentName && documentName !== "" && (
              <label className="fw-600 mb-1">Document Name</label>
            )}

            <select
              className="form-select rounded-pill"
              aria-label="Assign Custom Broker"
              value={documentName || ""}
              onChange={(e) => {
                setDocumentName(e.target.value);
                setdocumentNameError(null);
              }}
            >
              <option value="">Choose Document Name*</option>
              <option value="Commercial invoice / Factura comercial">
                Commercial invoice / Factura comercial
              </option>
              <option value="International Treaty / Tratado">
                International Treaty / Tratado
              </option>
              {conditionalExp(
                customer?.everest_type,
                <>
                  <option value="Relationship number in SAP / Relación de SAP">
                    Relationship number in SAP / Relación de SAP
                  </option>
                  <option value="Origin inspection / Previo">
                    Origin inspection / Previo
                  </option>
                </>,
                ""
              )}
              <option value="Packing list">Packing list</option>
              <option value="Analisys certificate / Certificado de análisis">
                Analisys certificate / Certificado de análisis
              </option>
              <option value="Origin Certificate / Certificado de origen">
                Origin Certificate / Certificado de origen
              </option>
              <option value="BL">BL</option>
              <option value="Instructions letter / Carta de instrucciones">
                Instructions letter / Carta de instrucciones
              </option>
            </select>
          </div>

          {documentsUploadModalDups("shipping")}
          {documentNameError && (
            <div className="mb-2 w-100 text-danger text-center">
              {documentNameError}
            </div>
          )}
          <div className="mt-1">
            <button
              disabled={isFileUploading}
              onClick={handleUploadShippingDoc}
              className="button button-blue upload-btn w-100 py-2 fs-20"
            >
              {conditionalExp(isFileUploading, <Loader />, "Upload")}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <SuccessModal
        showSuccess={showSuccess}
        handleCloseSuccess={handleCloseSuccess}
        successMessagePopup={successMessagePopup}
      />

      {/* ===Modle booking id */}

      <AddBookingModal
        showAddBooking={showAddBookingModal}
        handleClose={handleClose2}
        customerId={customerId}
        getQuotes={() =>
          !quoteDetailsFetched.length &&
          getQuotes(
            customerId,
            "home",
            null,
            setQuoteDetailsFetched,
            navigate,
            {
              selectedProductType,
              selectedDate,
              selectedShipment,
              clickupData,
              setCurrentClickup: null,
              setdataFetched,
              getBookingList,
            }
          )
        }
        getShippingDocuments={getShippingDocuments}
        selectedDocNumber={selectedDocNumber}
        setSelectedDocNumber={setSelectedDocNumber}
        setShowErrModal2={setShowErrModal2}
        setfinalPoError={setfinalPoError}
        quote_id={null}
      />

      {/* add contact modal*/}
      <Modal
        show={showMemberModal}
        centered
        onHide={handleClose3}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {conditionalExp(isOrgEdit, "Edit", "Add New")} {designation} Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <form onSubmit={createOrEditMember} className="p-4">
            {designation == "Demand Planning" && (
              <div className="input-box mb-4">
                {selectedOption && selectedOption != "" && (
                  <label>Choose Member</label>
                )}
                <Select
                  placeholder="Choose Member"
                  required
                  className="w-100"
                  value={selectedOption}
                  isDisabled={isOrgEdit}
                  isClearable={true}
                  onChange={setselectedOption}
                  options={[
                    { label: "Approver", value: "approver" },
                    { label: "New Item Manager", value: "new_item_manager" },
                  ]}
                />
              </div>
            )}
            <div className="input-box mb-4">
              {contact_name && contact_name != "" && <label>Name</label>}
              <input
                required
                value={contact_name}
                onChange={(e) => setContact_name(e.target.value)}
                type="text"
                className="w-100"
                placeholder="Enter Name"
              />
            </div>
            <div className="input-box mb-4">
              {contact_email && contact_email != "" && <label>Email</label>}
              <input
                required
                value={contact_email}
                onChange={(e) => setContact_email(e.target.value)}
                type="email"
                className="w-100"
                placeholder="Enter email"
              />
            </div>
            {designation !== "Demand Planning" && (
              <div className="input-box mb-4">
                {contact_phone && contact_phone != "" && <label>Phone</label>}
                <input
                  required
                  value={contact_phone}
                  onChange={(e) => setContact_phone(e.target.value)}
                  type="number"
                  className="w-100"
                  placeholder="Enter Phone"
                />
              </div>
            )}

            <div className="d-flex justify-content-end">
              <div>
                <p style={{ color: "red" }}>{errormsg}</p>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="mt-4 py-2 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                >
                  {conditionalExp(isLoading, <Loader />, "Submit")}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* pdf modal */}

      <Modal
        show={showimgpop}
        centered
        onHide={() => {
          setShowimgpop(false);
          setImgURL("");
          setSelectedPoItems([]);
        }}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{popupTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "calc(90vh - 56px)", padding: 0 }}>
          <>
            {(function () {
              if (popupTitle != "Purchase Order") {
                return pdfModalDups();
              } else {
                if (customer?.everest_type) {
                  return pdfModalDups();
                } else if (selectedPoItems?.length > 0) {
                  return (
                    <table className="table text-center my-4 border ">
                      <thead>
                        <tr
                          className="border"
                          style={{ background: "#EBEEFF" }}
                        >
                          <th>SKU</th>
                          <th>Description</th>
                          <th>Quantity(pcs)</th>
                          <th>Volume CBM</th>
                          <th># Pallets</th>
                          <th>Unit Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPoItems.map((item, index) => (
                          <tr key={"poitem" + item?.item_code}>
                            <td>{item?.item_code}</td>
                            <td>{item?.item_description}</td>
                            <td>{item?.qty}</td>
                            <td>{item?.volume}</td>
                            <td>{item?.pallet}</td>
                            <td>{item?.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  );
                } else {
                  return (
                    <h4
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      No Items
                    </h4>
                  );
                }
              }
            })()}
          </>
        </Modal.Body>
      </Modal>

      {/* confirmation modal */}
      <Modal show={showVendor} onHide={handleCloseVendor} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want change this agent ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendor}
            >
              Close
            </Button>
            <Button
              disabled={isLoadingVendorChange}
              variant="success"
              onClick={() => {
                handleAgentChange();
              }}
            >
              {conditionalExp(!isLoadingVendorChange, "Change", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showVendorModal} onHide={handleCloseVendorModal} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want choose this Modal ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorModal}
            >
              Close
            </Button>
            <Button
              disabled={isLoadingAgentChange}
              variant="success"
              onClick={() => {
                handleAgentChangeModalSet();
              }}
            >
              {conditionalExp(!isLoadingAgentChange, "Choose", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ShowCustomBrokerModal}
        onHide={handleCloseCustomBrokerModal}
        centered
      >
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to Assign Custom Broker ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseCustomBrokerModal}
            >
              Close
            </Button>
            <Button
              variant="success"
              disabled={submittingBrokerData}
              onClick={() => {
                handleAssignCustomBroker();
              }}
            >
              {conditionalExp(submittingBrokerData, <Loader />, "Assign")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/*doc delete confirmation modal */}
      <Modal show={showDelete} onHide={handleCloseDelete} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">Are you sure you want delete ?</p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseDelete}
            >
              Close
            </Button>
            <Button
              variant="danger"
              disabled={isLoadingDelete}
              onClick={() => {
                if (isOrgEdit) {
                  handleOrgDelete(isOrgEdit);
                } else if (
                  selectedDoc?.document_type == "shipping" ||
                  selectedDoc?.document_type == "custom"
                ) {
                  handleShippingDocDelete(
                    selectedDoc?.id,
                    selectedDoc?.booking_id
                  );
                } else {
                  handleDocDelete(selectedDoc.doc_id);
                }
              }}
            >
              {conditionalExp(!isLoadingDelete, "Delete", <Loader />)}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Vendor Modal */}

      <AddVendorModal
        showvendorAdd={showvendorAdd}
        handleClosevendorAdd={handleClosevendorAdd}
        addNewVendorDetails={addNewVendorDetails}
        setaddNewVendorDetails={setaddNewVendorDetails}
        optionsForCountries={optionsForCountries}
        optionsForPorts={optionsForPorts}
        selectedOptionCountry={selectedOptionCountry}
        setselectedOptionCountry={setselectedOptionCountry}
        selectedOptionPort={selectedOptionPort}
        setSelectedOption={setselectedOption}
        setSelectedOptionPort={setSelectedOptionPort}
        setoptionsForPorts={setoptionsForPorts}
        customerId={customerId}
        getVendorList={() =>
          !vendorListDetailsRaw.length &&
          getVendorList(
            customerId,
            null,
            setvendorListDetailsRaw,
            setvendorListDetails,
            navigate
          )
        }
        setvendorListDetailsRaw={setvendorListDetailsRaw}
      />
      {/* Add SKU Modal*/}

      <AddSkuModal
        addSKUDetails={addSKUDetails}
        addSKUDetailsError={addSKUDetailsError}
        setaddSKUDetails={setaddSKUDetails}
        setaddSKUDetailsError={setaddSKUDetailsError}
        customerId={customerId}
        getSKUList={() =>
          !skuListDetailsRaw.length &&
          getSKUList(
            customerId,
            setskuListDetails,
            setskuListDetailsRaw,
            navigate,
            null
          )
        }
        handleCloseSKUAdd={handleCloseSKUAdd}
        showSKUAdd={showSKUAdd}
      />

      {/* add agents cost modal */}

      <Modal
        show={showAddAgentsAdd}
        centered
        onHide={handleCloseAddAgentsAdd}
        // className="tracking-modal"
        className="modal-lg"
      >
        <Modal.Header className="border-0" closeButton></Modal.Header>

        <Modal.Body className="px-4">
          <p className="text-center fs-26 fw-bold pb-2">Add Agent's Cost </p>
          <Row className="mt-3">
            <Col md={6}>
              <div className="mb-3 input-box">
                {selectedAgentNameFromModal && (
                  <label className="mb-1 ">Agent Name</label>
                )}

                <Select
                  value={selectedAgentNameFromModal}
                  onChange={(row) => {
                    setselectedAgentNameFromModal(row);
                    setaddAddNewCostDetailsError({
                      ...addAddNewCostDetailsError,
                      agent_name: null,
                    });
                  }}
                  options={addNewCostOptions}
                  isSearchable={true}
                  placeholder="Add Agent"
                  isClearable
                  isLoading={!addNewCostOptions?.length}
                />
              </div>
              {addAddNewCostDetailsError.agent_name && (
                <div className="text-danger mb-3">
                  {addAddNewCostDetailsError.agent_name}
                </div>
              )}

              <div className="input-box mb-3">
                {addAddNewCostDetails.cost &&
                  addAddNewCostDetails.cost != "" && (
                    <label htmlFor="cost">Cost *</label>
                  )}
                <input
                  type="number"
                  className="w-100"
                  id="cost"
                  required
                  value={addAddNewCostDetails.cost}
                  onChange={(e) => {
                    setaddAddNewCostDetails({
                      ...addAddNewCostDetails,
                      cost: e.target.value,
                    });
                    setaddAddNewCostDetailsError({
                      ...addAddNewCostDetailsError,
                      cost: null,
                    });
                  }}
                  placeholder="Enter Cost*"
                />
              </div>
              {addAddNewCostDetailsError.cost && (
                <div className="text-danger mb-3">
                  {addAddNewCostDetailsError.cost}
                </div>
              )}

              <div className="d-flex">
                <div className="ms-auto">
                  <button
                    className="grd-btn rounded-pill"
                    onClick={() => {
                      handleAddAgentCost();
                    }}
                    disabled={isLoading}
                  >
                    {conditionalExp(isLoading, <Loader />, "Submit")}
                  </button>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="col">
                {bookingAgentsWithCostArray?.length == 0 && (
                  <p className="text-center fs-26  pb-2">
                    No agents & cost added yet
                  </p>
                )}

                {bookingAgentsWithCostArray &&
                  bookingAgentsWithCostArray?.length > 0 && (
                    <div className="fw-600 ">
                      <div className="w-100 overflow-auto vendor-tbl">
                        <table class="table text-center  border">
                          <thead>
                            <tr
                              className="border"
                              style={{ background: "#EBEEFF" }}
                            >
                              <th>Agent Name</th>
                              <th>Cost</th>
                            </tr>
                          </thead>
                          <tbody>
                            {conditionalExp(
                              bookingAgentsWithCostArray?.length,
                              bookingAgentsWithCostArray?.map((item, index) => (
                                <tr key={"item" + item?.broker_id + item?.cost}>
                                  <td>{getAgentName(item?.broker_id)}</td>
                                  <td>{item?.cost}</td>
                                </tr>
                              )),
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* error show modal */}
      <Modal show={showErrModal} onHide={() => setShowErrModal(false)} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center text-danger">
            <b>{error?.title}</b>
          </p>
          <p className="fs-4 text-center text-danger">{error?.err}</p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={() => setShowErrModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showErrModal2}
        onHide={() => setShowErrModal2(false)}
        centered
      >
        <Modal.Body className="p-4 text-center">
          <div class="d-flex justify-content-center text-danger pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          <h3>Error</h3>
          <h4>{finalPoError}</h4>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="primary"
              className=""
              onClick={() => setShowErrModal2(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Drawer
        title="Arrived Containers"
        placement="right"
        closable={true}
        onClose={() => setDrVisible(false)}
        open={drVisible}
      >
        <div className="overflow-auto">
          {conditionalExp(
            arrivedContainers?.length,
            arrivedContainers?.map((container) => (
              <>
                <div>
                  <p>
                    <span className="fw-700">Container Number:</span>{" "}
                    {container.container_id}
                  </p>
                  <p>
                    <span className="fw-700">Arrival Date:</span>{" "}
                    {container.arrival_date}
                  </p>
                  <div>
                    {container?.proofs?.map((item, index) => (
                      <>
                        <div
                          className="bg-gray border  px-3 rounded-3 mt-1 d-flex align-items-center"
                          key={item?.document_name + "proof"}
                        >
                          <div className="d-flex align-items-center">
                            {getTypeDoc(item?.document_path)}
                            {/* <div>
                                 <svg className="icon fs-22">
                                   <use href="#icon_pdf"></use>
                                 </svg>
                               </div> */}
                            <div className="ms-3">
                              <p className="fw-400 fs-16 py-2">
                                {item.document_name}
                              </p>
                              {/* <span className='text-gray fw-400' style={{fontSize: "10px"}}>
                                 246 KB
                                 </span> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>{" "}
                <hr className=" border border-1 opacity-100" />
              </>
            )),
            <p>No Containers Arrived</p>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default Home2;
