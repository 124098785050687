import { React, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Modal } from "react-bootstrap";
import NavBar from "../components/NavBar";
import axios from "axios";
import Loader from "../components/Loader";
import Progress from "../components/Progress";
import usePostQuoteHook from "../hooks/post-quote-details";
import CustomInput from "../components/CustomInput";
import {
  conditionalExp,
  getCargoMeasurements,
  getCountriesFn,
  getCountryCode,
  getPortDetails,
} from "../utils/commonFn";
import QuoteMenu from "../components/quoteMenu";

function PageDynamic() {
  const navigate = useNavigate();
  const { postQuoteDetailsFn } = usePostQuoteHook();

  const [isCountryChange, setIsCountryChange] = useState();
  const [questionData, setQuestionData] = useState([]);
  const [
    quoteDetailsCameFromLocalStorage,
    setquoteDetailsCameFromLocalStorage,
  ] = useState({});
  const [airportCodes, setAirportCodes] = useState([])

  const [options, setoptions] = useState([]);
  const [optionsPort, setoptionsPort] = useState([]);
  const [allCountries, setallCountries] = useState([]);
  let { page_num } = useParams();

  page_num = parseInt(page_num);

  const postQuoteDetails = async (from) => {
    try {
      let localQuestionsData = JSON.parse(
        localStorage.getItem("modal_questions")
      );
      let dataToSend = {};

      questionData.forEach((q) => {
        dataToSend[q.content_key] = q.value;

        if (q.content_key == "container_type") {
          dataToSend["container_type_id"] = options.find(
            (o) => o.container_type == q.value
          )?.clickup_product_id;
        } else if (q.content_key == "trade") {
          dataToSend["trade"] = q?.value;
        } else if (q.content_key == "import_export") {
          dataToSend["import_export_name"] = options.find(
            (o) => o.clickup_product_id == q.value
          )?.clickup_product_name;
          dataToSend["import_export"] = q.value;
        }else if (q.content_key == "cargo_description") {
          dataToSend["hs_code"] = q?.value?.split(',,')?.map(op=>op?.split(' | ')[0])?.join(',');
          dataToSend["cargo_description"] = q?.value?.split(',,')?.join(', ');
        } else if (
          (q.input_type == "port_dropdown" ||
            q.input_type == "airport_dropdown") &&
          q.content_key == "discharge_port_name"
        ) {
          dataToSend["discharge_port"] = q?.value?.split("|")[1]?.trim();
        }
        if (
          (q.input_type == "port_dropdown" ||
            q.input_type == "airport_dropdown") &&
          q.content_key == "loading_port_name"
        ) {
          dataToSend["loading_port"] = q?.value?.split("|")[1]?.trim();
        }
      });
      let data = {
        ...quoteDetailsCameFromLocalStorage,
        ...dataToSend,
        current_state: page_num,
      };
      setQuestionData([]);
      setoptions([]);
      let lastPage = JSON.parse(localStorage.getItem("modal_questions"))
        ?.sort((a, b) => a.page_order - b.page_order)
        ?.pop()?.page_order;
      await postQuoteDetailsFn(
        data,
        conditionalExp(
          lastPage == page_num,
          "/details",
          `/page/${page_num + 1}`
        ),
        0
      );

      localStorage.setItem(
        "modal_questions",
        JSON.stringify(
          localQuestionsData.map((q) => {
            let avaliable = questionData?.find((qe) => qe.id == q?.id);
            if (avaliable) {
              return avaliable;
            } else {
              return q;
            }
          })
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getAirPort = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/country/airport_get`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
      });

      if(!response.data?.error){
        setAirportCodes(response.data?.port_details || [])
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(error);
    }
  };
  async function getDropdown(data) {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let clickup_data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/quote/dropdowns`,
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (clickup_data?.data?.dropdown_details) {
        let qDetaisLocal = JSON.parse(localStorage.getItem('quoteDetailsStored'))

        if (data?.find((q) => q.content_key == "container_type")) {
         
          if(data?.find((q) => q.content_key == "container_type")?.input_type == 'truck_type_buttons'){
            if(qDetaisLocal?.origin_country &&
              qDetaisLocal?.destination_country && qDetaisLocal?.origin_country ==
              qDetaisLocal?.destination_country){
                setoptions(
                  clickup_data?.data?.dropdown_details?.filter(
                    (item) => item.dropdown_type == "truck_container_type_both" || item.dropdown_type == "truck_container_type_domestic"
                  )
                );
              }else if(qDetaisLocal?.origin_country &&
                qDetaisLocal?.destination_country && qDetaisLocal?.origin_country !=
                qDetaisLocal?.destination_country){
                  setoptions(
                    clickup_data?.data?.dropdown_details?.filter(
                      (item) => item.dropdown_type == "truck_container_type_both" || item.dropdown_type == "truck_container_type_international"
                      )
                  );
                }else{
                  setoptions(
                    clickup_data?.data?.dropdown_details?.filter(
                      (item) => item.dropdown_type == "truck_container_type_both"
                    )
                  );
                }
          }else{
            setoptions(
              clickup_data?.data?.dropdown_details?.filter(
                (item) => item.dropdown_type == "container_type"
              )
            );
          }
        } else if (data?.find((q) => q.content_key == "trade")) {
          let tradeArr =  clickup_data?.data?.dropdown_details?.filter(
            (item) => item.dropdown_type == "trade"
          )
         
          if(qDetaisLocal?.origin_country ==
            qDetaisLocal?.destination_country ){
            let tradeId = tradeArr.find(t=> t?.clickup_product_name == 'Nacional')
           localStorage.setItem('quoteDetailsStored',JSON.stringify({...qDetaisLocal,trade:tradeId?.clickup_product_id}))
           setquoteDetailsCameFromLocalStorage(pre=> ({...pre,trade:tradeId?.clickup_product_id}))
           let qdL = JSON.parse(localStorage.getItem('modal_questions'))
           localStorage.setItem('modal_questions',JSON.stringify(qdL?.map(q=>{
            if(q.content_key == 'trade'){
              return {...q,value:tradeId?.clickup_product_id}
            }else{
              return q
            }
           })))
          }else if(qDetaisLocal?.import_export_name == 'Import' ){
            let tradeId = tradeArr.find(t=> t?.clickup_product_name == allCountries?.find(c=> c?.country == qDetaisLocal?.origin_country)?.trade)
            console.log(tradeId,'tradeId');
           localStorage.setItem('quoteDetailsStored',JSON.stringify({...qDetaisLocal,trade:tradeId?.clickup_product_id}))
           setquoteDetailsCameFromLocalStorage(pre=> ({...pre,trade:tradeId?.clickup_product_id}))
           let qdL = JSON.parse(localStorage.getItem('modal_questions'))
           localStorage.setItem('modal_questions',JSON.stringify(qdL?.map(q=>{
            if(q.content_key == 'trade'){
              return {...q,value:tradeId?.clickup_product_id}
            }else{
              return q
            }
           })))
          }else if(qDetaisLocal?.import_export_name == 'Export' ){
            let tradeId = tradeArr.find(t=> t?.clickup_product_name == allCountries?.find(c=> c?.country == qDetaisLocal?.destination_country)?.trade)
            console.log(tradeId,'tradeId');
           localStorage.setItem('quoteDetailsStored',JSON.stringify({...qDetaisLocal,trade:tradeId?.clickup_product_id}))
           setquoteDetailsCameFromLocalStorage(pre=> ({...pre,trade:tradeId?.clickup_product_id}))
           let qdL = JSON.parse(localStorage.getItem('modal_questions'))
           localStorage.setItem('modal_questions',JSON.stringify(qdL?.map(q=>{
            if(q.content_key == 'trade'){
              return {...q,value:tradeId?.clickup_product_id}
            }else{
              return q
            }
           })))
          }
          setoptions(
           tradeArr
          );
        } else if (data?.find((q) => q.content_key == "import_export")) {
          let routeArr = clickup_data?.data?.dropdown_details?.filter(
            (item) => item.dropdown_type == "route"
          );
          let qDetaisLocal = JSON.parse(localStorage.getItem('quoteDetailsStored'))
            console.log(qDetaisLocal,(
              qDetaisLocal?.origin_country ==
              qDetaisLocal?.destination_country
            ));
          if (
            qDetaisLocal?.origin_country ==
              qDetaisLocal?.destination_country 
          ) {
            console.log('came in condition'); 
            routeArr = routeArr.filter(
              (item) => item?.clickup_product_name == "National"
            );
           localStorage.setItem('quoteDetailsStored',JSON.stringify({...qDetaisLocal,import_export:routeArr[0]?.clickup_product_id,import_export_name:routeArr[0]?.clickup_product_name}))
           setquoteDetailsCameFromLocalStorage(pre=> ({...pre,import_export:routeArr[0]?.clickup_product_id,import_export_name:routeArr[0]?.clickup_product_name}))
           let qdL = JSON.parse(localStorage.getItem('modal_questions'))
           localStorage.setItem('modal_questions',JSON.stringify(qdL?.map(q=>{
            if(q.content_key == 'import_export'){
              return {...q,value:routeArr[0].clickup_product_id}
            }else{
              return q
            }
           })))
          }else{
            routeArr = routeArr.filter(
              (item) => item?.clickup_product_name != "National"
            );
           if(qDetaisLocal?.import_export_name == 'National'){
            localStorage.setItem('quoteDetailsStored',JSON.stringify({...qDetaisLocal,import_export:null,import_export_name:null}))
           setquoteDetailsCameFromLocalStorage(pre=> ({...pre,import_export:null,import_export_name:null}))
           let qdL = JSON.parse(localStorage.getItem('modal_questions'))
           localStorage.setItem('modal_questions',JSON.stringify(qdL?.map(q=>{
            if(q.content_key == 'import_export'){
              return {...q,value:null}
            }else{
              return q
            }
           })))
           }
          }
          setoptions(routeArr);
        }
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }


  const getCommodities = async () => {
    try {
      const token = localStorage.getItem("mtm_user_token");

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/mtm/commodity/list_commodity`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
        timeout: 10000,
      });
      if (!response.data.error) {
        const commodities = response.data.commodities.map((element) => ({
          label: element.hs_code + " | " + element.commodity_name,
          value: element.hs_code + " | " + element.commodity_name,
        }));
        setoptions(commodities);
      } else {
        setoptions([]);
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }

      if (error.code === "ECONNABORTED") {
        console.log("Request timed out");
      } else {
        console.log("An error occurred:", error);
      }
    }
  };

  useEffect(() => {
    async function getDropdownData() {
      if (localStorage.getItem("quoteDetailsStored")) {
        setquoteDetailsCameFromLocalStorage(
          JSON.parse(localStorage.getItem("quoteDetailsStored"))
        );
      }
      if (localStorage.getItem("modal_questions")) {
        let data = JSON.parse(localStorage.getItem("modal_questions"))
          ?.filter((q) => q.page_order == page_num)

        await getDropdown(data);
       data = JSON.parse(localStorage.getItem("modal_questions"))
          ?.filter((q) => q.page_order == page_num)
          ?.sort((a, b) => a.id - b.id);
        if (data?.find((q) => q?.input_type == "country_dropdown")) {
          await getCountriesFn(setoptions, setallCountries, navigate);
          
        }else if(data?.find((q) => q?.input_type == "commodity_dropdown")){
          await getCommodities()
        }

        if(data?.find((q) => q?.input_type == "airport_dropdown")){
          await getAirPort();
        }
        setQuestionData(
          data.map((q) => ({
            ...q,
            value:
              q.value ||
              quoteDetailsCameFromLocalStorage[q.content_key] ||
              null,
          }))
        );
      }
    }
    getDropdownData();
  }, [page_num]);

  useEffect(() => {
    let origin_country_question = questionData?.find(
      (q) => q?.content_key == "origin_country"
    );
    let destination_country_question = questionData?.find(
      (q) => q?.content_key == "destination_country"
    );
    if (origin_country_question) {
      if (!origin_country_question?.value) {
        setoptionsPort([]);
      }
      if (origin_country_question?.value && allCountries?.length > 0) {
        let countryCode = getCountryCode(
          allCountries,
          origin_country_question?.value
        );
        // console.log(questionData.find(q=>q.content_key == 'origin_country')?.value,countryCode);
        if (questionData?.find((q) => q?.input_type == "airport_dropdown")) {
          const countryAirports = airportCodes?.filter(
            (ap) =>
              ap?.country?.toLowerCase() ==
              origin_country_question?.value?.toLowerCase() && ap?.iata
          );
          // Map the airport data to the format required by react-select options
          const mappedAirports = countryAirports.map((airport) => ({
            value: `${airport.name} | ${airport.iata}`,
            label: `${airport.name} | ${airport.iata}`,
          }));
          setoptionsPort(mappedAirports);
        } else {
          getPortDetails(countryCode, setoptionsPort, null, navigate);
        }
      }
    } else if (destination_country_question) {
      if (!destination_country_question?.value) {
        setoptionsPort([]);
      }
      if (destination_country_question?.value && allCountries?.length > 0) {
        let countryCode = getCountryCode(
          allCountries,
          destination_country_question?.value
        );
        // console.log(questionData.find(q=>q.content_key == 'origin_country')?.value,countryCode);
        if (questionData?.find((q) => q?.input_type == "airport_dropdown")) {
          const countryAirports = airportCodes?.filter(
            (ap) =>
              ap?.country?.toLowerCase() ==
              destination_country_question?.value?.toLowerCase() && ap?.iata
          );
          // Map the airport data to the format required by react-select options
          const mappedAirports = countryAirports.map((airport) => ({
            value: `${airport.name} | ${airport.iata}`,
            label: `${airport.name} | ${airport.iata}`,
          }));
          setoptionsPort(mappedAirports);
        } else {
          getPortDetails(countryCode, setoptionsPort, null, navigate);
        }
      }
    }
  }, [
    questionData.find((q) => q.content_key == "origin_country")?.value,
    questionData.find((q) => q.content_key == "destination_country")?.value,
    allCountries?.length,
  ]);

  useEffect(() => {
    console.log(questionData, "questionsData",quoteDetailsCameFromLocalStorage);
  }, [questionData]);

  return (
    <>
      <NavBar />
      <div className="">
        <Container className="">
          <Row  className=" ">
          <QuoteMenu page={page_num} setQuestionData={setQuestionData} setoptions={setoptions}  />
            <Col sm={10} className="">
              <div className="wrapper-box">
                <Progress now={0} page={page_num} />
                <div className="content-wrapper">
                  <p className="fw-600 fs-26 text-center ">
                    {questionData[0]?.page_title}
                    {questionData?.find(
                      (q) => q?.content_key == "container_type"
                    ) && (
                      <div className=" fw-600 fs-26 pt-1">
                        Disclaimer: Freightify Supports only these container:
                        20Dry, 40Dry, 40HC, 20'+40'
                      </div>
                    )}
                  </p>
                  {questionData.length == 0 ? (
                    <div className="mt-5 text-center">
                      <Loader />
                    </div>
                  ) : (
                    <form className="pt-5">
                      {questionData.map((question) =>
                        conditionalExp(
                          question?.dependent_question_id,
                          conditionalExp(
                            questionData?.find(
                              (q) => q?.id == question?.dependent_question_id
                            )?.value == "yes",
                            <CustomInput
                              page={page_num}
                              isCountryChange={isCountryChange}
                              setIsCountryChange={setIsCountryChange}
                              options={conditionalExp(
                                questionData?.find(
                                  (q) => q.content_key == "origin_country"
                                ) ||
                                  questionData?.find(
                                    (q) =>
                                      q.content_key == "destination_country"
                                  ),
                                conditionalExp(
                                  question?.input_type == "country_dropdown",
                                  options,
                                  optionsPort
                                ),
                                options
                              )}
                              question={question}
                              setQuestionData={setQuestionData}
                            />
                          ),
                          <CustomInput
                            page={page_num}
                            isCountryChange={isCountryChange}
                            setIsCountryChange={setIsCountryChange}
                            options={conditionalExp(
                              questionData?.find(
                                (q) => q.content_key == "origin_country"
                              ) ||
                                questionData?.find(
                                  (q) => q.content_key == "destination_country"
                                ),
                              conditionalExp(
                                question?.input_type == "country_dropdown" || question?.input_type == "commodity_dropdown",
                                options,
                                optionsPort
                              ),
                              options
                            )}
                            question={question}
                            setQuestionData={setQuestionData}
                          />
                        )
                      )}
                      <div className="d-flex justify-content-end py-3">
                        <div>
                          <button
                            type="button"
                            className="px-4 py-2 rounded-pill grdline-btn fs-20 fw-600 d-flex text-center align-items-center me-4"
                            onClick={() => {
                              setQuestionData([]);
                              setoptions([]);
                              navigate(
                                conditionalExp(
                                  page_num == 4,
                                  `/pagethree`,
                                  `/page/${page_num - 1}`
                                )
                              );
                            }}
                          >
                            <svg className="icon me-2 fs-24">
                              <use href="#icon_leftarrow"></use>
                            </svg>
                            Back
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            disabled={
                              !questionData?.filter(q=>{
                                // filter out the pickup_zip_code and delivery_zip_code if modal_id is 1
                                if([1].includes(q?.modal_id) && ['pickup_zip_code','delivery_zip_code'].includes(q?.content_key)){
                                  return false
                                }else{
                                  return true
                                }
                              }).every((q) =>
                                conditionalExp(
                                  q?.dependent_question_id,
                                  conditionalExp(
                                    questionData?.find(
                                      (que) =>
                                        que?.id == q?.dependent_question_id
                                    )?.value == "yes",
                                    q?.value,
                                    true
                                  ),
                                  conditionalExp(
                                    q?.input_type == "measurement_text",
                                    conditionalExp(
                                      !getCargoMeasurements(q?.value)?.every((i) => i),
                                      false,
                                      true
                                    ),
                                    conditionalExp(q?.content_key == 'cbm' || q?.content_key == 'total_weight',conditionalExp(q?.value > 0,true,false),conditionalExp(q?.content_key=='number_of_packages' || ([1,2,3,4,17].includes(q?.modal_id) && (q?.content_key == 'palletized_cargo' || q?.content_key == 'stackable_cargo')),true,q?.value))
                                  )
                                )
                              )
                            }
                            className="px-4 py-2 rounded-pill grd-btn fs-20 fw-600 d-flex text-center align-items-center"
                            onClick={() => {
                              postQuoteDetails("normal");
                            }}
                          >
                            Next
                            <svg className="icon ms-2 fs-24">
                              <use href="#icon_rightarrow"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PageDynamic;
