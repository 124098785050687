import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import AsideBar from "../components/AsideBar";
import { useState } from "react";

function LayoutOutlet2() {
  const [showAddContainer, setShowAddContainer] = useState(false);
  const [showAddBooking, setShowAddBooking] = useState(false);
  return (
    <>
      <AsideBar
        showAddContainer={showAddContainer}
        setShowAddContainer={setShowAddContainer}
        showAddBooking={showAddBooking}
        setShowAddBooking={setShowAddBooking}
      ></AsideBar>
      <NavBar></NavBar>
      <div className="wrapper-operations">
        <Outlet
          context={{
            showAddContainer,
            setShowAddContainer,
            showAddBooking,
            setShowAddBooking,
          }}
        ></Outlet>
      </div>
    </>
  );
}
export default LayoutOutlet2;
